import React, { useContext, useMemo } from 'react';
import { SelectPicker, Avatar } from 'rsuite';
import Styled from 'styled-components';
import { get } from 'lodash';

import { OrganizationTeamsContext } from '../context';
import { Colors } from '../assets';

const MiniProfileContainer = Styled.div({
  display: 'flex',
  verticalAlign: 'center',
  textAlign: 'center',
  alignItems: 'center',
  alignContent: 'center',
  cursor: 'pointer',
  flex: 1,
});

const TeamSelectPicker = ({ value, cleanable = true, ...props }) => {
  const { list: teams, map: teamsMap } = useContext(OrganizationTeamsContext);

  const teamsOptions = useMemo(
    () => [
      ...teams.map((u) => ({ label: u.displayName, value: u.id, role: u.role })),
      ...(value && !cleanable ? [{ label: 'Remove Team', value: null }] : []),
    ],
    [teams, cleanable, value],
  );

  return (
    <SelectPicker
      className='user-select-picker'
      placeholder=' '
      {...props}
      data={teamsOptions}
      cleanable={cleanable}
      value={value}
      renderMenuItem={(label, item) => {
        return (
          <MiniProfileContainer>
            <Avatar
              square
              size='xs'
              style={{
                backgroundColor: !item.value ? Colors.RED : Colors.TAGLINE,
                borderColor: !item.value ? Colors.RED : Colors.TAGLINE,
                marginRight: 4,
              }}
              src={get(teamsMap, `${item.value}.photoURL`)}
            >
              {!get(teamsMap, `${item.value}.photoURL`)
                ? !item.value ? '-' : get(teamsMap, `${item.value}.displayName`, 'Deleted Team').substring(0, 2)
                : null}
            </Avatar>
            {label || 'Deleted Team'}
          </MiniProfileContainer>
        );
      }}
      renderMenuGroup={(label) => {
        return <div>{label || 'Deleted Team'}</div>;
      }}
      renderValue={(id) => (
        <MiniProfileContainer>
          <Avatar
            square
            size='xs'
            style={{
              backgroundColor: !id ? Colors.RED : Colors.TAGLINE,
              borderColor: !id ? Colors.RED : Colors.TAGLINE,
              marginRight: 4,
            }}
            src={get(teamsMap, `${id}.photoURL`)}
          >
            {!get(teamsMap, `${id}.photoURL`)
              ? !id ? '-' : get(teamsMap, `${id}.displayName`, 'Deleted Team').substring(0, 2)
              : null}
          </Avatar>
          {get(teamsMap, `${id}.displayName`, 'Deleted Team')}
        </MiniProfileContainer>
      )}
    />
  );
};

export default TeamSelectPicker;
