import React, { useState } from 'react';

import { BrandLayout } from '../layouts';
import { LoginCard } from '../components';

const LoginPage = () => {
  const [background, setBackground] = useState(null);
  return (
    <BrandLayout background={background}>
      <LoginCard linkToSignUp="/signup" setBackground={setBackground}/>
    </BrandLayout>
  );
};

export default LoginPage;
