import React, { useState, useContext, useEffect, useMemo, useCallback, useRef } from 'react';
import Styled from 'styled-components';
import {
  Panel,
  Col,
  Row,
  Avatar,
  Placeholder,
  Input,
  IconButton,
  Icon,
  Notification,
  ButtonGroup,
  Whisper,
  Tooltip,
  Button,
  Popover,
  Dropdown,
  Checkbox,
  Animation,
} from 'rsuite';
import { get, isEqual, debounce, find } from 'lodash';

import { Bugsnag, Firebase, Mixpanel } from '../services';
import { LoggedUserContext, OrganizationContext, TeamContext } from '../context';
import { GoalSelectPicker } from '.';
import AutogrowInput from './AutogrowInput';
import RichText, { DEFAULT_VALUE } from './RichText';

const { Slide } = Animation;

const OutcomeItem = ({ value, goals, previous, next }) => {
  const loggedUser = useContext(LoggedUserContext);
  const organization = useContext(OrganizationContext);
  const team = useContext(TeamContext);
  const [outcome, setOutcome] = useState(value);
  const [isFocused, setIsFocused] = useState(false);
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [isMenuOpened, setIsMenuOpened] = useState(false);

  const triggerRef = useRef();
  const handleSelectMenu = useCallback(() => {
    triggerRef.current.hide();
    setIsFocused(false);
  }, [triggerRef]);

  useEffect(() => {
    if (!isInputFocused) {
      setOutcome(value);
    }
    return () => {};
  }, [value]);

  const updateFromProps = useCallback(async () => {
    if (!isEqual(value, outcome)) {
      setOutcome(value);
    }
  }, [value, outcome]);

  const update = useCallback(
    async (nOutcome) => {
      try {
        let extra = {};
        if (!nOutcome.description) return;
        if (isEqual(nOutcome.description, DEFAULT_VALUE)) {
          extra = {
            description: value.description,
            deletedBy: loggedUser.id,
            deletedAt: Firebase.firestore.FieldValue.serverTimestamp(),
          };
        }

        await Firebase.firestore()
          .collection('organizations')
          .doc(organization.id)
          .collection('private')
          .doc(organization.id)
          .collection('outcomes')
          .doc(nOutcome.id)
          .update({
            ...nOutcome,
            updatedBy: loggedUser.id,
            updatedAt: Firebase.firestore.FieldValue.serverTimestamp(),
            goalSequence: nOutcome.goal ? find(goals.list, ['id', nOutcome.goal]).goalSequence || [] : [],
            ...extra,
          });
        Mixpanel.track('Updated Outcome');
      } catch (ex) {
        Notification.error({
          title: 'There has been an error',
          description: get(ex, 'message', 'Please try again...'),
        });
        Bugsnag.notify(ex, (event) => {
          event.severity = 'warning';
        });
      }
    },
    [organization.id, loggedUser.id, goals, value],
  );

  const updateWithDebounce = useCallback(debounce(update, 500), [update]);

  const remove = useCallback(
    () => {
      Mixpanel.track('Deleted Outcome');
      return update({
        ...outcome,
        deletedBy: loggedUser.id,
        deletedAt: Firebase.firestore.FieldValue.serverTimestamp(),
      })
    },
    [outcome, update, loggedUser.id],
  );

  return (
    <Row
      style={{ marginBottom: 8, marginRight: 0, display: 'flex', flexDirection: 'row' }}
      onMouseEnter={() => setIsFocused(true)}
      onMouseLeave={() => setIsFocused(false)}
    >
      <Col xs={2} style={{ paddingLeft: 0, marginLeft: -4 }}>
        <Checkbox checked={outcome.completed} onChange={(_, completed) => update({ ...outcome, completed })} />
      </Col>
      <Col style={{ display: 'flex', flex: 1 }}>
        <RichText
          placeholder={' '}
          value={outcome.description}
          onChange={(description) => {
            if (isEqual(description, outcome.description)) return; // very important since Rich Text will update at least once when it is created and will cause a write unless prevented
            setOutcome({ ...outcome, description });
            updateWithDebounce({ ...outcome, description });
          }}
          onFocus={() => setIsInputFocused(true)}
          onBlur={() => {
            setIsInputFocused(false);
            updateFromProps();
          }}
          toolbar={false}
          floatingToolbar={false}
          clickableLinks={true}
          placeholderClassName='editor-placeholder-inline'
          editorInputClassName='editor-input-inline'
        />
      </Col>
      {(isFocused || isMenuOpened) && (
        <Slide in={isFocused || isMenuOpened} placement='right'>
          <Col
            style={{
              display: 'flex',
            }}
          >
            <GoalSelectPicker
              preloadedGoals={goals.list}
              style={{ marginRight: 4, maxHeight: 36 }}
              placeholder='Goal'
              onSelect={(goal) => update({ ...outcome, goal })}
              onClose={() => setIsFocused(false)}
              value={outcome.goal}
              cleanable={false}
              small
            />
            <Whisper
              triggerRef={triggerRef}
              trigger='hover'
              enterable
              placement='bottomEnd'
              onEnter={() => setIsMenuOpened(true)}
              onExit={() => setIsMenuOpened(false)}
              speaker={
                <Popover placement='bottomEnd' full>
                  <Dropdown.Menu onSelect={handleSelectMenu}>
                    {/* <Dropdown.Item
                      icon={<Icon icon='detail' />}
                      onSelect={doSetShowTeamFormModal(true)}
                    >
                      Details
                    </Dropdown.Item> */}
                    <Dropdown.Item icon={<Icon icon='close' />} onSelect={remove}>
                      Delete
                    </Dropdown.Item>
                    <Dropdown.Item
                      icon={<Icon icon='arrow-up' />}
                      onSelect={() => {
                        update({ ...outcome, order: get(previous, 'order', 0) });
                        update({ ...previous, order: get(outcome, 'order', 0) });
                      }}
                      disabled={!previous}
                    >
                      Move Up
                    </Dropdown.Item>
                    <Dropdown.Item
                      icon={<Icon icon='arrow-down' />}
                      onSelect={() => {
                        update({ ...outcome, order: get(next, 'order', 0) });
                        update({ ...next, order: get(outcome, 'order', 0) });
                      }}
                      disabled={!next}
                    >
                      Move Down
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Popover>
              }
            >
              <IconButton icon={<Icon icon='ellipsis-v' />} />
            </Whisper>
          </Col>
        </Slide>
      )}
    </Row>
  );
};

export default OutcomeItem;
