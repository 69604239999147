import React, { useRef, useState, useCallback, useContext } from 'react';
import {
  FormGroup,
  ControlLabel,
  InputGroup,
  FormControl,
  HelpBlock,
  Icon,
  Whisper,
  Tooltip,
  Loader,
  Button,
  Notification,
} from 'rsuite';
import FormContext, { FormValueContext, FormErrorContext } from 'rsuite/es/Form/FormContext';
import Styled from 'styled-components';
import { get } from 'lodash';

import { Colors, NoImage } from '../assets';
import { Bugsnag, Firebase } from '../services';
import { uuidv4 } from '../utils';
import { LimitationsContext, LoggedUserContext, OrganizationContext, UsageContext } from '../context';

const ErrorBlock = Styled(HelpBlock)({
  color: Colors.RED,
  display: 'block',
});
const LabelHelpBlockIcon = Styled(Icon)({
  color: Colors.GRAY,
  marginLeft: 6,
  cursor: 'pointer',
});
const LabelHelpBlock = ({ text }) => (
  <Whisper trigger='click' placement='right' speaker={<Tooltip>{text}</Tooltip>}>
    <LabelHelpBlockIcon icon='question2' />
  </Whisper>
);

const Image = Styled.div(({ url, backgroundSize, ...props }) => ({
  backgroundImage: `url('${url || NoImage}')`,
  backgroundPosition: 'center',
  backgroundSize: url ? backgroundSize || 'cover' : 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundColor: Colors.TAGLINE,
  width: '100%',
  height: 250,
  marginRight: 'auto',
  marginLeft: 'auto',
  borderRadius: 8,
  cursor: 'pointer',
  ...props,
}));

const ImageContainer = Styled.div(({ height }) => ({
  width: '100%',
  height: height || 250,
  marginRight: 'auto',
  marginLeft: 'auto',
  marginBottom: 30,
}));

const ImageLoader = Styled(Loader)(({ height, loaderHeight }) => ({
  width: '93%',
  height: loaderHeight || height || 150,
  margin: 0,
  marginLeft: 10,
  top: 74,
  '.rs-loader-backdrop': {
    backgroundColor: 'transparent',
  },
}));

const RemoveImageButtonContainer = Styled.div({
  width: '100%',
  textAlign: 'right',
});

const RemoveImageButton = Styled(Button)({
  padding: 0,
  marginTop: 10,
  textAlign: 'right',
});

const FormGroupComponent = ({
  image,
  imageStyle = {},
  uploadMaxSize = 150,
  uploadExtraData = {},
  label,
  name,
  hint,
  addon,
  error,
  errorMessage,
  required,
  children,
  ...props
}) => {
  const organization = useContext(OrganizationContext);
  const loggedUser = useContext(LoggedUserContext);
  const limitations = useContext(LimitationsContext);
  const usage = useContext(UsageContext);
  const [uploading, setUploading] = useState(false);
  const fileInputRef = useRef(null);
  const form = useContext(FormContext);
  const formValue = useContext(FormValueContext);
  const formError = useContext(FormErrorContext);

  const openFileManager = useCallback(() => fileInputRef.current.click(), [fileInputRef]);

  const upload = useCallback(
    async (event) => {
      setUploading(true);
      try {
        const file = event.target.files[0];
        if (!file) return setUploading(false);
        if (file.size / 1024 / 1024 > uploadMaxSize) {
          throw new Error(`File is too large, ${uploadMaxSize}MB is maximum`);
        }
        const fileSizeInKbs = file.size / 1024;
        if (usage?.storage?.totalInKbs + fileSizeInKbs > limitations?.storage?.maxInKbs) {
          throw new Error(`Organization Maximum Storage Reached, please contact administrator.`);
        }
        const id = uuidv4();
        const snapshot = await Firebase.storage()
          .ref()
          .child(`organizations/${organization.id}/${id}.${file.name.split('.').pop()}`)
          .put(file);
        const url = await snapshot.ref.getDownloadURL();
        await Firebase.firestore()
          .collection('organizations')
          .doc(organization.id)
          .collection('private')
          .doc(organization.id)
          .collection('files')
          .doc(id)
          .set({
            ...uploadExtraData,
            url,
            sizeInBytes: file.size,
            type: file.type,
            name: file.name,
            createBy: loggedUser.id,
            createAt: Firebase.firestore.FieldValue.serverTimestamp(),
            updatedBy: loggedUser.id,
            updatedAt: Firebase.firestore.FieldValue.serverTimestamp(),
            deletedBy: null,
            deletedAt: null,
          });
        form.onFieldChange(name, url);
      } catch (ex) {
        Notification.error({
          title: 'There has been an error',
          description: get(ex, 'message', 'Please try again...'),
        });
        Bugsnag.notify(ex, (event) => {
          event.severity = 'warning';
        });
      }
      setUploading(false);
    },
    [name, form, uploadMaxSize, organization, loggedUser, uploadExtraData, limitations, usage],
  );

  const removeValue = useCallback(() => form.onFieldChange(name, null), [name, form]);

  return (
    <FormGroup className={error ? 'has-error' : ''}>
      {children ? (
        children
      ) : (
        <>
          <ControlLabel>
            {label}
            {required ? '*' : ''}
            {hint && <LabelHelpBlock text={hint} />}
          </ControlLabel>
          {!image && (
            <InputGroup inside>
              <FormControl block name={name} errorMessage={false} {...props} />
              {!!addon && addon}
              {!!errorMessage && <ErrorBlock>{errorMessage}</ErrorBlock>}
            </InputGroup>
          )}
          {image && (
            <>
              <ImageContainer height={imageStyle.height}>
                <Image url={formValue[name]} onClick={openFileManager} {...imageStyle} />
                {uploading && (
                  <ImageLoader
                    size='lg'
                    vertical
                    backdrop
                    height={imageStyle.height}
                    loaderHeight={imageStyle.loaderHeight}
                  />
                )}
                {!!formValue[name] && (
                  <RemoveImageButtonContainer>
                    <RemoveImageButton appearance='link' onClick={removeValue}>
                      Remove
                    </RemoveImageButton>
                  </RemoveImageButtonContainer>
                )}
              </ImageContainer>
              <input id='photo-file' ref={fileInputRef} type='file' accept='image/*' onChange={upload} hidden />
            </>
          )}
        </>
      )}
    </FormGroup>
  );
};

export default FormGroupComponent;
