const Colors = {
  PRIMARY: '#1190cb',
  SECONDARY: '#212532',
  TAGLINE: '#141414',
  COMPLEMENTARY: '#cb4c11',
  BRAND: '#546aef',
  ACCENT: '#212532',
  WHITE: '#ffffff',
  GRAY: '#8e8e93',
  BLACK: '#000000',
  CLOUD: '#f0f3f8',
  RED: '#f44336',
  GREEN: '#4caf50',
  YELLOW: '#fff176',
  COMPLETED: '#9c27b0',
  PENDING: '#e57373',
};

export default Colors;
