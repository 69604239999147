import React, { useState, useMemo, useContext, useEffect, useCallback } from 'react';
import { debounce, get } from 'lodash';
import Styled from 'styled-components';
import { Row, Col, Table, Panel, Toggle, Checkbox } from 'rsuite';
import { useHistory } from 'react-router-dom';
import moment from 'moment-timezone';

import { Firebase } from '../services';
import { OrganizationContext, LoggedUserContext, OrganizationTeamsContext } from '../context';
import { Colors } from '../assets';
import GoalTag from './GoalTag';
import DateSelector from './DateSelector';
import AutogrowInput from './AutogrowInput';
import UserSelectPicker from './UserSelectPicker';
import { docsToArray } from '../utils';
import UserAvatar from './UserAvatar';
import { TeamAvatar } from '.';
import GoalAvatar from './GoalAvatar';
import RichText from './RichText';

const ContainerPanel = Styled(Panel)({
  backgroundColor: Colors.WHITE,
});

const CompletedCell = ({ rowData, dataKey, ...props }) => (
  <Table.Cell {...props} style={{ paddingTop: 4, paddingLeft: 0, paddingBottom: 0, paddingRight: 0 }}>
    <Checkbox checked={rowData.completed} />
  </Table.Cell>
);
const ResponsibleCell = ({ rowData, dataKey, ...props }) => (
  <Table.Cell {...props} style={{ paddingTop: 8, textAlign: 'right' }}>
    <UserAvatar id={rowData.assignedTo} size='sm' whisper />
  </Table.Cell>
);

const GoalOutcomes = ({ data: goal, ...props }) => {
  const history = useHistory();
  const organization = useContext(OrganizationContext);
  const loggedUser = useContext(LoggedUserContext);
  const [outcomes, setOutcomes] = useState([]);
  const { map: teamsMap } = useContext(OrganizationTeamsContext);
  const [subGoals, setSubGoals] = useState({ list: [], map: {} });
  const [loading, setLoading] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [displayLength, setDisplayLength] = useState(10);
  const [includeSubGoals, setIncludeSubGoals] = useState(true);
  const [date, setDate] = useState(moment().format('YYYY-MM-DD'));

  const paginatedOutcomes = useMemo(
    () =>
      outcomes.filter((_, i) => {
        const start = displayLength * (activePage - 1);
        const end = start + displayLength;
        return i >= start && i < end;
      }),
    [outcomes, activePage, displayLength],
  );

  useEffect(() => {
    let subscription;
    if (organization?.id && goal?.id) {
      subscription = Firebase.firestore()
        .collection('organizations')
        .doc(organization.id)
        .collection('private')
        .doc(organization.id)
        .collection('goals')
        .where('goalSequence', 'array-contains', goal.id)
        .onSnapshot((snapshots) => {
          const list = docsToArray(snapshots);
          const map = list.reduce((r, u) => ({ ...r, [u.id]: u }), {});
          setSubGoals({ list, map });
        });
    }
    return () => subscription && subscription();
  }, [organization?.id, goal?.id]);

  useEffect(() => {
    let subscription;
    if (organization?.id && goal?.id) {
      setLoading(true);
      subscription = Firebase.firestore()
        .collection('organizations')
        .doc(organization.id)
        .collection('private')
        .doc(organization.id)
        .collection('outcomes')
        .where('deletedAt', '==', null)
        .where(includeSubGoals ? 'goalSequence' : 'goal', includeSubGoals ? 'array-contains' : '==', goal.id)
        .where('date', '==', date)
        .onSnapshot((docs) => {
          setOutcomes(docsToArray(docs));
          setLoading(false);
        });
      setActivePage(1);
    }
    return () => subscription && subscription();
  }, [organization?.id, goal?.id, date, includeSubGoals]);

  const TeamCell = useCallback(
    ({ rowData, dataKey, ...props }) => (
      <Table.Cell {...props} style={{ paddingTop: 8, textAlign: 'right' }}>
        <TeamAvatar data={teamsMap[rowData.team]} size='sm' whisper />
      </Table.Cell>
    ),
    [teamsMap],
  );

  const GoalCell = useCallback(
    ({ rowData, dataKey, ...props }) => (
      <Table.Cell {...props} style={{ padding: 0, paddingTop: 6, textAlign: 'right' }}>
        <GoalAvatar data={subGoals.map[rowData.goal]} whisper appearance='subtle' />
      </Table.Cell>
    ),
    [subGoals],
  );

  const DescriptionCell = useCallback(
    ({ rowData, dataKey, ...props }) => (
      <Table.Cell {...props} style={{ padding: 0, paddingTop: 5 }}>
        <RichText
          placeholder={' '}
          value={rowData.description}
          toolbar={false}
          floatingToolbar={false}
          readOnly={true}
          placeholderClassName='editor-placeholder-inline'
          editorInputClassName='editor-input-inline'
          editorInnerStyle={{ backgroundColor: 'transparent' }}
        />
      </Table.Cell>
    ),
    [],
  );

  return (
    <Row gutter={16} style={{ marginBottom: 20 }}>
      <Col xs={24} style={{ display: 'flex', alignItems: 'flex-end', marginBottom: 20 }}>
        <div style={{ flex: 1 }}>
          <h6>Outcomes</h6>
        </div>
        {/* <div style={{ marginRight: 8 }}>
          <ContainerPanel style={{ maxHeight: 60 }}>
            <span style={{ marginRight: 8 }}>Include sub-goals</span>
            <Toggle checked={includeSubGoals} onChange={setIncludeSubGoals} />
          </ContainerPanel>
        </div> */}
        <div>
          <DateSelector onChange={({ date }) => setDate(date)} value={date} placement='left' />
        </div>
      </Col>
      <Col xs={24}>
        <ContainerPanel>
          <Table height={450} data={paginatedOutcomes} loading={loading}>
            <Table.Column>
              <Table.HeaderCell>Is Completed?</Table.HeaderCell>
              <CompletedCell dataKey='completed' />
            </Table.Column>

            <Table.Column flexGrow>
              <Table.HeaderCell>Description</Table.HeaderCell>
              <DescriptionCell dataKey='description' />
            </Table.Column>

            <Table.Column align='right'>
              <Table.HeaderCell>Responsible</Table.HeaderCell>
              <ResponsibleCell dataKey='assignedTo' />
            </Table.Column>

            <Table.Column align='right'>
              <Table.HeaderCell>Team</Table.HeaderCell>
              <TeamCell dataKey='team' />
            </Table.Column>
            <Table.Column align='right'>
              <Table.HeaderCell>Goal</Table.HeaderCell>
              <GoalCell dataKey='goal' />
            </Table.Column>
          </Table>
          <Table.Pagination
            style={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 0 }}
            lengthMenu={[
              {
                value: 10,
                label: 10,
              },
              {
                value: 20,
                label: 20,
              },
              {
                value: 30,
                label: 30,
              },
              {
                value: 40,
                label: 40,
              },
              {
                value: 50,
                label: 50,
              },
            ]}
            activePage={activePage}
            displayLength={displayLength}
            total={outcomes.length}
            onChangePage={setActivePage}
            onChangeLength={(value) => {
              setDisplayLength(value);
              setActivePage(1);
            }}
          />
        </ContainerPanel>
      </Col>
    </Row>
  );
};

export default GoalOutcomes;
