import React, { useMemo, useCallback, useContext } from 'react';
import { Avatar, Tooltip, Whisper } from 'rsuite';
import { useHistory } from 'react-router-dom';

import { OrganizationContext } from '../context';
import { Colors } from '../assets';

const TeamAvatar = ({ style = {}, whisper = false, placement = 'bottom', data: team, ...props }) => {
  const organization = useContext(OrganizationContext);
  const history = useHistory();

  const goToTeam = useCallback(() => {
    history.push(`/${organization.id}/team/${team.id}`);
  }, [organization?.id, team?.id]);

  const AvatarData = useMemo(
    () => (
      <Avatar
        square
        size='md'
        style={{
          backgroundColor: Colors.TAGLINE,
          cursor: 'pointer',
          ...style,
        }}
        src={team?.photoURL}
        onClick={goToTeam}
        {...props}
      >
        {!team?.photoURL ? (team?.displayName || '').substring(0, 2) : null}
      </Avatar>
    ),
    [goToTeam, team, style, props],
  );

  return whisper ? (
    <Whisper placement={placement} speaker={<Tooltip>{team?.displayName}</Tooltip>}>
      {AvatarData}
    </Whisper>
  ) : (
    <>{AvatarData}</>
  );
};

export default TeamAvatar;
