import React, { useRef, useEffect } from 'react';
import autosize from 'autosize';

const AutogrowInput = ({ style = {}, onChange = () => {}, rows = 1, value, ...props }) => {
  const ref = useRef(null);

  useEffect(() => {
    autosize(ref.current);
  }, [ref]);

  useEffect(() => {
    if (ref) {
      autosize.update(ref.current);
    }
  }, [value]);

  return (
    <textarea
      ref={ref}
      style={{ resize: 'none', ...style }}
      className='rs-input'
      resize={false}
      componentClass='textarea'
      value={value}
      rows={rows}
      onChange={({ target: { value: newVal } }) => onChange(newVal)}
      {...props}
    />
  );
};

export default AutogrowInput;
