import React, { useState, useCallback, useContext, useMemo } from 'react';
import { get, slice } from 'lodash';
import Styled from 'styled-components';
import {
  Avatar,
  Row,
  Col,
  Dropdown,
  Icon,
  Popover,
  Whisper,
  IconButton,
  Modal,
  Notification,
  Tooltip,
} from 'rsuite';
import { useHistory } from 'react-router-dom';

import { Bugsnag, Firebase, Mixpanel } from '../services';
import { Colors } from '../assets';
import { TeamForm } from '../components';
import {
  LoggedUserContext,
  OrganizationContext,
  OrganizationUsersContext,
} from '../context';

const TeamInfo = Styled.div({
  marginLeft: 8,
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'start',
  alignSelf: 'center',
  flexDirection: 'column',
  top: 0,
});

const MiniProfilesContainer = Styled.div({
  display: 'flex',
  verticalAlign: 'center',
  textAlign: 'center',
  alignItems: 'center',
  alignContent: 'center',
  cursor: 'pointer',
});

const TeamItem = ({ data: team, setExpand, ...props }) => {
  const history = useHistory();
  const organization = useContext(OrganizationContext);
  const loggedUser = useContext(LoggedUserContext);
  const { map: users } = useContext(OrganizationUsersContext);
  const [showTeamFormModal, setShowTeamFormModal] = useState(false);

  const doSetShowTeamFormModal = useCallback(
    (value) => () => setShowTeamFormModal(value),
    [],
  );

  const members = useMemo(
    () => [...Object.keys(team.leaders), ...Object.keys(team.members)],
    [team],
  );

  const remove = useCallback(async () => {
    try {
      if (window.confirm('Are you sure you want to remove this team?')) {
        await Firebase.firestore()
          .collection('organizations')
          .doc(organization.id)
          .collection('private')
          .doc(organization.id)
          .collection('teams')
          .doc(team.id)
          .update({
            deletedBy: loggedUser.id,
            deletedAt: Firebase.firestore.FieldValue.serverTimestamp(),
          });
          Mixpanel.track('Deleted Team');
      }
    } catch (ex) {
      Notification.error({
        title: 'There has been an error',
        description: get(ex, 'message', 'Please try again...'),
      });
      Bugsnag.notify(ex, (event) => {
        event.severity = 'warning';
      });
    }
  }, [team.id, organization.id, loggedUser.id]);

  const update = useCallback(
    async (data) => {
      try {
        await Firebase.firestore()
          .collection('organizations')
          .doc(organization.id)
          .collection('private')
          .doc(organization.id)
          .collection('teams')
          .doc(team.id)
          .update({
            ...data,
            updatedBy: loggedUser.id,
            updatedAt: Firebase.firestore.FieldValue.serverTimestamp(),
          });
      } catch (ex) {
        Notification.error({
          title: 'There has been an error',
          description: get(ex, 'message', 'Please try again...'),
        });
        Bugsnag.notify(ex, (event) => {
          event.severity = 'warning';
        });
      }
    },
    [loggedUser.id, team.id, organization.id],
  );

  const disable = useCallback(async () => {
    if (window.confirm('Are you sure you want to disable this team?')) {
      Mixpanel.track('Disabled Team');
      return update({ disabled: true });
    }
  }, [update]);

  const enable = useCallback(async () => {
    if (window.confirm('Are you sure you want to enable this team?')) {
      Mixpanel.track('Enabled Team');
      return update({ disabled: false });
    }
  }, [update]);

  const goToTeam = useCallback(() => {
    history.push(`/${organization.id}/team/${team.id}`);
    setExpand(false);
  }, []);

  return (
    <Row gutter={16} {...props}>
      <Col xs={16} style={{ display: 'flex' }}>
        <Avatar
          square
          size='md'
          style={{
            backgroundColor: Colors.TAGLINE,
          }}
          src={team.photoURL}
        >
          {!team.photoURL ? (team.displayName || '').substring(0, 2) : null}
        </Avatar>
        <TeamInfo>
          <span>{team.displayName}</span>
        </TeamInfo>
      </Col>
      <Col
        xs={8}
        style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'row-reverse',
        }}
      >
        <div>
          <IconButton
            appearance='primary'
            style={{ marginLeft: 8 }}
            icon={<Icon icon='chevron-right' />}
            disabled={team.disabled}
            onClick={goToTeam}
          />
        </div>
        <div>
          <Whisper
            trigger='click'
            placement='bottomEnd'
            speaker={
              <Popover placement='bottomEnd' full>
                <Dropdown.Menu>
                  <Dropdown.Item
                    icon={<Icon icon='edit' />}
                    onSelect={doSetShowTeamFormModal(true)}
                    disabled={loggedUser.role === 'Member' && !get(team, `leaders.${loggedUser.id}`, false)}
                  >
                    Edit
                  </Dropdown.Item>
                  <Dropdown.Item
                    icon={
                      <Icon
                        icon={team.disabled ? 'plus-square-o' : 'collasped-o'}
                      />
                    }
                    disabled={loggedUser.role === 'Member'}
                    onSelect={team.disabled ? enable : disable}
                  >
                    {team.disabled ? 'Enable' : 'Disable'}
                  </Dropdown.Item>
                  <Dropdown.Item
                    icon={<Icon icon='close' />}
                    disabled={loggedUser.role === 'Member'}
                    onSelect={remove}
                  >
                    Delete
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Popover>
            }
          >
            <IconButton icon={<Icon icon='ellipsis-v' />} />
          </Whisper>
        </div>
        <MiniProfilesContainer>
          {slice(members, 0, 5).map((id, index) => (
            <Whisper
              placement='bottom'
              speaker={
                <Tooltip>
                  {get(users, `${id}.displayName`, 'Deleted User')}
                </Tooltip>
              }
            >
              <Avatar
                circle
                size='sm'
                style={{
                  backgroundColor: Colors.TAGLINE,
                  marginRight: index < members.length - 1 ? -10 : 4,
                }}
                src={get(users, `${id}.photoURL`)}
              >
                {!get(users, `${id}.photoURL`)
                  ? get(
                      users,
                      `${id}.displayName`,
                      'Deleted User',
                    ).substring(0, 2)
                  : null}
              </Avatar>
            </Whisper>
          ))}
        </MiniProfilesContainer>
      </Col>
      <Modal
        key={`team-form-${team.id}`}
        show={showTeamFormModal}
        onHide={doSetShowTeamFormModal(false)}
        overflow={false}
      >
        <Modal.Header>
          <Modal.Title>Edit Team</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ paddingBottom: 0 }}>
          <TeamForm data={team} onSave={doSetShowTeamFormModal(false)} />
        </Modal.Body>
      </Modal>
    </Row>
  );
};

export default TeamItem;
