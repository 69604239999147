import { TextNode } from "lexical";

export class JiraTicketNode extends TextNode {
  __state;

  static getType() {
    return "jira-ticket";
  }

  static clone(node) {
    return new JiraTicketNode(node.__text, node.__key, node.__state);
  }

  constructor(text, state = 'loading') {
    super(text);
    this.__state = state;
  }

  createDOM(config) {
    const dom = super.createDOM(config);
    return dom;
  }

  exportJSON() {
    return {
      text: this.__text,
      type: 'jira-ticket',
      state: 'done',
      version: 1,
    };
  }

  importJSON({text, state}) {
    const node = $createJiraTicketNode(text, state);
    return node;
  }
}

export function $isJiraTicketNode(node) {
  return node instanceof JiraTicketNode;
}

export function $createJiraTicketNode(text) {
  return new JiraTicketNode(text).setMode("token");
}