import Styled from 'styled-components';

import {
  IntryLogoWhite,
  IntryLogo,
  IntryLogoWhiteSmall,
  IntryLogoSmall,
} from '../assets';

const Logo = Styled.div((props) => ({
  backgroundImage: `url('${
    props.white
      ? props.small
        ? IntryLogoWhiteSmall
        : IntryLogoWhite
      : props.small
      ? IntryLogoSmall
      : IntryLogo
  }')`,
  backgroundPosition: 'center',
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  width: props.width || 200,
  height: props.height || 100,
  marginRight: 'auto',
  marginLeft: 'auto',
  marginBottom: props.marginBottom || 0,
  filter: props.filter,
}));

export default Logo;
