import React from 'react';
import Styled from 'styled-components';

import { Colors } from '../assets';

const Page = Styled.div(({ background }) => ({
  width: '100%',
  height: '100vh',
  transition: 'background .3s',
  backgroundColor: Colors.PRIMARY,
  ...(background
    ? {
        backgroundImage: `url('${background}')`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      }
    : {}),
}));

const Container = Styled.div({
  width: 500,
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  '@media (max-width: 600px)': {
    width: '98%'
  }
});

const BrandLayout = ({ background, children }) => {
  return (
    <Page background={background}>
      <Container>{children}</Container>
    </Page>
  );
};

export default BrandLayout;
