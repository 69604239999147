import React, { useState, useCallback, useContext, useMemo } from 'react';
import { debounce, find, get, slice } from 'lodash';
import Styled from 'styled-components';
import {
  Avatar,
  Row,
  Col,
  Dropdown,
  Icon,
  Popover,
  Whisper,
  IconButton,
  Modal,
  Notification,
  Tooltip,
  Button,
  DatePicker,
  Progress,
  Input,
  ButtonGroup,
} from 'rsuite';
import { useHistory } from 'react-router-dom';
import moment from 'moment-timezone';

import { Bugsnag, Firebase } from '../services';
import { Colors } from '../assets';
import { GoalSelectPicker } from '../components';
import { OrganizationContext, TeamContext, LoggedUserContext } from '../context';
import GoalTag from './GoalTag';

const GoalInfo = Styled.div({
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'start',
  alignSelf: 'center',
  flexDirection: 'column',
  top: 0,
  flex: 1,
});

const MiniProfilesContainer = Styled.div({
  display: 'flex',
  verticalAlign: 'center',
  textAlign: 'center',
  alignItems: 'center',
  alignContent: 'center',
  cursor: 'pointer',
});

const GoalItem = ({ data: goal, goals, ...props }) => {
  const history = useHistory();
  const organization = useContext(OrganizationContext);
  const team = useContext(TeamContext);
  const loggedUser = useContext(LoggedUserContext);
  const [displayName, setDisplayName] = useState(goal.displayName);

  const goalsMap = useMemo(() => goals.reduce((r, g) => ({ ...r, [g.id]: g }), {}), [goals]);

  const update = useCallback(
    async (data) => {
      try {
        await Firebase.firestore()
          .collection('organizations')
          .doc(organization.id)
          .collection('private')
          .doc(organization.id)
          .collection('goals')
          .doc(goal.id)
          .update({
            ...data,
            goalSequence: goal.parent ? [...(find(goals, ['id', goal.parent]).goalSequence || []) , goal.id] : [goal.id],
            updatedBy: loggedUser.id,
            updatedAt: Firebase.firestore.FieldValue.serverTimestamp(),
          });
        Notification.success({
          title: 'Success',
          description: 'Team Objective has been updated',
        });
      } catch (ex) {
        Notification.error({
          title: 'There has been an error',
          description: get(ex, 'message', 'Please try again...'),
        });
        Bugsnag.notify(ex, (event) => {
          event.severity = 'warning';
        });
      }
    },
    [loggedUser.id, goal.id, organization.id, goals],
  );

  const updateWithDebounce = useCallback(debounce(update, 500), [update]);

  const updateDisplayName = useCallback(
    (value) => {
      setDisplayName(value);
      updateWithDebounce({ displayName: value });
    },
    [updateWithDebounce],
  );

  const remove = useCallback(async () => {
    if (window.confirm('Are you sure you want to delete this team objective?')) {
      return update({ deletedAt: Firebase.firestore.FieldValue.serverTimestamp(), deletedBy: loggedUser.id });
    }
  }, [update, loggedUser]);

  const archive = useCallback(async () => {
    if (window.confirm('Are you sure you want to archive this team objective?')) {
      return update({ archivedAt: Firebase.firestore.FieldValue.serverTimestamp(), archivedBy: loggedUser.id });
    }
  }, [update, loggedUser]);

  const unarchive = useCallback(async () => {
    if (window.confirm('Are you sure you want to unarchive this team objective?')) {
      return update({ archivedAt: null, archivedBy: null });
    }
  }, [update]);

  const goToGoal = useCallback(() => {
    history.push(`/${organization.id}/team/${team.id}/objective/${goal.id}`);
  }, []);

  return (
    <Row gutter={16} style={{ display: 'flex', alignContent: 'center' }} {...props}>
      <Col xs={16} style={{ display: 'flex' }}>
        <GoalTag
          code={goal.code}
          tagColor={goal.tagColor}
          textColor={goal.textColor}
          style={{
            alignSelf: 'center',
            marginRight: 10,
          }}
          onChange={(tagColor) => updateWithDebounce({ tagColor })}
        />
        <Progress.Circle
          className='rs-sm'
          style={{
            width: 50,
            display: 'inline-block',
            marginRight: 10,
          }}
          percent={goal.progress}
          status={Number(goal.progress || 0) === 100 ? 'success' : ''}
        />
        <GoalInfo>
          <Input style={{ border: 'none' }} value={displayName} onChange={updateDisplayName} />
        </GoalInfo>
      </Col>
      <Col
        xs={8}
        style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'row-reverse',
          alignSelf: 'center',
        }}
      >
        <div>
          <IconButton
            appearance='primary'
            style={{ marginLeft: 8 }}
            icon={<Icon icon='chevron-right' />}
            disabled={goal.archived}
            onClick={goToGoal}
          />
        </div>
        <div>
          <Whisper
            trigger='click'
            placement='bottomEnd'
            speaker={
              <Popover placement='bottomEnd' full>
                <Dropdown.Menu>
                  <Dropdown.Item
                    icon={<Icon icon={goal.archivedAt ? 'plus-square-o' : 'collasped-o'} />}
                    onSelect={goal.archivedAt ? unarchive : archive}
                  >
                    {goal.archivedAt ? 'Unarchive' : 'Archive'}
                  </Dropdown.Item>
                  <Dropdown.Item icon={<Icon icon='close' />} onSelect={remove}>
                    Delete
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Popover>
            }
          >
            <IconButton icon={<Icon icon='ellipsis-v' />} />
          </Whisper>
        </div>
        <div style={{marginRight: 8}}>
          <ButtonGroup>
            <Whisper
              placement={'bottom'}
              speaker={<Tooltip>{get(goalsMap, `${goal.parent}.displayName`, 'No Parent Organization Goal')}</Tooltip>}
            >
              <Button>
                <Icon icon='circle' style={{ color: get(goalsMap, `${goal.parent}.tagColor`) }} />
              </Button>
            </Whisper>
          </ButtonGroup>
        </div>
        <div>
          <DatePicker
            style={{ marginRight: 8 }}
            format='DD MMM, YYYY'
            placeholder='No due date'
            appearance='subtle'
            placement='leftStart'
            value={goal.dueDate ? goal.dueDate.toDate() : null}
            onChange={(dueDate) => update({ dueDate })}
            oneTap
          />
        </div>
        {/* <MiniProfilesContainer>
          {slice(members, 0, 5).map((id, index) => (
            <Whisper
              placement='bottom'
              speaker={
                <Tooltip>
                  {get(users, `${id}.displayName`, 'Deleted User')}
                </Tooltip>
              }
            >
              <Avatar
                circle
                size='sm'
                style={{
                  backgroundColor: Colors.TAGLINE,
                  marginRight: index < members.length - 1 ? -10 : 4,
                }}
                src={get(users, `${id}.photoURL`)}
              >
                {!get(users, `${id}.photoURL`)
                  ? get(
                      users,
                      `${id}.displayName`,
                      'Deleted User',
                    ).substring(0, 2)
                  : null}
              </Avatar>
            </Whisper>
          ))}
        </MiniProfilesContainer> */}
      </Col>
    </Row>
  );
};

export default GoalItem;
