import React, { useCallback } from 'react';
import { Alert, Button, Icon, Input, InputGroup, List } from 'rsuite';

const ArrayList = ({ value = [], onChange, disabled, min = 1, ...props }) => {
  const update = useCallback(
    (index) => (val) => {
      const copy = [...value];
      if (val) {
        copy[index] = val;
      } else {
        if (copy.length > min) {
          copy.splice(index, 1);
        } else {
          Alert.error(`There needs to be at least ${min} item${min > 1 ? 's' : ''}.`);
        }
      }
      onChange(copy);
    },
    [onChange, value],
  );

  return (
    <>
      <List {...props}>
        {value.map((item, index) => (
          <List.Item key={index} index={index}>
            <InputGroup inside>
              <Input
                placeholder='Item'
                style={{ border: 'none' }}
                value={item}
                onChange={update(index)}
                disabled={disabled}
              />
              <InputGroup.Button onClick={() => update(index)(null)}>
                <Icon icon='close' />
              </InputGroup.Button>
            </InputGroup>
          </List.Item>
        ))}
      </List>

      <Button
        color='green'
        size='xs'
        style={{ marginTop: 8 }}
        onClick={() => update(value.length)('Another Milestone')}
      >
        <Icon icon='plus' /> Add
      </Button>
    </>
  );
};

export default ArrayList;
