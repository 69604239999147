import React, { useState, useMemo, useEffect } from 'react';
import Styled from 'styled-components';
import moment from 'moment';
import {
  Row,
  Col,
  Panel,
  Placeholder,
  Input,
  Button,
  DatePicker,
  CheckboxGroup,
  Checkbox,
  SelectPicker,
  Loader,
  InputPicker,
  InputNumber,
  Message,
  Modal,
} from 'rsuite';
import { find, get, includes, isEqual, orderBy } from 'lodash';
import { Colors, Achievement as AchievementIcon } from '../assets';
import { useGoals, useGoalSetting } from '../hooks';
import GoalItem from './GoalItem';
import ArrayList from './ArrayList';
import GoalUpdateForm from './GoalUpdateForm';

const ContainerPanel = Styled(Panel)({
  backgroundColor: Colors.WHITE,
  marginTop: 8,
});

const SettingTitle = Styled.span({
  marginBottom: 4,
  display: 'block',
});

const options = [
  {
    label: 'By Goal Updates',
    value: 'updates',
    description: 'The Goal Progress will be updated with the specified percentage on goal updates.',
  },
  {
    label: 'Achieved Amount / Targeted Amount',
    value: 'amount',
    description:
      'The Goal Progress will be based on the following formula (achieved amount / targeted amount) that is provided on goal updates. Very useful for goals that track specific numbers, like profit, attendance, users, etc...',
  },
  {
    label: 'Achieved Milestone',
    value: 'milestones',
    description:
      'The goal Progress will be divided in Milestones/Steps and will get updated by the current milestone specified on goal updates.',
  },
  {
    label: 'Calculated by Sub-Goals',
    value: 'calculated-by-sub-goals',
    description: 'The Goal Progress will be calculated by the sum of the sub-goals progress.',
  },
];

const DEFAULT_VALUE = { value: 'updates' };
const DEFAULT_TARGETED_AMOUNT = 100;
const DEFAULT_MILESTONES = ['Started', 'Halfway', 'Finished'];

const GoalProgressSetting = ({ data: goal }) => {
  const { loading, saving, value, update } = useGoalSetting({ goalId: goal?.id, setting: 'progress', loadOnce: true });
  const [setting, setSetting] = useState({});
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setSetting(value || DEFAULT_VALUE);
  }, [value]);

  const description = useMemo(() => find(options, { value: setting.value })?.description, [setting?.value]);

  return (
    <>
      <Row gutter={16} style={{ marginBottom: 20 }}>
        <Col xs={24}>
          <div style={{ flex: 1 }}>
            <h6>Progress</h6>
          </div>
        </Col>

        <Col xs={24}>
          <Row gutter={16}>
            <Col>
              <ContainerPanel>
                {loading ? (
                  <Placeholder.Paragraph rows={4}>
                    <Loader center content='loading' />
                  </Placeholder.Paragraph>
                ) : (
                  <Row gutter={16}>
                    <Col xs={24} md={12}>
                      <SettingTitle>How to track progress?</SettingTitle>
                      <InputPicker
                        placeholder='Progress'
                        cleanable={false}
                        data={options}
                        value={setting.value}
                        onChange={(value) =>
                          setSetting({
                            value,
                            ...(value === 'amount' ? { targetedAmount: DEFAULT_TARGETED_AMOUNT } : {}),
                            ...(value === 'milestones' ? { milestones: DEFAULT_MILESTONES } : {}),
                          })
                        }
                        disabled={saving}
                        block
                      />
                      <SettingTitle style={{ marginBottom: 12, marginTop: 20 }}>How it works?</SettingTitle>
                      <p>{description}</p>
                    </Col>
                    {(setting.value === 'updates' || setting.value === 'calculated-by-sub-goals') && (
                      <Col xs={24} md={12} style={{ marginTop: 12 }}>
                        <Message type='info' description='No extra configuration required' />
                      </Col>
                    )}
                    {setting.value === 'amount' && (
                      <Col xs={24} md={12}>
                        <SettingTitle>Targeted Amount</SettingTitle>
                        <InputNumber
                          min={1}
                          value={setting.targetedAmount}
                          onChange={(value) => setSetting({ ...setting, targetedAmount: Number(value) })}
                          disabled={saving}
                        />
                      </Col>
                    )}
                    {setting.value === 'milestones' && (
                      <Col xs={24} md={12}>
                        <SettingTitle>Milestones</SettingTitle>
                        <ArrayList
                          value={setting?.milestones}
                          onChange={(value) => setSetting({ ...setting, milestones: value })}
                          disabled={saving}
                          min={2}
                          bordered
                        />
                      </Col>
                    )}
                    <Col xs={24} style={{ marginTop: 10 }}>
                      <div style={{ textAlign: 'right' }}>
                        <Button
                          appearance='primary'
                          onClick={() => setIsOpen(true)}
                          disabled={(!value ? isEqual(setting, DEFAULT_VALUE) : isEqual(setting, value)) || saving}
                          loading={saving}
                        >
                          Save
                        </Button>
                      </div>
                    </Col>
                  </Row>
                )}
              </ContainerPanel>
            </Col>
          </Row>
        </Col>
      </Row>
      <Modal size='md' show={isOpen} onHide={() => setIsOpen(false)}>
        <Modal.Header>
          <Modal.Title>Required Update</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{paddingBottom: 0}}>
          <GoalUpdateForm goal={goal} settings={{progress: setting}} requirement={() => update(setting)} onGoalUpdateCreated={() => setIsOpen(false)} showNotification={false} defaultHasFocused={true}/>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default GoalProgressSetting;
