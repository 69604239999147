import { get } from 'lodash';
import moment from 'moment-timezone';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Row, Col, Panel, Button, Notification, Toggle, Badge, Placeholder, Progress } from 'rsuite';
import Styled from 'styled-components';

import { Colors, UnderDevelopment } from '../assets';
import { SubscriptionProduct } from '../components';
import { LimitationsContext, LoggedUserContext, OrganizationContext, UsageContext } from '../context';
import { usePartner } from '../hooks';
import { Bugsnag, Firebase, Mixpanel } from '../services';
import { formatKbs } from '../utils';

const Container = Styled.div({
  margin: 30,
});

const Title = Styled.h2({
  marginBottom: 30,
});

const ContainerPanel = Styled(Panel)({
  backgroundColor: Colors.WHITE,
  textAlign: 'center',
  paddingTop: 20,
  marginBottom: 20,
});

const OrganizationSubscription = () => {
  const loggedUser = useContext(LoggedUserContext);
  const organization = useContext(OrganizationContext);
  const usage = useContext(UsageContext);
  const limitations = useContext(LimitationsContext);
  const [loading, setLoading] = useState(false);
  const [openingPortal, setOpeningPortal] = useState(false);
  const [subscribing, setSubscribing] = useState(false);
  const [showYearly, setShowYearly] = useState(true);
  const [products, setProducts] = useState([]);
  const { loading: stripeLoading, value: stripe } = usePartner({ partner: 'stripe' });
  const query = new URLSearchParams(useLocation().search);
  const success = query.get('success');
  const product = query.get('product');
  const price = query.get('price');

  useEffect(() => {
    if (success || product || price) {
      Mixpanel.track('Finished Checkout Session', { success, product, price });
    }
  }, [success, product, price]);

  const subscriptionDates = useMemo(() => {
    if (!stripe) return '';
    if (stripe?.subscription?.start && stripe?.subscription?.end) {
      return `${moment(stripe?.subscription?.start?.toDate()).format('ll')} to ${moment(
        stripe?.subscription?.end?.toDate(),
      ).format('ll')}`;
    } else {
      return `Since ${moment(stripe?.subscription?.start?.toDate()).format('ll')}`;
    }
  }, [stripe]);

  const openPortal = useCallback(async () => {
    try {
      setOpeningPortal(true);
      const {
        data: { url },
      } = await Firebase.functions().httpsCallable('CreateCustomerPortalUrl')();
      window.open(url, '_self');
      Mixpanel.track('Opened Customer Portal');
    } catch (ex) {
      setOpeningPortal(false);
      Notification.error({
        title: 'There has been an error',
        description: get(ex, 'message', 'Please try again...'),
      });
      Bugsnag.notify(ex, (event) => {
        event.severity = 'warning';
      });
    }
  }, []);
  const checkout = useCallback(
    async (product, price) => {
      setSubscribing(true);
      try {
        if (organization.demo) {
          throw new Error('This is not available on a Demo Environment');
        }
        const {
          data: { url },
        } = await Firebase.functions().httpsCallable('CreateCheckoutSession')({ product, price });
        window.open(url, '_self');
        Mixpanel.track('Started Checkout Session');
      } catch (ex) {
        setSubscribing(false);
        Notification.error({
          title: 'There has been an error',
          description: get(ex, 'message', 'Please try again...'),
        });
        Bugsnag.notify(ex, (event) => {
          event.severity = 'warning';
        });
      }
    },
    [organization],
  );
  useEffect(() => {
    setLoading(true);
    if (loggedUser?.role !== 'Owner' && loggedUser?.role !== 'Admin') {
      setLoading(false);
      return;
    }
    Firebase.functions()
      .httpsCallable('ListAllProducts')()
      .then(({ data }) => {
        setProducts(data);
        setLoading(false);
      })
      .catch((ex) => {
        Bugsnag.notify(ex, (event) => {
          event.severity = 'warning';
        });
        setLoading(false);
      });
  }, [loggedUser?.role]);
  return (
    <Container>
      <Row gutter={16}>
        <Col xs={16}>
          <Title>Subscription</Title>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={24}>
          <ContainerPanel style={{ paddingTop: 8 }}>
            {stripeLoading ? (
              <Row gutter={16}>
                <Col xs={24} md={6} style={{ textAlign: 'left' }}>
                  <Placeholder.Paragraph rows={2} />
                </Col>
                <Col xs={24} md={6} style={{ textAlign: 'left' }}>
                  <Placeholder.Paragraph rows={2} />
                </Col>
                <Col xs={24} md={6} style={{ textAlign: 'left' }}>
                  <Placeholder.Paragraph rows={2} />
                </Col>
                <Col xs={24} md={6} style={{ textAlign: 'left' }}>
                  <Placeholder.Paragraph rows={2} />
                </Col>
              </Row>
            ) : (
              <Row gutter={16}>
                <Col xs={24} md={6} style={{ textAlign: 'left' }}>
                  <h5>{stripe?.product?.name}</h5>
                  <span>{stripe?.product?.description}</span>
                </Col>
                <Col xs={24} md={6} style={{ textAlign: 'left' }}>
                  <h5>{stripe?.subscription?.displayedStatus}</h5>
                  <span>{subscriptionDates}</span>
                </Col>
                {stripe?.product?.id !== 0 && (
                  <>
                    <Col xs={24} md={6} style={{ textAlign: 'left' }}>
                      <h5>{`$${stripe?.price?.amount / 100} ${
                        stripe?.price?.interval === 'month' ? 'Monthly' : 'Yearly'
                      }`}</h5>
                      <span>{`Using ${(stripe?.card?.brand || '').toUpperCase()} ending ${stripe?.card?.last4}`}</span>
                    </Col>
                    <Col xs={24} md={6} style={{ textAlign: 'right' }}>
                      <Button onClick={openPortal} appearance='ghost' loading={openingPortal} disabled={openingPortal}>
                        Manage Subscription
                      </Button>
                    </Col>
                  </>
                )}
              </Row>
            )}
          </ContainerPanel>
        </Col>
        {!stripeLoading && (
          <Col xs={24}>
            <ContainerPanel style={{ paddingTop: 8, textAlign: 'left' }}>
              <Row gutter={16}>
                <Col xs={24} style={{ marginBottom: 10 }}>
                  <h5>Usage</h5>
                </Col>
                <Col xs={24}>
                  <span>
                    Users ({usage?.users?.active}/{limitations?.users?.max})
                  </span>
                  <Progress.Line
                    style={{ paddingLeft: 0, paddingRight: 0 }}
                    percent={Math.ceil((usage?.users?.active / limitations?.users?.max) * 100)}
                    strokeColor={
                      Math.ceil((usage?.users?.active / limitations?.users?.max) * 100) >= 90
                        ? Colors.RED
                        : Colors.GREEN
                    }
                  />
                </Col>
                <Col xs={24}>
                  <span>
                    Storage ({formatKbs(usage?.storage?.totalInKbs)}/{formatKbs(limitations?.storage?.maxInKbs)})
                  </span>
                  <Progress.Line
                    style={{ paddingLeft: 0, paddingRight: 0 }}
                    percent={Math.ceil((usage?.storage?.totalInKbs / limitations?.storage?.maxInKbs) * 100)}
                    strokeColor={
                      Math.ceil((usage?.storage?.totalInKbs / limitations?.storage?.maxInKbs) * 100) >= 90
                        ? Colors.RED
                        : Colors.GREEN
                    }
                  />
                </Col>
              </Row>
            </ContainerPanel>
          </Col>
        )}
        {(stripe?.product?.id || 0) === 0 && (loading ? loading : products.length > 0) && (
          <Col xs={24}>
            <ContainerPanel>
              <h5 style={{ marginBottom: 20 }}>Upgrade your subscription</h5>
              {!loading && (
                <div style={{ display: 'block', marginBottom: 20 }}>
                  <Badge content={showYearly ? '' : 'Save 20%'}>
                    <Toggle
                      size='lg'
                      checkedChildren='Yearly'
                      unCheckedChildren='Monthly'
                      checked={showYearly}
                      onChange={(yearly) => {
                        setShowYearly(yearly);
                        Mixpanel.track('Checked Available Subscription Plans', { yearly, monthly: !yearly });
                      }}
                    />
                  </Badge>
                </div>
              )}
              {loading ? (
                <>
                  <SubscriptionProduct loading={true} />
                  <SubscriptionProduct loading={true} />
                  <SubscriptionProduct loading={true} />
                </>
              ) : (
                products.map((product) => (
                  <SubscriptionProduct
                    product={product}
                    onSubscribe={checkout}
                    disabled={subscribing}
                    interval={showYearly ? 'year' : 'month'}
                  />
                ))
              )}
            </ContainerPanel>
          </Col>
        )}
      </Row>
    </Container>
  );
};

export default OrganizationSubscription;
