import React, { useState, useContext, useCallback } from 'react';
import { get } from 'lodash';
import Styled from 'styled-components';
import {
  Sidebar,
  Sidenav,
  Icon,
  Nav,
  Navbar,
  IconButton,
  Container,
  Avatar,
  Drawer,
  Whisper,
  Tooltip,
  Notification,
} from 'rsuite';

import {
  OrganizationContext,
  LoggedUserContext,
  TeamContext,
} from '../context';
import { Colors } from '../assets';
import { Logo, UserForm, ChangePassword } from '../components';
import { Firebase } from '../services';

const SiteSideBar = Styled(Sidebar)({
  backgroundColor: Colors.SECONDARY,
  display: 'flex',
  flexDirection: 'column',
});

const SiteSideNav = Styled(Sidenav)({
  backgroundColor: Colors.SECONDARY,
});

const SiteBottomBar = Styled(Navbar)({
  backgroundColor: 'transparent !important',
  bottom: 0,
  position: 'absolute',
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  color: Colors.WHITE,
  verticalAlign: 'center',
  textAlign: 'center',
  alignItems: 'stretch',
  alignContent: 'center',
});

const SiteContainer = Styled(Container)({
  minHeight: '100vh',
});

const PageContainer = Styled(Container)({
  backgroundColor: Colors.CLOUD,
  overflow: 'auto',
  maxHeight: '100vh',
});

const MiniProfileContainer = Styled.div({
  display: 'flex',
  verticalAlign: 'center',
  textAlign: 'center',
  alignItems: 'center',
  alignContent: 'center',
  cursor: 'pointer',
  flex: 1,
  color: Colors.WHITE,
});

const DisplayName = Styled.strong({
  marginRight: 4,
});

const BodyContainer = Styled.div({
  width: '98%',
});

const DrawerContainer = Styled.div({
  width: '100%',
  paddingTop: 20,
});

const LogoImage = Styled.div(({ url }) => ({
  backgroundImage: `url('${url}')`,
  backgroundPosition: 'center',
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  backgroundColor: Colors.TAGLINE,
  width: '100%',
  height: 200,
  marginRight: 'auto',
  marginLeft: 'auto',
  borderRadius: 8,
  cursor: 'pointer',
}));

const TeamInfoContainer = Styled.div({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  alignItems: 'center',
  alignContent: 'center',
  textAlign: 'center',
  verticalAlign: 'center',
});

const TeamName = Styled.h6({
  marginTop: 4,
  color: Colors.WHITE,
});

const OrganizationLayout = ({ active, onSelect, children }) => {
  const [expand, setExpand] = useState(true);
  const [showDrawer, setShowDrawer] = useState(false);
  const [currentNavKey, setCurrentNavKey] = useState('profile');
  const organization = useContext(OrganizationContext);
  const team = useContext(TeamContext);
  const loggedUser = useContext(LoggedUserContext);

  return (
    <SiteContainer>
      <SiteSideBar width={expand ? 240 : 56} collapsible>
        <Sidenav.Header
          style={{
            minHeight: 100,
            paddingTop: expand ? 12 : 30,
            paddingBottom: expand ? 12 : 30,
          }}
        >
          <TeamInfoContainer
            style={{
              paddingTop: expand ? 10 : 0,
            }}
          >
            {expand ? (
              <Avatar
                square
                size='md'
                style={{
                  backgroundColor: Colors.PRIMARY,
                }}
                src={team.photoURL}
              >
                {!team.photoURL
                  ? (team.displayName || '').substring(0, 2)
                  : null}
              </Avatar>
            ) : (
              <Whisper
                placement='right'
                speaker={<Tooltip>{team.displayName}</Tooltip>}
              >
                <Avatar
                  square
                  size='md'
                  style={{
                    backgroundColor: Colors.PRIMARY,
                  }}
                  src={team.photoURL}
                >
                  {!team.photoURL
                    ? (team.displayName || '').substring(0, 2)
                    : null}
                </Avatar>
              </Whisper>
            )}
            {expand && <TeamName>{team.displayName}</TeamName>}
          </TeamInfoContainer>
        </Sidenav.Header>
        <SiteSideNav
          expanded={expand}
          activeKey={active}
          appearance='inverse'
          onSelect={onSelect}
        >
          <Sidenav.Body>
            <Nav>
              <Nav.Item eventKey='analytics' icon={<Icon icon='bar-chart' />}>
                Analytics
              </Nav.Item>
              <Nav.Item eventKey='outcomes' icon={<Icon icon='task' />}>
                Outcomes
              </Nav.Item>
              <Nav.Item eventKey='objectives' icon={<Icon icon='bullseye' />}>
                Objectives
              </Nav.Item>
              <Nav.Item eventKey='members' icon={<Icon icon='people-group' />}>
                Members
              </Nav.Item>
              <Nav.Item eventKey='configuration' icon={<Icon icon='wrench' />}>
                Configuration
              </Nav.Item>
            </Nav>
          </Sidenav.Body>
        </SiteSideNav>
        <SiteBottomBar
          style={{
            width: expand ? 240 : 56,
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: expand ? 'row' : 'column',
              alignItems: 'center',
              marginBottom: 20,
              paddingLeft: expand ? 15 : 5,
              paddingRight: expand ? 15 : 5,
            }}
          >
            <div style={{ flex: 1 }}></div>
            <div style={{ ...(!expand ? { flex: 1 } : {}) }}>
              <Whisper
                placement={expand ? 'top' : 'right'}
                speaker={<Tooltip>Toggle Expand</Tooltip>}
              >
                <IconButton
                  size='sm'
                  color='cyan'
                  icon={
                    <Icon
                      style={{
                        color: Colors.WHITE,
                      }}
                      icon={expand ? 'arrow-left-line' : 'arrow-right-line'}
                    />
                  }
                  onClick={() => setExpand(!expand)}
                />
              </Whisper>
            </div>
          </div>
        </SiteBottomBar>
      </SiteSideBar>
      <PageContainer>{children}</PageContainer>
      <Drawer show={showDrawer} onHide={() => setShowDrawer(false)}>
        <Drawer.Header>
          <Drawer.Title>User Configuration</Drawer.Title>
        </Drawer.Header>
        <Drawer.Body>
          <BodyContainer>
            <Nav
              activeKey={currentNavKey}
              onSelect={setCurrentNavKey}
              appearance='subtle'
            >
              <Nav.Item eventKey='profile'>Profile</Nav.Item>
              {!organization?.demo && <Nav.Item eventKey='password'>Change Password</Nav.Item>}
            </Nav>
            <DrawerContainer>
              {currentNavKey === 'profile' && <UserForm data={loggedUser} />}
              {currentNavKey === 'password' && <ChangePassword />}
            </DrawerContainer>
          </BodyContainer>
        </Drawer.Body>
      </Drawer>
    </SiteContainer>
  );
};

export default OrganizationLayout;
