import { useState, useContext, useEffect, useCallback } from 'react';
import { get } from 'lodash';
import { Notification } from 'rsuite';

import { Bugsnag, Firebase, Mixpanel } from '../services';
import { LoggedUserContext, OrganizationContext } from '../context';

const useGoalSetting = ({ goalId, setting, loadOnce }) => {
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [value, setValue] = useState(null);
  const loggedUser = useContext(LoggedUserContext);
  const organization = useContext(OrganizationContext);

  useEffect(() => {
    let subscription = () => {};
    setLoading(true);
    if (organization.id) {
      subscription = Firebase.firestore()
        .collection('organizations')
        .doc(organization.id)
        .collection('private')
        .doc(organization.id)
        .collection('goals')
        .doc(goalId)
        .collection('settings')
        .doc(setting)
        .onSnapshot((doc) => {
          if (doc.exists) {
            setValue(doc.data());
          } else {
            setValue(null);
          }
          setLoading(false);
          if (loadOnce) {
            subscription();
          }
        });
    }
    return subscription;
  }, [organization.id, goalId, setting, loadOnce]);

  const update = useCallback(
    async (updatedValue) => {
      setSaving(true);
      try {
        if (!updatedValue) return;
        await Firebase.firestore()
          .collection('organizations')
          .doc(organization.id)
          .collection('private')
          .doc(organization.id)
          .collection('goals')
          .doc(goalId)
          .collection('settings')
          .doc(setting)
          .set({
            ...updatedValue,
            updatedBy: loggedUser.id,
            updatedAt: Firebase.firestore.FieldValue.serverTimestamp(),
          });
        setValue({
          ...updatedValue,
          updatedBy: loggedUser.id,
          updatedAt: Firebase.firestore.FieldValue.serverTimestamp(),
        });
        Notification.success({
          title: 'Success',
          description: 'Setting has been updated',
        });
        Mixpanel.track('Updated Goal Setting', { setting });
      } catch (ex) {
        Notification.error({
          title: 'There has been an error',
          description: get(ex, 'message', 'Please try again...'),
        });
        Bugsnag.notify(ex, (event) => {
          event.severity = 'warning';
        });
      } finally {
        setSaving(false);
      }
    },
    [loggedUser.id, organization.id, goalId, setting],
  );

  return { loading, saving, update, value };
};

export default useGoalSetting;
