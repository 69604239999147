import React, { useState, useCallback, useContext, useRef } from 'react';
import { debounce, get, slice } from 'lodash';
import Styled from 'styled-components';
import {
  Avatar,
  Row,
  Col,
  Dropdown,
  Icon,
  Popover,
  Whisper,
  IconButton,
  Modal,
  Notification,
  Tooltip,
  Button,
  DatePicker,
  Progress,
  Input,
  Panel,
  Animation,
} from 'rsuite';
import { useHistory } from 'react-router-dom';
import moment from 'moment-timezone';

import { Bugsnag, Firebase, Mixpanel } from '../services';
import { Colors } from '../assets';
import { GoalForm, TeamSelectPicker } from '../components';
import { OrganizationContext, LoggedUserContext } from '../context';
import GoalTag from './GoalTag';

const GoalInfo = Styled.div({
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'start',
  alignSelf: 'center',
  flexDirection: 'column',
  top: 0,
  flex: 1,
});

const ContainerPanel = Styled(Panel)({
  backgroundColor: Colors.WHITE,
});

const MiniProfilesContainer = Styled.div({
  display: 'flex',
  verticalAlign: 'center',
  textAlign: 'center',
  alignItems: 'center',
  alignContent: 'center',
  cursor: 'pointer',
});

const GoalItem = ({ data: goal, onSelect, teamId, showTeam = true, ...props }) => {
  const history = useHistory();
  const organization = useContext(OrganizationContext);
  const loggedUser = useContext(LoggedUserContext);
  const [displayName, setDisplayName] = useState(goal.displayName);
  const [showSubGoals, setShowSubGoals] = useState(false);
  const whisperRef = useRef();

  const update = useCallback(
    async (data) => {
      try {
        await Firebase.firestore()
          .collection('organizations')
          .doc(organization.id)
          .collection('private')
          .doc(organization.id)
          .collection('goals')
          .doc(goal.id)
          .update({
            ...data,
            updatedBy: loggedUser.id,
            updatedAt: Firebase.firestore.FieldValue.serverTimestamp(),
          });
          Mixpanel.track('Updated Goal');
      } catch (ex) {
        Notification.error({
          title: 'There has been an error',
          description: get(ex, 'message', 'Please try again...'),
        });
        Bugsnag.notify(ex, (event) => {
          event.severity = 'warning';
        });
      }
    },
    [loggedUser.id, goal.id, organization.id],
  );

  const updateWithDebounce = useCallback(debounce(update, 500), [update]);

  const updateDisplayName = useCallback(
    (value) => {
      setDisplayName(value);
      updateWithDebounce({ displayName: value });
    },
    [updateWithDebounce],
  );

  const remove = useCallback(async () => {
    if (window.confirm('Are you sure you want to delete this goal?')) {
      Mixpanel.track('Deleted Goal');
      return update({ deletedAt: Firebase.firestore.FieldValue.serverTimestamp(), deletedBy: loggedUser.id });
    }
  }, [update, loggedUser]);

  const archive = useCallback(async () => {
    if (window.confirm('Are you sure you want to archive this goal?')) {
      Mixpanel.track('Achieved Goal');
      return update({ archivedAt: Firebase.firestore.FieldValue.serverTimestamp(), archivedBy: loggedUser.id });
    }
  }, [update, loggedUser]);

  const unarchive = useCallback(async () => {
    if (window.confirm('Are you sure you want to unarchive this goal?')) {
      Mixpanel.track('Unarchived Goal');
      return update({ archivedAt: null, archivedBy: null });
    }
  }, [update]);

  const goToGoal = useCallback(() => {
    if (teamId) {
      history.push(`/${organization.id}/team/${teamId}/objective/${goal.id}`);
    } else {
      history.push(`/${organization.id}/goal/${goal.id}`);
    }
    if (onSelect) {
      onSelect();
    }
  }, [goal.id, organization.id, history, onSelect, teamId]);

  return (
    <div key={goal?.id}>
      <ContainerPanel
        style={
          goal.archivedAt
            ? {
                borderColor: Colors.COMPLEMENTARY,
                borderWidth: 1,
                borderStyle: 'dashed',
              }
            : {}
        }
      >
        <Row gutter={16} style={{ display: 'flex', alignContent: 'center' }} {...props}>
          <Col xs={16} style={{ display: 'flex' }}>
            <GoalTag
              code={goal.code}
              tagColor={goal.tagColor}
              textColor={goal.textColor}
              style={{
                alignSelf: 'center',
                marginRight: 10,
              }}
              onChange={(tagColor) => updateWithDebounce({ tagColor })}
            />
            <Progress.Circle
              className='rs-sm'
              style={{
                width: 50,
                display: 'inline-block',
                marginRight: 10,
              }}
              percent={goal.progress}
              status={Number(goal.progress || 0) === 100 ? 'success' : ''}
            />
            <GoalInfo>
              <Input style={{ border: 'none' }} value={displayName} onChange={updateDisplayName} />
            </GoalInfo>
          </Col>
          <Col
            xs={8}
            style={{
              flex: 1,
              display: 'flex',
              flexDirection: 'row-reverse',
              alignSelf: 'center',
            }}
          >
            <div>
              <IconButton
                appearance='primary'
                style={{ marginLeft: 8 }}
                icon={<Icon icon='chevron-right' />}
                disabled={goal.archived}
                onClick={goToGoal}
              />
            </div>
            <div>
              <Whisper
                trigger='click'
                placement='bottomEnd'
                ref={whisperRef}
                speaker={
                  <Popover placement='bottomEnd' full>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        icon={<Icon icon={goal.archivedAt ? 'plus-square-o' : 'collasped-o'} />}
                        onSelect={goal.archivedAt ? unarchive : archive}
                      >
                        {goal.archivedAt ? 'Unarchive' : 'Archive'}
                      </Dropdown.Item>
                      <Dropdown.Item icon={<Icon icon='close' />} onSelect={remove}>
                        Delete
                      </Dropdown.Item>
                      {goal.subGoals && goal.subGoals.length > 0 && (
                        <Dropdown.Item
                          icon={<Icon icon={showSubGoals ? 'arrow-up' : 'arrow-down'} />}
                          onSelect={() => {
                            setShowSubGoals(!showSubGoals);
                            whisperRef.current.close();
                          }}
                        >
                          {`${showSubGoals ? 'Hide' : 'Show'} sub-goals`}
                        </Dropdown.Item>
                      )}
                    </Dropdown.Menu>
                  </Popover>
                }
              >
                <IconButton icon={<Icon icon='ellipsis-v' />} />
              </Whisper>
            </div>
            <div>
              <DatePicker
                style={{ marginRight: 8 }}
                format='DD MMM, YYYY'
                placeholder='Due Date'
                appearance='subtle'
                placement='leftStart'
                value={goal.dueDate ? goal.dueDate.toDate() : null}
                onChange={(dueDate) => update({ dueDate })}
                cleanable={false}
                ranges={[
                  {
                    label: 'yesterday',
                    value: moment().add(-1, 'day').toDate(),
                  },
                  {
                    label: 'today',
                    value: new Date(),
                  },
                  ...(get(goal, 'dueDate')
                    ? [
                        {
                          label: 'Remove',
                          value: null,
                        },
                      ]
                    : []),
                ]}
                oneTap
              />
            </div>
            {showTeam && (
              <div>
                <TeamSelectPicker
                  style={{ marginRight: 2 }}
                  value={get(goal, 'team')}
                  onChange={(team) => update({ team })}
                  appearance='subtle'
                  cleanable={false}
                  placeholder='Team'
                />
              </div>
            )}
            {/* <MiniProfilesContainer>
          {slice(members, 0, 5).map((id, index) => (
            <Whisper
              placement='bottom'
              speaker={
                <Tooltip>
                  {get(users, `${id}.displayName`, 'Deleted User')}
                </Tooltip>
              }
            >
              <Avatar
                circle
                size='sm'
                style={{
                  backgroundColor: Colors.TAGLINE,
                  marginRight: index < members.length - 1 ? -10 : 4,
                }}
                src={get(users, `${id}.photoURL`)}
              >
                {!get(users, `${id}.photoURL`)
                  ? get(
                      users,
                      `${id}.displayName`,
                      'Deleted User',
                    ).substring(0, 2)
                  : null}
              </Avatar>
            </Whisper>
          ))}
        </MiniProfilesContainer> */}
          </Col>
        </Row>
      </ContainerPanel>
      {goal.subGoals && goal.subGoals.length > 0 && (
        <>
          <Animation.Collapse in={showSubGoals}>
            <Row gutter={16} style={{ paddingLeft: 20 }}>
              {goal.subGoals.map((g) => (
                <Col style={{ marginTop: 10 }}>
                  <GoalItem data={g} onSelect={onSelect} showTeam={showTeam} teamId={teamId} {...props} />
                </Col>
              ))}
            </Row>
          </Animation.Collapse>
        </>
      )}
    </div>
  );
};

export default GoalItem;
