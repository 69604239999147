import mixpanel from 'mixpanel-browser';

import config from '../config';

if (config.mixpanel.enabled) {
  mixpanel.init(config.mixpanel.apiKey, { debug: config.mixpanel.debug });
}

const instance = {
  track: (eventName, properties) => {
    if (config.mixpanel.enabled) {
      mixpanel.track(eventName, properties);
    }
  },
  identify: (userId) => {
    if (config.mixpanel.enabled) {
      mixpanel.identify(userId);
    }
  },
  set_group: (groupName, groupValue) => {
    if (config.mixpanel.enabled) {
      mixpanel.set_group(groupName, groupValue);
    }
  },
  people: {
    set: (properties) => {
      if (config.mixpanel.enabled) {
        mixpanel.people.set(properties);
      }
    },
    increment: (property, value) => {
      if (config.mixpanel.enabled) {
        mixpanel.people.increment(property, value);
      }
    },
  },
};

export default instance;
