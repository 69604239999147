import React, { useState, useCallback, useContext, useRef } from 'react';
import { get } from 'lodash';
import moment from 'moment-timezone';
import { Form, Button, Notification, Schema, FlexboxGrid, InputPicker } from 'rsuite';

import { Bugsnag, Firebase, Mixpanel } from '../services';
import { LoggedUserContext, OrganizationContext } from '../context';
import { FormGroup } from '../components';
import { UserRoles } from '../types';
import { generateIdBasedOn, uuidv4 } from '../utils';

const { StringType } = Schema.Types;

const userFormModel = Schema.Model({
  displayName: StringType()
    .minLength(4, 'The field cannot be less than 4 characters')
    .isRequired('This field is required'),
  email: StringType().isRequired('This field is required').isEmail('Please enter a valid email'),
  role: StringType().isOneOf(UserRoles, 'Needs to be valid role').isRequired('This field is required'),
});

const roleOptions = UserRoles.map((r) => ({
  label: r,
  value: r,
}));

const UserForm = ({ data: user, isNew, onSave, usedEmails }) => {
  const [saving, setSaving] = useState(false);
  const loggedUser = useContext(LoggedUserContext);
  const organization = useContext(OrganizationContext);
  const userFormRef = useRef(null);
  const [userFormValue, setUserFormValue] = useState(
    isNew
      ? {}
      : {
          photoURL: user.photoURL || '',
          displayName: user.displayName,
          email: user.email,
          role: user.role,
        },
  );
  const [userFormError, setUserFormError] = useState({});

  const save = useCallback(async () => {
    setSaving(true);
    try {
      if (isNew) {
        if (usedEmails.indexOf(userFormValue.email) !== -1) {
          throw new Error('Email already in use');
        }
        await Firebase.firestore()
          .collection('organizations')
          .doc(organization.id)
          .collection('private')
          .doc(organization.id)
          .collection('invites')
          .add({
            ...userFormValue,
            createdBy: loggedUser.id,
            createdAt: Firebase.firestore.FieldValue.serverTimestamp(),
            deletedBy: null,
            deletedAt: null,
          });
        Mixpanel.track('Created User');
        Mixpanel.people.increment("Users Created");
      } else {
        await Firebase.firestore()
          .collection('organizations')
          .doc(organization.id)
          .collection('private')
          .doc(organization.id)
          .collection('users')
          .doc(user.id)
          .update({
            ...userFormValue,
            updatedBy: loggedUser.id,
            updatedAt: Firebase.firestore.FieldValue.serverTimestamp(),
          });
        Mixpanel.track('Updated User');
      }
      if (onSave) onSave();
    } catch (ex) {
      Notification.error({
        title: 'There has been an error',
        description: get(ex, 'message', 'Please try again...'),
      });
      Bugsnag.notify(ex, (event) => {
        event.severity = 'warning';
      });
    }
    setSaving(false);
  }, [isNew, user, loggedUser.id, onSave, userFormValue, organization, usedEmails]);

  return (
    <Form
      fluid
      key='userForm'
      ref={userFormRef}
      onChange={setUserFormValue}
      onCheck={setUserFormError}
      formValue={userFormValue}
      model={userFormModel}
    >
      <FormGroup image label='Photo' name='photoURL' imageStyle={{ loaderHeight: 250 }} />
      <FormGroup
        label='Display Name'
        name='displayName'
        error={!!userFormError.displayName}
        errorMessage={userFormError.displayName}
        disabled={saving}
      />
      <FormGroup
        label='Email'
        name='email'
        error={!!userFormError.email}
        errorMessage={userFormError.email}
        disabled={saving || !isNew}
      />
      <FormGroup
        label='Role'
        name='role'
        error={!!userFormError.role}
        errorMessage={userFormError.role}
        disabled={saving || (!isNew && user.role === 'Owner') || loggedUser.role === 'Member'}
        accepter={InputPicker}
        data={roleOptions}
        disabledItemValues={['Owner']}
        cleanable={false}
      />
      <FlexboxGrid justify='end'>
        <FlexboxGrid.Item colspan={4}>
          <Button
            appearance='primary'
            block
            disabled={
              !userFormValue.displayName || userFormError.displayName || !userFormValue.role || userFormError.role
            }
            loading={saving}
            onClick={save}
          >
            Save
          </Button>
        </FlexboxGrid.Item>
      </FlexboxGrid>
    </Form>
  );
};

export default UserForm;
