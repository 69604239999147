import React, { useState, useCallback } from 'react';
import { Switch, Route, useRouteMatch, useHistory, Redirect } from 'react-router-dom';
import { get, last, includes } from 'lodash';

import { TeamLayout } from '../layouts';
import {
  OrganizationGoal,
  TeamConfiguration,
  TeamDashboard,
  TeamMembers,
  TeamObjectives,
  TeamOutcomes,
} from '../sections';

const validSections = ['analytics', 'outcomes', 'objectives', 'members', 'configuration'];

const TeamPage = () => {
  const { path, url } = useRouteMatch();
  const history = useHistory();
  const location = get(window, 'location.pathname', '');
  const lastPath = last(location.split('/'));
  const [active, setActive] = useState(includes(validSections, lastPath) ? lastPath : 'analytics');

  const goto = useCallback((section) => {
    setActive(section);
    history.push(`${url}/${section}`);
  }, []);

  return (
    <TeamLayout active={active} onSelect={goto}>
      <Switch>
        <Route exact path={path}>
          <Redirect
            to={{
              pathname: `${location}/analytics`,
            }}
          />
        </Route>
        <Route path={`${path}/analytics`}>
          <TeamDashboard />
        </Route>
        <Route path={`${path}/members`}>
          <TeamMembers />
        </Route>
        <Route path={`${path}/configuration`}>
          <TeamConfiguration />
        </Route>
        <Route path={`${path}/objectives`}>
          <TeamObjectives />
        </Route>
        <Route path={`${path}/outcomes`}>
          <TeamOutcomes />
        </Route>
        <Route path={`${path}/objective/:goalId`}>
          <OrganizationGoal />
        </Route>
      </Switch>
    </TeamLayout>
  );
};

export default TeamPage;
