import React, { useMemo, useCallback, useContext } from 'react';
import { Button, Icon, Tooltip, Whisper } from 'rsuite';
import { useHistory } from 'react-router-dom';

import { OrganizationContext } from '../context';

const GoalAvatar = ({ style = {}, whisper = false, placement = 'bottom', data: goal, ...props }) => {
  const organization = useContext(OrganizationContext);
  const history = useHistory();

  const goToGoal = useCallback(() => {
    goal.team
      ? history.push(`/${organization.id}/team/${goal.team}/objective/${goal.id}`)
      : history.push(`/${organization.id}/goal/${goal.id}`);
  }, [organization?.id, goal?.id]);

  const AvatarData = useMemo(
    () => (
      <Button style={style} {...props} onClick={goToGoal}>
        <Icon icon='circle' style={{ color: goal?.tagColor }} />
      </Button>
    ),
    [goToGoal, goal, style, props],
  );

  return whisper ? (
    <Whisper placement={placement} speaker={<Tooltip>{goal?.displayName}</Tooltip>}>
      {AvatarData}
    </Whisper>
  ) : (
    <>{AvatarData}</>
  );
};

export default GoalAvatar;
