import Firebase from 'firebase';

import config from '../config';

Firebase.initializeApp(config.firebase);

if (config.firebase.useEmulator) {
    Firebase.functions().useEmulator('localhost', 5001);
    Firebase.firestore().useEmulator('localhost', 8080);
}

const FirebaseService = Firebase;

export default FirebaseService;
