import React, { useContext, useMemo } from 'react';
import { SelectPicker, Avatar, Tag } from 'rsuite';
import Styled from 'styled-components';
import { get } from 'lodash';

import { OrganizationUsersContext } from '../context';
import { Colors } from '../assets';

const MiniProfileContainer = Styled.div({
  display: 'flex',
  verticalAlign: 'center',
  textAlign: 'center',
  alignItems: 'center',
  alignContent: 'center',
  cursor: 'pointer',
  flex: 1,
});

const UserSelectPicker = ({ style, ...props }) => {
  const { list: users, map: usersMap } = useContext(OrganizationUsersContext);

  const usersOptions = useMemo(() => users.map((u) => ({ label: u.displayName, value: u.id, role: u.role })), [users]);

  return (
    <SelectPicker
      className='user-select-picker'
      placeholder=' '
      style={style || { display: 'block' }}
      {...props}
      data={usersOptions}
      renderMenuItem={(label, item) => {
        return (
          <MiniProfileContainer>
            <Avatar
              square
              size='xs'
              style={{
                backgroundColor: Colors.TAGLINE,
                marginRight: 4,
              }}
              src={get(usersMap, `${item.value}.photoURL`)}
            >
              {!get(usersMap, `${item.value}.photoURL`)
                ? get(usersMap, `${item.value}.displayName`, 'Deleted User').substring(0, 2)
                : null}
            </Avatar>
            {label || 'Deleted User'}
          </MiniProfileContainer>
        );
      }}
      renderMenuGroup={(label) => {
        return <div>{label || 'Deleted User'}</div>;
      }}
      renderValue={(id) => (
        <MiniProfileContainer>
          <Avatar
            square
            size='xs'
            style={{
              backgroundColor: Colors.TAGLINE,
              marginRight: 4,
            }}
            src={get(usersMap, `${id}.photoURL`)}
          >
            {!get(usersMap, `${id}.photoURL`)
              ? get(usersMap, `${id}.displayName`, 'Deleted User').substring(0, 2)
              : null}
          </Avatar>
          {get(usersMap, `${id}.displayName`, 'Deleted User')}
        </MiniProfileContainer>
      )}
    />
  );
};

export default UserSelectPicker;
