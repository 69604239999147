import React, { useState, useCallback, useContext } from 'react';
import { get } from 'lodash';
import Styled from 'styled-components';
import {
  Avatar,
  Form,
  FormGroup,
  ButtonToolbar,
  Button,
  Notification,
  Row,
  Col,
} from 'rsuite';

import { Bugsnag, Firebase } from '../services';
import { LoggedUserContext } from '../context';

const UserAvatar = Styled(Avatar)({
  marginRight: 4,
});

const LogoutButton = Styled(Button)({
  width: '100%',
});

const MiniProfileContainer = Styled(Col)({
  display: 'flex',
  verticalAlign: 'center',
  textAlign: 'center',
  alignItems: 'center',
  alignContent: 'center',
});

const Logout = (props) => {
  const [loading, setLoading] = useState(false);
  const loggedUser = useContext(LoggedUserContext);

  const logout = useCallback(async () => {
    setLoading(true);
    try {
      await Firebase.auth().signOut();
      Notification.success({
        title: 'Success',
        description: 'You logged out',
      });
    } catch (ex) {
      Notification.error({
        title: 'There has been an error',
        description: get(ex, 'message', 'Please try again...'),
      });
      Bugsnag.notify(ex, (event) => {
        event.severity = 'warning';
      });
    }
    setLoading(false);
  }, []);

  return (
    <Form fluid>
      <FormGroup>
        <Row className="show-grid">
          <MiniProfileContainer xs={18}>
            <UserAvatar circle src={loggedUser.photoURL} />
            <strong>{loggedUser.displayName}</strong>
          </MiniProfileContainer>
          <Col xs={6}>
            <ButtonToolbar>
              <LogoutButton
                appearance="primary"
                loading={loading}
                onClick={logout}
              >
                Logout
              </LogoutButton>
            </ButtonToolbar>
          </Col>
        </Row>
      </FormGroup>
    </Form>
  );
};

export default Logout;
