import React, {
  useContext,
  useState,
  useMemo,
  useCallback,
  useEffect,
} from 'react';
import {
  Row,
  Col,
  Panel,
  InputGroup,
  Icon,
  Input,
  Button,
  Modal,
  Loader,
} from 'rsuite';
import Styled from 'styled-components';
import { get, orderBy } from 'lodash';

import { LoggedUserContext, OrganizationContext, OrganizationTeamsContext } from '../context';
import { Colors, Team as TeamIcon } from '../assets';
import { TeamForm, TeamItem } from '../components';
import { Firebase } from '../services';
import { docsToArray } from '../utils';
import { isMobile } from 'react-device-detect';

const Container = Styled.div({
  margin: 30,
});

const Title = Styled.h2({
  marginBottom: 30,
});

const ContainerPanel = Styled(Panel)({
  backgroundColor: Colors.WHITE,
});

const OrganizationTeams = ({ setExpand }) => {
  const loggedUser = useContext(LoggedUserContext);
  const organization = useContext(OrganizationContext);
  const { list: teams } = useContext(OrganizationTeamsContext);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [showTeamFormModal, setShowTeamFormModal] = useState(false);

  const filteredTeams = useMemo(
    () =>
      teams.filter(
        (t) =>
          (t.displayName || '').toLowerCase().search(search.toLowerCase()) >=
            0 &&
          (!t.private ||
            (t.private &&
              (loggedUser.role === 'Owner' ||
                loggedUser.role === 'Admin' ||
                get(t, `leaders.${loggedUser.id}`, false) ||
                get(t, `members.${loggedUser.id}`, false)))),
      ),
    [teams, search, loggedUser],
  );

  const orderedTeams = useMemo(
    () =>
      orderBy(
        filteredTeams,
        [
          (t) => (t.disabled ? 1 : 0),
          (t) =>
            get(t, 'updatedAt') ? get(t, 'updatedAt').toDate() : new Date(),
        ],
        ['asc', 'desc'],
      ),
    [filteredTeams],
  );


  const doSetShowTeamFormModal = useCallback(
    (value) => () => setShowTeamFormModal(value),
    [],
  );

  return (
    <Container>
      <Row gutter={16}>
        <Col xs={16}>
          <Title>Teams</Title>
        </Col>
      </Row>
      <Row gutter={16} style={{ marginBottom: 20 }}>
        <Col xs={24} md={20} style={isMobile ? { marginBottom: 20 } : {}}>
          <ContainerPanel>
            <InputGroup inside>
              <Input
                placeholder='search'
                style={{ border: 'none' }}
                value={search}
                onChange={setSearch}
              />
              <InputGroup.Button>
                <Icon icon='search' />
              </InputGroup.Button>
            </InputGroup>
          </ContainerPanel>
        </Col>
        <Col xs={24} md={4}>
          <Button
            block
            appearance='primary'
            style={{ height: 74, fontSize: 18 }}
            onClick={doSetShowTeamFormModal(true)}
            disabled={loggedUser.role === 'Member'}
          >
            Add Team
          </Button>
        </Col>
      </Row>
      {loading ? (
        <Row gutter={16}>
          <Col xs={24} style={{ marginBottom: 10 }}>
            <ContainerPanel style={{ minHeight: 80 }}>
              <Loader center content='loading' />
            </ContainerPanel>
          </Col>
        </Row>
      ) : orderedTeams.length > 0 ? (
        <Row gutter={16}>
          {orderedTeams.map((team, index) => (
            <Col xs={24} style={{ marginBottom: 10 }}>
              <ContainerPanel
                style={
                  team.disabled
                    ? {
                        borderColor: Colors.COMPLEMENTARY,
                        borderWidth: 1,
                        borderStyle: 'dashed',
                      }
                    : {}
                }
              >
                <TeamItem
                  key={`team-${team.id}`}
                  data={team}
                  setExpand={setExpand}
                />
              </ContainerPanel>
            </Col>
          ))}
        </Row>
      ) : (
        <Row gutter={16}>
          <Col xs={24} style={{ marginBottom: 10 }}>
          <ContainerPanel
                  style={{
                    minHeight: 90,
                    textAlign: 'center',
                    paddingTop: 40,
                    paddingBottom: 40,
                  }}
                >
                  <img src={TeamIcon} style={{ width: '100%', maxWidth: 400 }} />
                  <h5 style={{ marginTop: 20 }}>No teams found</h5>
                  <span>You can start by creating one</span>
                </ContainerPanel>
          </Col>
        </Row>
      )}
      <Modal
        key='add-new-team'
        show={showTeamFormModal}
        onHide={doSetShowTeamFormModal(false)}
        overflow={false}
      >
        <Modal.Header>
          <Modal.Title>Add Team</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ paddingBottom: 0 }}>
          <TeamForm isNew onSave={doSetShowTeamFormModal(false)} />
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default OrganizationTeams;
