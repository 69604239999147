import React, {
  useState,
  useCallback,
  useContext,
  useRef,
  useMemo,
} from 'react';
import { get } from 'lodash';
import moment from 'moment-timezone';
import {
  Form,
  Button,
  Notification,
  Schema,
  FlexboxGrid,
  Row,
  Col,
  InputPicker,
} from 'rsuite';

import { Bugsnag, Firebase, Mixpanel } from '../services';
import { OrganizationContext, LoggedUserContext } from '../context';
import { FormGroup } from '../components';

const { StringType } = Schema.Types;

const organizationProfileFormModel = Schema.Model({
  displayName: StringType()
    .minLength(4, 'The field cannot be less than 4 characters')
    .isRequired('This field is required'),
  timezone: StringType()
    .isOneOf(moment.tz.names(), 'Needs to be valid timezone')
    .isRequired('This field is required'),
});

const OrganizationForm = (props) => {
  const [saving, setSaving] = useState(false);
  const organization = useContext(OrganizationContext);
  const loggedUser = useContext(LoggedUserContext);
  const organizationProfileFormRef = useRef(null);
  const [
    organizationProfileFormValue,
    setOrganizationProfileFormValue,
  ] = useState({
    logoURL: organization.logoURL,
    backgroundURL: organization.backgroundURL,
    displayName: organization.displayName,
    timezone: organization.timezone,
  });
  const [
    organizationProfileFormError,
    setOrganizationProfileFormError,
  ] = useState({});

  const timezones = useMemo(
    () =>
      moment.tz.names().map((t) => ({ value: t, label: t.replace(/_/g, ' ') })),
    [],
  );

  const save = useCallback(async () => {
    setSaving(true);
    try {
      await Firebase.firestore()
        .collection('organizations')
        .doc(organization.id)
        .collection('private')
        .doc(organization.id)
        .update({
          logoURL: organizationProfileFormValue.logoURL || null,
          backgroundURL: organizationProfileFormValue.backgroundURL || null,
          displayName: organizationProfileFormValue.displayName,
          timezone: organizationProfileFormValue.timezone,
          updatedBy: loggedUser.id,
          updatedAt: Firebase.firestore.FieldValue.serverTimestamp(),
        });
        Mixpanel.track('Updated Organization Settings');
    } catch (ex) {
      Notification.error({
        title: 'There has been an error',
        description: get(ex, 'message', 'Please try again...'),
      });
      Bugsnag.notify(ex, (event) => {
        event.severity = 'warning';
      });
    }
    setSaving(false);
  }, [organizationProfileFormValue, organization.id, loggedUser.id]);

  return (
    <Form
      fluid
      key='organizationProfileForm'
      ref={organizationProfileFormRef}
      onChange={setOrganizationProfileFormValue}
      onCheck={setOrganizationProfileFormError}
      formValue={organizationProfileFormValue}
      model={organizationProfileFormModel}
    >
      <Row gutter={16}>
        <Col xs={24} md={12} lg={12}>
          <FormGroup
            image
            imageStyle={{ backgroundSize: 'contain' }}
            label='Logo'
            name='logoURL'
          />
        </Col>
        <Col xs={24} md={12} lg={12}>
          <FormGroup
            image
            label='Background Image'
            name='backgroundURL'
          />
        </Col>
      </Row>
      <FormGroup
        label='Display Name'
        name='displayName'
        error={!!organizationProfileFormError.displayName}
        errorMessage={organizationProfileFormError.displayName}
        disabled={saving}
      />
      <FormGroup
        label='Timezone'
        name='timezone'
        error={!!organizationProfileFormError.timezone}
        errorMessage={organizationProfileFormError.timezone}
        disabled={saving}
        accepter={InputPicker}
        data={timezones}
      />
      <FlexboxGrid justify='end'>
        <FlexboxGrid.Item colspan={4}>
          <Button
            appearance='primary'
            block
            disabled={
              !organizationProfileFormValue.displayName ||
              organizationProfileFormError.displayName ||
              !organizationProfileFormValue.timezone ||
              organizationProfileFormError.timezone ||
              loggedUser.role === 'Member'
            }
            loading={saving}
            onClick={save}
          >
            Save
          </Button>
        </FlexboxGrid.Item>
      </FlexboxGrid>
    </Form>
  );
};

export default OrganizationForm;
