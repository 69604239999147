import React, { useState, useCallback, useRef, useEffect } from 'react';
import { get, debounce } from 'lodash';
import moment from 'moment-timezone';
import Styled from 'styled-components';
import {
  Panel,
  Button,
  Notification,
  Steps,
  FlexboxGrid,
  InputGroup,
  Icon,
  Form,
  Schema,
  Whisper,
  Tooltip,
} from 'rsuite';

import { Colors } from '../assets';
import { Bugsnag, Firebase, Mixpanel } from '../services';
import { Logo, FormGroup } from '../components';

const SignUpPanel = Styled(Panel)({
  backgroundColor: Colors.WHITE,
});
const Content = Styled.div({});
const Section = Styled.div({
  marginBottom: 20,
});
const ButtonContainer = Styled.div({
  marginBottom: 10,
});
const LoginLink = Styled.p({
  marginTop: 20,
  textAlign: 'center',
});

const WorkspaceIdStatus = {
  NOT_AVAILABLE: 'NOT_AVAILABLE',
  LOADING: 'LOADING',
  AVAILABLE: 'AVAILABLE',
};

const { StringType, NumberType } = Schema.Types;

const organizationFormModel = Schema.Model({
  displayName: StringType()
    .minLength(4, 'The field cannot be less than 4 characters')
    .isRequired('This field is required'),
  workspaceId: StringType()
    .minLength(4, 'The field cannot be less than 4 characters')
    .isRequired('This field is required'),
});

const accountFormModel = Schema.Model({
  displayName: StringType().isRequired('This field is required'),
  email: StringType()
    .isRequired('This field is required')
    .isEmail('Please enter a valid email'),
  password: StringType()
    .isRequired('This field is required')
    .pattern(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      'Password must have at least 1 lowercase letter, 1 uppercase letter, 1 number and 1 symbol (!@#$%^&*) and be at least 8 characters long',
    ),
  repeatPassword: StringType()
    .isRequired('This field is required')
    .addRule(
      (value, data) => value === data.password,
      'The passwords are inconsistent',
    ),
});

const verificationFormModel = Schema.Model({
  code: NumberType().isRequired('This field is required'),
});

const SignUpCard = (props) => {
  const [loading, setLoading] = useState(false);
  const organizationFormRef = useRef(null);
  const [organizationFormValue, setOrganizationFormValue] = useState({});
  const [organizationFormError, setOrganizationFormError] = useState({});
  const accountFormRef = useRef(null);
  const [accountFormValue, setAccountFormValue] = useState({});
  const [accountFormError, setAccountFormError] = useState({});
  const verificationFormRef = useRef(null);
  const [verificationFormValue, setVerificationFormValue] = useState({});
  const [verificationFormError, setVerificationFormError] = useState({});
  const [workspaceIdStatus, setWorkspaceIdStatus] = useState(
    WorkspaceIdStatus.LOADING,
  );
  const [section, setSection] = useState(0);

  useEffect(() => {
    changeToSection(0);
  }, []);

  const changeToSection = useCallback((section) => {
    Mixpanel.track('Visited Signup Section', { section });
    setSection(section);
  }, []);

  const signUp = useCallback(async () => {
    setLoading(true);
    try {
      Firebase.auth().tenantId = (
        await Firebase.functions().httpsCallable('CreateWorkspace')({
          organization: {
            displayName: organizationFormValue.displayName,
            workspaceId: organizationFormValue.workspaceId,
            timezone: moment.tz.guess(),
          },
          account: {
            displayName: accountFormValue.displayName,
            email: accountFormValue.email,
            password: accountFormValue.password,
          },
          verificationCode: verificationFormValue.code,
        })
      ).data.tenantId;
      await Firebase.auth().signInWithEmailAndPassword(
        accountFormValue.email,
        accountFormValue.password,
      );
      Mixpanel.track('Signed Up', { email: accountFormValue.email, workspaceId: organizationFormValue.workspaceId });
      Notification.success({
        title: 'Welcome!',
        description: 'Your account & organization has been created',
      });
    } catch (ex) {
      Notification.error({
        title: 'There has been an error',
        description: 'Please try again...',
      });
      Bugsnag.notify(ex, (event) => {
        event.severity = 'warning';
      });
    }
    setLoading(false);
  }, [accountFormValue, organizationFormValue, verificationFormValue]);

  const checkWorkspaceIdAvailability = useCallback(
    debounce(async (workspaceId) => {
      try {
        if (!workspaceId || workspaceId.length < 4) return;
        setWorkspaceIdStatus(WorkspaceIdStatus.LOADING);
        const doc = await Firebase.firestore()
          .collection('organizations')
          .doc(workspaceId)
          .collection('public')
          .doc(workspaceId)
          .get();
        if (doc && doc.exists) {
          setWorkspaceIdStatus(WorkspaceIdStatus.NOT_AVAILABLE);
        } else {
          setWorkspaceIdStatus(WorkspaceIdStatus.AVAILABLE);
        }
      } catch (ex) {
        setWorkspaceIdStatus(WorkspaceIdStatus.NOT_AVAILABLE);
      }
    }, 500),
    [],
  );

  const sendVerificationCode = useCallback(
    debounce(async (email, displayName) => {
      try {
        await Firebase.functions().httpsCallable('EmailVerification')({
          email,
          displayName,
        });
      } catch (ex) {
        Notification.error({
          title: 'There has been an error',
          description: get(ex, 'message', 'Please try again...'),
        });
        Bugsnag.notify(ex, (event) => {
          event.severity = 'warning';
        });
      }
    }, 1000),
    [],
  );

  const setWorkspace = useCallback(
    (value, extra = {}) => {
      const newVal = value
        .replace(/[^a-zA-Z0-9 -]/g, '')
        .replace(/ /g, '-')
        .toLowerCase();
      setOrganizationFormValue({
        ...organizationFormValue,
        ...extra,
        workspaceId: newVal,
      });
      setTimeout(
        () =>
          organizationFormRef &&
          organizationFormRef.current &&
          organizationFormRef.current.check(),
        20,
      );
      checkWorkspaceIdAvailability(newVal);
    },
    [organizationFormValue, checkWorkspaceIdAvailability],
  );

  const setOrganizationAndWorkspace = useCallback(
    (value) => setWorkspace(value, { displayName: value }),
    [setWorkspace],
  );

  const onChangePassword = useCallback(
    (value) => {
      setAccountFormValue({
        ...accountFormValue,
        password: value,
      });
      setTimeout(
        () =>
          accountFormRef &&
          accountFormRef.current &&
          accountFormRef.current.check(),
        20,
      );
    },
    [accountFormValue],
  );

  const getWorkspaceIdColor = useCallback((workspaceIdStatus) => {
    switch (workspaceIdStatus) {
      case WorkspaceIdStatus.NOT_AVAILABLE:
        return Colors.RED;
      case WorkspaceIdStatus.LOADING:
        return Colors.GRAY;
      case WorkspaceIdStatus.AVAILABLE:
        return Colors.GREEN;
      default:
        return;
    }
  }, []);

  const getWorkspaceIdIcon = useCallback((workspaceIdStatus) => {
    switch (workspaceIdStatus) {
      case WorkspaceIdStatus.NOT_AVAILABLE:
        return 'ban';
      case WorkspaceIdStatus.LOADING:
        return 'spinner';
      case WorkspaceIdStatus.AVAILABLE:
        return 'ok-circle';
      default:
        return;
    }
  }, []);

  const getWorkspaceIdText = useCallback((workspaceIdStatus) => {
    switch (workspaceIdStatus) {
      case WorkspaceIdStatus.NOT_AVAILABLE:
        return 'Not available';
      case WorkspaceIdStatus.LOADING:
        return 'Loading...';
      case WorkspaceIdStatus.AVAILABLE:
        return 'Available';
      default:
        return;
    }
  }, []);

  const renderOrganizationSection = useCallback(
    () => (
      <Form
        fluid
        key='organizationForm'
        ref={organizationFormRef}
        onChange={setOrganizationFormValue}
        onCheck={setOrganizationFormError}
        formValue={organizationFormValue}
        model={organizationFormModel}
      >
        <FormGroup
          label='Organization Name'
          name='displayName'
          error={!!organizationFormError.displayName}
          errorMessage={organizationFormError.displayName}
          onChange={setOrganizationAndWorkspace}
        />
        <FormGroup
          label='Workspace ID'
          name='workspaceId'
          hint='This is the unique identifier of your organization which is then used to login, once selected it cannot be changed'
          error={
            !!organizationFormError.workspaceId ||
            workspaceIdStatus === WorkspaceIdStatus.NOT_AVAILABLE
          }
          errorMessage={organizationFormError.workspaceId}
          onChange={setWorkspace}
          addon={
            organizationFormValue.workspaceId && organizationFormValue.workspaceId.length >= 4 ? <InputGroup.Addon style={{ right: 0, left: 'auto' }}>
              <Whisper
                placement='top'
                speaker={
                  <Tooltip>{getWorkspaceIdText(workspaceIdStatus)}</Tooltip>
                }
              >
                <Icon
                  style={{ color: getWorkspaceIdColor(workspaceIdStatus) }}
                  icon={getWorkspaceIdIcon(workspaceIdStatus)}
                />
              </Whisper>
            </InputGroup.Addon> : undefined
          }
        />
        <ButtonContainer>
          <FlexboxGrid justify='end'>
            <FlexboxGrid.Item colspan={24}>
              <Button
                appearance='primary'
                block
                disabled={
                  !organizationFormValue.displayName ||
                  !organizationFormValue.workspaceId ||
                  organizationFormError.displayName ||
                  organizationFormError.workspaceId ||
                  workspaceIdStatus !== WorkspaceIdStatus.AVAILABLE
                }
                onClick={() => changeToSection(1)}
              >
                Continue
              </Button>
            </FlexboxGrid.Item>
          </FlexboxGrid>
        </ButtonContainer>
      </Form>
    ),
    [
      organizationFormRef,
      organizationFormValue,
      organizationFormError,
      setOrganizationAndWorkspace,
      setWorkspace,
      workspaceIdStatus,
      getWorkspaceIdIcon,
      getWorkspaceIdColor,
      getWorkspaceIdText,
    ],
  );

  const renderAccountSection = useCallback(
    () => (
      <Form
        fluid
        key='accountForm'
        ref={accountFormRef}
        onChange={setAccountFormValue}
        onCheck={setAccountFormError}
        formValue={accountFormValue}
        model={accountFormModel}
      >
        <FormGroup
          label='Full Name'
          name='displayName'
          error={!!accountFormError.displayName}
          errorMessage={accountFormError.displayName}
        />
        <FormGroup
          label='Email'
          name='email'
          error={!!accountFormError.email}
          errorMessage={accountFormError.email}
        />
        <FormGroup
          label='Password'
          name='password'
          error={!!accountFormError.password}
          errorMessage={accountFormError.password}
          onChange={onChangePassword}
          type='password'
        />
        <FormGroup
          label='Repeat Password'
          name='repeatPassword'
          error={!!accountFormError.repeatPassword}
          errorMessage={accountFormError.repeatPassword}
          type='password'
        />
        <ButtonContainer>
          <FlexboxGrid justify='space-between'>
            <FlexboxGrid.Item colspan={11}>
              <Button appearance='default' block onClick={() => changeToSection(0)}>
                Back
              </Button>
            </FlexboxGrid.Item>
            <FlexboxGrid.Item colspan={11}>
              <Button
                appearance='primary'
                block
                disabled={
                  !accountFormValue.displayName ||
                  !accountFormValue.email ||
                  !accountFormValue.password ||
                  !accountFormValue.repeatPassword ||
                  accountFormError.displayName ||
                  accountFormError.email ||
                  accountFormError.password ||
                  accountFormError.repeatPassword
                }
                onClick={() => {
                  sendVerificationCode(accountFormValue.email, accountFormValue.displayName);
                  changeToSection(2);
                }}
              >
                Next
              </Button>
            </FlexboxGrid.Item>
          </FlexboxGrid>
        </ButtonContainer>
      </Form>
    ),
    [
      accountFormValue,
      accountFormError,
      sendVerificationCode,
      onChangePassword,
    ],
  );

  const renderVerificationSection = useCallback(
    () => (
      <Form
        fluid
        key='verificationForm'
        ref={verificationFormRef}
        onChange={setVerificationFormValue}
        onCheck={setVerificationFormError}
        formValue={verificationFormValue}
        model={verificationFormModel}
      >
        <FormGroup
          label='Code'
          name='code'
          error={!!verificationFormError.code}
          errorMessage={verificationFormError.code}
          type='number'
          disabled={loading}
        />
        <Section>
          <span>
            You should have the verification code available on your email by
            now, if not{' '}
            <a
              href='#'
              onClick={() => sendVerificationCode(accountFormValue.email)}
            >
              send again
            </a>{' (Remember to check your spam folder as well)'}
          </span>
        </Section>
        <ButtonContainer>
          <FlexboxGrid justify='space-between'>
            <FlexboxGrid.Item colspan={11}>
              <Button
                appearance='default'
                block
                disabled={loading}
                onClick={() => changeToSection(1)}
              >
                Back
              </Button>
            </FlexboxGrid.Item>
            <FlexboxGrid.Item colspan={11}>
              <Button
                appearance='primary'
                block
                disabled={
                  !verificationFormValue.code || verificationFormError.code || loading
                }
                loading={loading}
                onClick={signUp}
              >
                Complete
              </Button>
            </FlexboxGrid.Item>
          </FlexboxGrid>
        </ButtonContainer>
      </Form>
    ),
    [
      accountFormValue,
      verificationFormValue,
      verificationFormError,
      loading,
      signUp,
      sendVerificationCode,
    ],
  );

  const renderCurrentSection = useCallback(
    (section) => {
      switch (section) {
        case 0:
          return renderOrganizationSection();
        case 1:
          return renderAccountSection();
        case 2:
          return renderVerificationSection();
        default:
          return;
      }
    },
    [
      renderOrganizationSection,
      renderAccountSection,
      renderVerificationSection,
    ],
  );

  return (
    <SignUpPanel shaded>
      <Content>
        <Logo marginBottom={30} />
        <Section>
          <Steps current={section}>
            <Steps.Item title='Organization' />
            <Steps.Item title='Account' />
            <Steps.Item title='Verification' />
          </Steps>
        </Section>
        {renderCurrentSection(section)}
        <LoginLink>
          Already have an account? <a href={props.linkToLogin}>Login</a>
        </LoginLink>
      </Content>
    </SignUpPanel>
  );
};

export default SignUpCard;
