import React, { useContext } from 'react';
import { Row, Col, Panel } from 'rsuite';
import Styled from 'styled-components';
import moment from 'moment-timezone';

import { TeamContext } from '../context';
import { TeamForm } from '../components';
import { Colors } from '../assets';
import { get } from 'lodash';

const Container = Styled.div({
  margin: 30,
});

const Title = Styled.h2({
  marginBottom: 30,
});

const ContainerPanel = Styled(Panel)({
  backgroundColor: Colors.WHITE,
});

const TeamConfiguration = () => {
  const team = useContext(TeamContext);

  return (
    <Container>
      <Row gutter={16}>
        <Col xs={16}>
          <Title>Team Configuration</Title>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={12} style={{ marginBottom: 20 }}>
          <ContainerPanel>
            <h6>Team ID</h6>
            <h4>{team.id}</h4>
          </ContainerPanel>
        </Col>
        <Col xs={12} style={{ marginBottom: 20 }}>
          <ContainerPanel>
            <h6>Created on</h6>
            <h4>
              {moment(get(team, 'createdAt', moment()).toDate()).format(
                'LL',
              )}
            </h4>
          </ContainerPanel>
        </Col>
        {!!team.id && (
          <Col xs={24} style={{ marginBottom: 20 }}>
            <ContainerPanel>
              <h6>General</h6>
              <TeamForm data={team} />
            </ContainerPanel>
          </Col>
        )}
      </Row>
    </Container>
  );
};

export default TeamConfiguration;
