import React, { useState, useContext, useCallback } from 'react';
import { get } from 'lodash';
import Styled from 'styled-components';
import {
  Sidebar,
  Sidenav,
  Icon,
  Nav,
  Navbar,
  IconButton,
  Container,
  Avatar,
  Drawer,
  Whisper,
  Tooltip,
  Notification,
} from 'rsuite';

import { OrganizationContext, LoggedUserContext } from '../context';
import { Colors } from '../assets';
import { Logo, UserForm, ChangePassword } from '../components';
import { Bugsnag, Firebase } from '../services';

const SiteSideBar = Styled(Sidebar)({
  backgroundColor: Colors.PRIMARY,
  display: 'flex',
  flexDirection: 'column',
});

const SiteSideNav = Styled(Sidenav)({
  backgroundColor: Colors.PRIMARY,
});

const SiteBottomBar = Styled(Navbar)({
  backgroundColor: 'transparent !important',
  bottom: 0,
  position: 'absolute',
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  color: Colors.WHITE,
  verticalAlign: 'center',
  textAlign: 'center',
  alignItems: 'stretch',
  alignContent: 'center',
});

const SiteContainer = Styled(Container)({
  minHeight: '100vh',
});

const PageContainer = Styled(Container)({
  backgroundColor: Colors.CLOUD,
  overflow: 'auto',
  maxHeight: '100vh',
});

const MiniProfileContainer = Styled.div({
  display: 'flex',
  verticalAlign: 'center',
  textAlign: 'center',
  alignItems: 'center',
  alignContent: 'center',
  cursor: 'pointer',
  flex: 1,
  color: Colors.WHITE,
});

const DisplayName = Styled.strong({
  marginRight: 4,
});

const BodyContainer = Styled.div({
  width: '98%',
});

const DrawerContainer = Styled.div({
  width: '100%',
  paddingTop: 20,
});

const LogoImage = Styled.div(({ url }) => ({
  backgroundImage: `url('${url}')`,
  backgroundPosition: 'center',
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  backgroundColor: Colors.TAGLINE,
  width: '100%',
  height: 200,
  marginRight: 'auto',
  marginLeft: 'auto',
  borderRadius: 8,
  cursor: 'pointer',
}));

const OrganizationLayout = ({
  expand,
  setExpand,
  active,
  onSelect,
  children,
  reverse,
}) => {
  const [showDrawer, setShowDrawer] = useState(false);
  const [currentNavKey, setCurrentNavKey] = useState('profile');
  const organization = useContext(OrganizationContext);
  const loggedUser = useContext(LoggedUserContext);

  const logout = useCallback(async () => {
    try {
      if (window.confirm('Are you sure you want to logout?')) {
        await Firebase.auth().signOut();
        Notification.success({
          title: 'Success',
          description: 'You logged out',
        });
      }
    } catch (ex) {
      Notification.error({
        title: 'There has been an error',
        description: get(ex, 'message', 'Please try again...'),
      });
      Bugsnag.notify(ex, (event) => {
        event.severity = 'warning';
      });
    }
  }, []);

  return (
    <SiteContainer>
      <SiteSideBar width={expand ? 240 : 56} collapsible>
        <Sidenav.Header
          style={{
            minHeight: 100,
            paddingTop: expand ? 12 : 30,
            paddingBottom: expand ? 12 : 30,
          }}
        >
          <Logo
            white
            width={expand ? 150 : 50}
            height={expand ? 75 : 40}
            small={!expand}
          />
        </Sidenav.Header>
        <SiteSideNav
          expanded={expand}
          activeKey={active}
          appearance='inverse'
          onSelect={onSelect}
          className={reverse ? 'rs-reverse' : ''}
        >
          <Sidenav.Body>
            <Nav>
              <Nav.Item eventKey='dashboard' icon={<Icon icon='area-chart' />}>
                Dashboard
              </Nav.Item>
              <Nav.Item eventKey='goals' icon={<Icon icon='target' />}>
                Goals
              </Nav.Item>
              <Nav.Item eventKey='teams' icon={<Icon icon='group' />}>
                Teams
              </Nav.Item>
              <Nav.Item eventKey='users' icon={<Icon icon='user' />}>
                Users
              </Nav.Item>
              <Nav.Item
                eventKey='subscription'
                icon={<Icon icon='credit-card' />}
              >
                Subscription
              </Nav.Item>
              <Nav.Item eventKey='integrations' icon={<Icon icon='cubes' />}>
                Integrations
              </Nav.Item>
              <Nav.Item eventKey='settings' icon={<Icon icon='cog' />}>
                Settings
              </Nav.Item>
              <Nav.Item eventKey='guide' icon={<Icon icon='book2' />}>
                Guide
              </Nav.Item>
            </Nav>
          </Sidenav.Body>
        </SiteSideNav>
        <SiteBottomBar
          style={{
            width: expand ? 240 : 56,
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: expand ? 'row' : 'column',
              marginBottom: 20,
              alignItems: 'center',
              paddingLeft: expand ? 15 : 5,
              paddingRight: expand ? 15 : 5,
            }}
          >
            <div
              style={{ flex: 1, marginBottom: expand ? 0 : 20 }}
              onClick={() => setShowDrawer(true)}
            >
              {expand ? (
                <MiniProfileContainer>
                  <Avatar
                    circle
                    size={expand ? 'sm' : 'xs'}
                    style={{
                      marginRight: expand ? 12 : 0,
                      backgroundColor: Colors.TAGLINE,
                    }}
                    src={loggedUser.photoURL}
                  >
                    {!loggedUser.photoURL
                      ? (loggedUser.displayName || '').substring(0, 2)
                      : null}
                  </Avatar>
                  {expand && (
                    <DisplayName>{loggedUser.minDisplayName}</DisplayName>
                  )}
                </MiniProfileContainer>
              ) : (
                <Whisper
                  placement='right'
                  speaker={<Tooltip>{loggedUser.displayName}</Tooltip>}
                >
                  <MiniProfileContainer>
                    <Avatar
                      circle
                      size='sm'
                      style={{
                        marginRight: expand ? 12 : 0,
                        backgroundColor: Colors.TAGLINE,
                      }}
                      src={loggedUser.photoURL}
                    >
                      {!loggedUser.photoURL
                        ? (loggedUser.displayName || '').substring(0, 2)
                        : null}
                    </Avatar>
                    {expand && (
                      <DisplayName>{loggedUser.minDisplayName}</DisplayName>
                    )}
                  </MiniProfileContainer>
                </Whisper>
              )}
            </div>
            <div
              style={{
                ...(!expand ? { flex: 1 } : {}),
              }}
            >
              <Whisper
                placement={expand ? 'top' : 'right'}
                speaker={<Tooltip>Logout</Tooltip>}
              >
                <IconButton
                  size='sm'
                  color='cyan'
                  icon={
                    <Icon
                      style={{
                        color: Colors.WHITE,
                      }}
                      icon='sign-out'
                    />
                  }
                  onClick={logout}
                />
              </Whisper>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: expand ? 'row' : 'column',
              alignItems: 'center',
              marginBottom: 20,
              paddingLeft: expand ? 15 : 5,
              paddingRight: expand ? 15 : 5,
            }}
          >
            <div
              style={{ flex: 1, marginBottom: expand ? 0 : 20 }}
              onClick={() => onSelect('settings')}
            >
              {expand ? (
                <MiniProfileContainer>
                  <Avatar
                    square
                    size={expand ? 'sm' : 'xs'}
                    style={{
                      marginRight: expand ? 12 : 0,
                      backgroundColor: Colors.TAGLINE,
                    }}
                  >
                    {!organization.logoURL ? (
                      (organization.displayName || '').substring(0, 2)
                    ) : (
                      <LogoImage url={organization.logoURL} />
                    )}
                  </Avatar>
                  {expand && (
                    <DisplayName>{organization.displayName}</DisplayName>
                  )}
                </MiniProfileContainer>
              ) : (
                <Whisper
                  placement='right'
                  speaker={<Tooltip>{organization.displayName}</Tooltip>}
                >
                  <MiniProfileContainer>
                    <Avatar
                      square
                      size='sm'
                      style={{
                        marginRight: expand ? 12 : 0,
                        backgroundColor: Colors.TAGLINE,
                      }}
                    >
                      {!organization.logoURL ? (
                        (organization.displayName || '').substring(0, 2)
                      ) : (
                        <LogoImage url={organization.logoURL} />
                      )}
                    </Avatar>
                    {expand && (
                      <DisplayName>{organization.displayName}</DisplayName>
                    )}
                  </MiniProfileContainer>
                </Whisper>
              )}
            </div>
            <div style={{ ...(!expand ? { flex: 1 } : {}) }}>
              <Whisper
                placement={expand ? 'top' : 'right'}
                speaker={<Tooltip>Toggle Expand</Tooltip>}
              >
                <IconButton
                  size='sm'
                  color='cyan'
                  icon={
                    <Icon
                      style={{
                        color: Colors.WHITE,
                      }}
                      icon={expand ? 'arrow-left-line' : 'arrow-right-line'}
                    />
                  }
                  onClick={() => setExpand(!expand)}
                />
              </Whisper>
            </div>
          </div>
        </SiteBottomBar>
      </SiteSideBar>
      <PageContainer>{children}</PageContainer>
      <Drawer show={showDrawer} onHide={() => setShowDrawer(false)}>
        <Drawer.Header>
          <Drawer.Title>User Configuration</Drawer.Title>
        </Drawer.Header>
        <Drawer.Body>
          <BodyContainer>
            <Nav
              activeKey={currentNavKey}
              onSelect={setCurrentNavKey}
              appearance='subtle'
            >
              <Nav.Item eventKey='profile'>Profile</Nav.Item>
              {!organization?.demo && <Nav.Item eventKey='password'>Change Password</Nav.Item>}
            </Nav>
            <DrawerContainer>
              {currentNavKey === 'profile' && <UserForm data={loggedUser} />}
              {currentNavKey === 'password' && <ChangePassword />}
            </DrawerContainer>
          </BodyContainer>
        </Drawer.Body>
      </Drawer>
    </SiteContainer>
  );
};

export default OrganizationLayout;
