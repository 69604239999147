import React, { useState, useCallback, useContext, useEffect } from 'react';
import { debounce, get } from 'lodash';
import Styled from 'styled-components';
import {
  Row,
  Col,
  Dropdown,
  Icon,
  Popover,
  Whisper,
  IconButton,
  Notification,
  DatePicker,
  Progress,
  Panel,
} from 'rsuite';
import { useHistory } from 'react-router-dom';
import moment from 'moment-timezone';

import { Bugsnag, Firebase, Mixpanel } from '../services';
import { OrganizationContext, LoggedUserContext } from '../context';
import { Colors } from '../assets';
import GoalTag from './GoalTag';
import AutogrowInput from './AutogrowInput';
import UserSelectPicker from './UserSelectPicker';
import TeamSelectPicker from './TeamSelectPicker';
import GoalStatusSelectPicker from './GoalStatusSelectPicker';

const ContainerPanel = Styled(Panel)({
  backgroundColor: Colors.WHITE,
});

const DetailContainer = Styled.div({
  marginBottom: 22,
});

const GoalDescription = ({ data, ...props }) => {
  const history = useHistory();
  const organization = useContext(OrganizationContext);
  const loggedUser = useContext(LoggedUserContext);
  const [goal, setGoal] = useState(data);

  useEffect(() => setGoal(data), [data]);

  const update = useCallback(
    async (nGoal) => {
      try {
        await Firebase.firestore()
          .collection('organizations')
          .doc(organization.id)
          .collection('private')
          .doc(organization.id)
          .collection('goals')
          .doc(nGoal.id)
          .update({
            ...nGoal,
            updatedBy: loggedUser.id,
            updatedAt: Firebase.firestore.FieldValue.serverTimestamp(),
          });
          Mixpanel.track('Updated Goal');
      } catch (ex) {
        Notification.error({
          title: 'There has been an error',
          description: get(ex, 'message', 'Please try again...'),
        });
        Bugsnag.notify(ex, (event) => {
          event.severity = 'warning';
        });
      }
    },
    [organization.id, loggedUser.id],
  );

  const updateWithDebounce = useCallback(debounce(update, 500), [update]);

  const remove = useCallback(async () => {
    if (window.confirm('Are you sure you want to delete this goal?')) {
      return update({ deletedAt: Firebase.firestore.FieldValue.serverTimestamp(), deletedBy: loggedUser.id });
    }
  }, [update, loggedUser]);

  const archive = useCallback(async () => {
    if (window.confirm('Are you sure you want to archive this goal?')) {
      return update({ archivedAt: Firebase.firestore.FieldValue.serverTimestamp(), archivedBy: loggedUser.id });
    }
  }, [update, loggedUser]);

  const unarchive = useCallback(async () => {
    if (window.confirm('Are you sure you want to unarchive this goal?')) {
      return update({ archivedAt: null, archivedBy: null });
    }
  }, [update]);

  return (
    <Row gutter={16} style={{ marginBottom: 20 }}>
      <Col xs={24}>
        <h6 style={{ marginBottom: 10 }}> Details</h6>
        <ContainerPanel>
          <DetailContainer>
            <b>Team</b>
            <TeamSelectPicker
              style={{ display: 'block', marginTop: 10 }}
              value={get(goal, 'team')}
              onChange={(team) => {
                setGoal({ ...goal, team });
                updateWithDebounce({ ...goal, team });
              }}
              appearance='ghost'
              placeholder='No team assigned'
            />
          </DetailContainer>
          <DetailContainer>
            <b>Responsible</b>
            <UserSelectPicker
              style={{ display: 'block', marginTop: 10 }}
              value={get(goal, 'responsible')}
              onChange={(responsible) => {
                setGoal({ ...goal, responsible });
                updateWithDebounce({ ...goal, responsible });
              }}
              appearance='ghost'
              placeholder='No responsible'
            />
          </DetailContainer>
          <DetailContainer>
            <b>Status</b>
            <GoalStatusSelectPicker
              style={{ display: 'block', marginTop: 10 }}
              cleanable={false}
              appearance='ghost'
              value={get(goal, 'status')}
              onChange={(status) => {
                setGoal({ ...goal, status });
                updateWithDebounce({ ...goal, status });
              }}
            />
          </DetailContainer>
          <DetailContainer>
            <b>Due Date</b>
            <DatePicker
              style={{ display: 'block', marginTop: 10 }}
              format='DD MMM, YYYY'
              placeholder='No due date'
              placement='bottomStart'
              appearance='ghost'
              value={get(goal, 'dueDate') ? get(goal, 'dueDate').toDate() : null}
              onChange={(dueDate) => update({ ...goal, dueDate })}
              oneTap
            />
          </DetailContainer>
          <DetailContainer style={{ marginBottom: 0 }}>
            <b>Tag Color</b>
            <GoalTag
              tagColor={get(goal, 'tagColor')}
              style={{
                alignSelf: 'center',
                width: '100%',
                height: 36,
                marginTop: 10,
              }}
              onChange={(tagColor) => update({ ...goal, tagColor })}
              placement='bottomStart'
            />
          </DetailContainer>
        </ContainerPanel>
      </Col>
    </Row>
  );
};

export default GoalDescription;
