import React, { useState, useMemo, useContext, useEffect } from 'react';
import { Row, Col, Panel } from 'rsuite';
import { LineChart, BarChart, PieChart, Bars, Line } from '@rsuite/charts';
import Styled from 'styled-components';
import moment from 'moment-timezone';
import { get, orderBy, first, find, last } from 'lodash';

import { OrganizationUsersContext, TeamContext, TeamGoalsContext } from '../context';
import { DateSelector, UserDisplay, TeamDisplay, GoalDisplay } from '../components';
import { FILTER_BY } from '../components/DateSelector';
import { Firebase, Mixpanel } from '../services';
import { OrganizationContext, LoggedUserContext, OrganizationTeamsContext } from '../context';
import { Colors } from '../assets';
import { docsToArray, groupGoalsWithParents } from '../utils';
import { useGoals } from '../hooks';

const OTHER_DATES_LENGTH = 5;

const CounterContainerPanel = Styled(Panel)({
  backgroundColor: Colors.WHITE,
  marginBottom: 20,
  paddingTop: 6,
  paddingBottom: 5,
});

const Container = Styled.div({
  margin: 30,
});

const Title = Styled.h2({
  marginBottom: 30,
});

const ContainerPanel = Styled(Panel)({
  backgroundColor: Colors.WHITE,
  marginBottom: 20,
});

const TeamDashboard = () => {
  const team = useContext(TeamContext);
  const organization = useContext(OrganizationContext);
  const loggedUser = useContext(LoggedUserContext);
  const goals = useContext(TeamGoalsContext);
  const [analytics, setAnalytics] = useState({});
  const { map: usersMap } = useContext(OrganizationUsersContext);
  const [loading, setLoading] = useState(false);
  const [calendar, setCalendar] = useState({
    date: moment().format(FILTER_BY.day.format),
    format: FILTER_BY.day.format,
    filter: FILTER_BY.day.name,
  });

  const parentGoalIds = useMemo(() => {
    if (!goals) return [];
    return groupGoalsWithParents(goals.list).map((g) => g.id);
  }, [goals]);

  useEffect(() => {
    let subscriptions;
    setLoading(true);
    if (organization?.id && team?.id) {
      subscriptions = [...Array(calendar.filter === FILTER_BY.all.name ? 1 : OTHER_DATES_LENGTH).keys()]
        .map((minus) =>
          calendar.filter === FILTER_BY.all.name
            ? 'ALL'
            : moment(calendar.date, calendar.format)
                .subtract(minus, FILTER_BY[calendar.filter].subtract)
                .format(calendar.format),
        )
        .map((dateDoc) =>
          Firebase.firestore()
            .collection('organizations')
            .doc(organization.id)
            .collection('private')
            .doc(organization.id)
            .collection('analytics')
            .doc(dateDoc)
            .collection('byTeam')
            .doc(team.id)
            .onSnapshot((snap) => {
              setAnalytics((currentAnalytics) => ({ ...currentAnalytics, [dateDoc]: snap.data() }));
              setLoading(false);
            }),
        );
    }
    return () => subscriptions && subscriptions.length && subscriptions.map((s) => s());
  }, [organization?.id, team?.id, calendar]);

  const outcomesCount = useMemo(() => get(analytics[calendar.date], `total`, 0), [calendar, analytics]);
  const membersCount = useMemo(
    () =>
      Object.keys(get(analytics[calendar.date], `byMember`, {}))
        .map((id) => ({ id, ...get(analytics[calendar.date], `byMember.${id}`, {}) }))
        .filter((u) => u.total > 0).length,
    [calendar, analytics],
  );
  const top5Contributors = useMemo(
    () =>
      orderBy(
        Object.keys(get(analytics[calendar.date], `byMember`, {})).map((id) => ({
          id,
          ...get(analytics[calendar.date], `byMember.${id}`, {}),
          completionRate:
            get(analytics[calendar.date], `byMember.${id}.completed`, 0) /
            get(analytics[calendar.date], `byMember.${id}.total`, 0),
        })),
        ['total', 'completionRate'],
        ['desc'],
      )
        .filter((g) => g.total > 0)
        .slice(0, 5) || [],
    [calendar, analytics],
  );
  const topContributor = useMemo(() => {
    const result = first(top5Contributors) || {};

    return result.total > 0 ? result : null;
  }, [top5Contributors]);
  const top5Goals = useMemo(
    () =>
      orderBy(
        Object.keys(get(analytics[calendar.date], `byGoal`, {})).map((id) => ({
          id,
          ...get(analytics[calendar.date], `byGoal.${id}`, {}),
          completionRate:
            get(analytics[calendar.date], `byGoal.${id}.completed`, 0) /
            get(analytics[calendar.date], `byGoal.${id}.total`, 0),
        })),
        ['total', 'completionRate'],
        ['desc', 'desc'],
      )
        .filter((g) => parentGoalIds.includes(g.id))
        .filter((g) => g.total > 0)
        .slice(0, 5) || [],
    [calendar, analytics, parentGoalIds],
  );
  const topGoal = useMemo(() => first(top5Goals) || null, [top5Goals]);
  const outcomesCompletedCount = useMemo(() => get(analytics[calendar.date], `completed`, 0), [calendar, analytics]);
  const outcomesPendingCount = useMemo(() => get(analytics[calendar.date], `pending`, 0), [calendar, analytics]);
  const goalsByOutcomes = useMemo(
    () => top5Goals.map((t) => [get(goals.map, `${t.id}`, {}).displayName || 'Deleted Goal', t.completed, t.pending]),
    [top5Goals, goals],
  );
  const usersByOutcomes = useMemo(
    () => top5Contributors.map((u) => [get(usersMap, `${u.id}`, {}).displayName || 'Deleted User', u.completed, u.pending]),
    [top5Contributors, usersMap],
  );
  const outcomesPerDay = useMemo(
    () =>
      [...Array(OTHER_DATES_LENGTH).keys()]
        .map((minus) => moment(calendar.date, calendar.format).subtract(minus, FILTER_BY[calendar.filter].subtract))
        .map((mappedDate) => [
          mappedDate.format(FILTER_BY[calendar.filter].formatOnDisplay),
          get(analytics[mappedDate.format(calendar.format)], `total`, 0),
          get(analytics[mappedDate.format(calendar.format)], `completed`, 0),
          get(analytics[mappedDate.format(calendar.format)], `pending`, 0),
        ])
        .reverse(),
    [calendar, analytics],
  );

  return (
    <Container>
      <Row gutter={16}>
        <Col xs={24} md={24} lg={16}>
          <Title>Team Dashboard</Title>
        </Col>
        <Col xs={24} md={24} lg={8}>
          <DateSelector
            onChange={(cal) => {
              Mixpanel.track('Filtered Team Dashboard', { by: cal?.filter });
              setCalendar(cal);
            }}
            value={calendar.date}
            placement='bottomEnd'
            justifyContent='flex-end'
            advanced
          />
        </Col>
      </Row>
      <Row gutter={16} style={{ paddingTop: 30 }}>
        <Col xs={24} md={24} lg={24}>
          <Row gutter={16}>
            <Col xs={24} md={12} lg={6}>
              <CounterContainerPanel>
                <span>Created outcomes</span>
                <h1>{outcomesCount}</h1>
              </CounterContainerPanel>
            </Col>
            <Col xs={24} md={12} lg={6}>
              <CounterContainerPanel>
                <span>Active Team Members</span>
                <h1>{`${membersCount}/${
                  Object.keys(team?.leaders || {}).length + Object.keys(team?.members || {}).length
                }`}</h1>
              </CounterContainerPanel>
            </Col>
            <Col xs={24} md={12} lg={6}>
              <CounterContainerPanel>
                <span>Top contributor</span>
                <br />
                {topContributor ? (
                  <UserDisplay
                    id={topContributor.id}
                    totalContribution={topContributor.total}
                    completionRate={topContributor.completionRate}
                  />
                ) : (
                  <div style={{ display: 'flex', alignItems: 'center', marginTop: 20, marginBottom: 20 }}>
                    <b>N/A</b>
                  </div>
                )}
              </CounterContainerPanel>
            </Col>
            <Col xs={24} md={12} lg={6}>
              <CounterContainerPanel>
                <span>Top Goal</span>
                <br />
                {topGoal ? (
                  <GoalDisplay
                    goal={{ ...goals.map[topGoal.id], id: topGoal.id }}
                    teamId={team?.id}
                    totalContribution={topGoal.total}
                    completionRate={topGoal.completionRate}
                  />
                ) : (
                  <div style={{ display: 'flex', alignItems: 'center', marginTop: 20, marginBottom: 20 }}>
                    <b>N/A</b>
                  </div>
                )}
              </CounterContainerPanel>
            </Col>
          </Row>
        </Col>
        <Col xs={24} md={24} lg={24}>
          <Row gutter={16}>
            <Col xs={24} md={8}>
              <ContainerPanel>
                <span>Outcomes status</span>
                <PieChart
                  data={
                    outcomesCompletedCount === 0 && outcomesPendingCount === 0
                      ? []
                      : [
                          ['Completed', outcomesCompletedCount],
                          ['Pending', outcomesPendingCount],
                        ]
                  }
                  color={[Colors.COMPLETED, Colors.PENDING]}
                  loading={loading}
                />
              </ContainerPanel>
            </Col>

            <Col xs={24} md={16}>
              <ContainerPanel>
                <span>Members by outcomes</span>
                <BarChart data={usersByOutcomes} loading={loading}>
                  <Bars name='Completed' color={Colors.COMPLETED} stack='a' />
                  <Bars name='Pending' color={Colors.PENDING} stack='a' />
                </BarChart>
              </ContainerPanel>
            </Col>

            <Col xs={24}>
              <ContainerPanel>
                <span>Goals by outcomes</span>
                <BarChart data={goalsByOutcomes} loading={loading}>
                  <Bars name='Completed' color={Colors.COMPLETED} stack='a' />
                  <Bars name='Pending' color={Colors.PENDING} stack='a' />
                </BarChart>
              </ContainerPanel>
            </Col>

            {calendar.filter !== FILTER_BY.all.name && (
              <Col xs={24} md={24} lg={24}>
                <ContainerPanel>
                  <span>Outcomes history</span>
                  <LineChart data={outcomesPerDay} loading={loading}>
                    <Line name='Total' />
                    <Line name='Completed' color={Colors.COMPLETED} />
                    <Line name='Pending' color={Colors.PENDING} />
                  </LineChart>
                </ContainerPanel>
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default TeamDashboard;
