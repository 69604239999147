import React, { createContext, useState, useEffect, useContext } from 'react';
import { get } from 'lodash';
import { useParams, useHistory } from 'react-router-dom';

import { Firebase, Mixpanel } from '../services';

import { AuthContext } from '../context';
export const Context = createContext();

const OrganizationContext = (props) => {
  const auth = useContext(AuthContext);
  const { workspaceId: workspaceIdFromUrl } = useParams();
  const history = useHistory();
  const [organization, setOrganization] = useState({});

  useEffect(() => {
    let subscription;
    const workspaceId = get(auth.token, 'claims.workspaceId');
    if (workspaceId) {
      if (workspaceId === workspaceIdFromUrl) {
        subscription = Firebase.firestore()
          .collection('organizations')
          .doc(workspaceId)
          .collection('private')
          .doc(workspaceId)
          .onSnapshot((snapshot) =>
            {
              const organization = { ...snapshot.data(), id: snapshot.id };
              setOrganization(organization);
              Mixpanel.set_group('Organization', organization.displayName);
          });
      } else {
        history.push('/login');
      }
    } else {
      setOrganization({});
    }
    return () => subscription && subscription();
  }, [auth.token]);

  return (
    <Context.Provider value={organization}>{props.children}</Context.Provider>
  );
};

export default OrganizationContext;
