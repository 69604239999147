import React, { createContext, useState, useEffect, useContext } from 'react';

import { Firebase } from '../services';
import { docsToArray } from '../utils';

import { OrganizationContext } from '.';
export const Context = createContext();

const OrganizationUsersContext = (props) => {
  const organization = useContext(OrganizationContext);
  const [users, setUsers] = useState({ list: [], map: {} });

  useEffect(() => {
    let subscription;
    if (organization.id) {
      subscription = Firebase.firestore()
        .collection('organizations')
        .doc(organization.id)
        .collection('private')
        .doc(organization.id)
        .collection('users')
        .where('deletedAt', '==', null)
        .onSnapshot((snapshots) => {
          const list = docsToArray(snapshots);
          const map = list.reduce((r, u) => ({ ...r, [u.id]: u }), {});
          setUsers({ list, map });
        });
    } else {
      setUsers({ list: [], map: {} });
    }
    return () => subscription && subscription();
  }, [organization.id]);

  return <Context.Provider value={users}>{props.children}</Context.Provider>;
};

export default OrganizationUsersContext;
