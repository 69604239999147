import React, { createContext, useState, useEffect, useContext } from 'react';
import { get } from 'lodash';

import { TeamContext, OrganizationUsersContext } from '.';
export const Context = createContext();

const TeamMembersContext = (props) => {
  const team = useContext(TeamContext);
  const { map: users } = useContext(OrganizationUsersContext);
  const [members, setMembers] = useState({ list: [], map: {} });

  useEffect(() => {
    let subscription;
    if (team.id) {
      const list = [
        ...Object.keys(get(team, 'leaders', {})).filter(id => !!users[id]).map((id) => ({
          ...get(users, id, {}),
          teamRole: 'Leader',
        })),
        ...Object.keys(get(team, 'members', {})).filter(id => !!users[id]).map((id) => ({
          ...get(users, id, {}),
          teamRole: 'Member',
        })),
      ];
      const map = list.reduce((r, u) => ({ ...r, [u.id]: u }), {});
      setMembers({ list, map });
    } else {
      setMembers({ list: [], map: {} });
    }
    return () => subscription && subscription();
  }, [team, users]);

  return <Context.Provider value={members}>{props.children}</Context.Provider>;
};

export default TeamMembersContext;
