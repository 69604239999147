import React, { useCallback, useContext } from 'react';
import { get, omitBy, includes } from 'lodash';
import Styled from 'styled-components';
import { Avatar, Row, Col, InputPicker, Icon, IconButton, Notification } from 'rsuite';

import { Bugsnag, Firebase, Mixpanel } from '../services';
import { Colors } from '../assets';
import { TeamRoles } from '../types';
import {
  OrganizationContext,
  LoggedUserContext,
  TeamContext,
  TeamMembersContext,
} from '../context';

const MemberInfo = Styled.div({
  marginLeft: 8,
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'start',
  alignSelf: 'center',
  flexDirection: 'column',
  top: 0,
});

const roleOptions = TeamRoles.map((r) => ({
  label: r,
  value: r,
}));

const MemberItem = ({ data: member, ...props }) => {
  const organization = useContext(OrganizationContext);
  const loggedUser = useContext(LoggedUserContext);
  const team = useContext(TeamContext);
  const { map: members } = useContext(TeamMembersContext);

  const remove = useCallback(async () => {
    try {
      if (
        window.confirm(
          'Are you sure you want to remove this member from this team?',
        )
      ) {
        await Firebase.firestore()
          .collection('organizations')
          .doc(organization.id)
          .collection('private')
          .doc(organization.id)
          .collection('teams')
          .doc(team.id)
          .update({
            ...team,
            leaders: {
              ...Object.keys(
                omitBy(
                  members,
                  (m) => m.teamRole !== 'Leader' || m.id === member.id,
                ),
              ).reduce((r, m) => ({ ...r, [m]: true }), {}),
            },
            members: {
              ...Object.keys(
                omitBy(
                  members,
                  (m) => m.teamRole !== 'Member' || m.id === member.id,
                ),
              ).reduce((r, m) => ({ ...r, [m]: true }), {}),
            },
            updatedBy: loggedUser.id,
            updatedAt: Firebase.firestore.FieldValue.serverTimestamp(),
          });

        Mixpanel.track('Removed Team Member');
      }
    } catch (ex) {
      Notification.error({
        title: 'There has been an error',
        description: get(ex, 'message', 'Please try again...'),
      });
      Bugsnag.notify(ex, (event) => {
        event.severity = 'warning';
      });
    }
  }, [team, members]);

  const changeRole = useCallback(
    async (role) => {
      try {
        await Firebase.firestore()
          .collection('organizations')
          .doc(organization.id)
          .collection('private')
          .doc(organization.id)
          .collection('teams')
          .doc(team.id)
          .update({
            ...team,
            leaders: {
              ...Object.keys(
                omitBy(
                  members,
                  (m) => m.teamRole !== 'Leader' || m.id === member.id,
                ),
              ).reduce((r, m) => ({ ...r, [m]: true }), {}),
              ...(role === 'Leader' ? { [member.id]: true } : {}),
            },
            members: {
              ...Object.keys(
                omitBy(
                  members,
                  (m) => m.teamRole !== 'Member' || m.id === member.id,
                ),
              ).reduce((r, m) => ({ ...r, [m]: true }), {}),
              ...(role === 'Member' ? { [member.id]: true } : {}),
            },
            updatedBy: loggedUser.id,
            updatedAt: Firebase.firestore.FieldValue.serverTimestamp(),
          });
      } catch (ex) {
        Notification.error({
          title: 'There has been an error',
          description: get(ex, 'message', 'Please try again...'),
        });
        Bugsnag.notify(ex, (event) => {
          event.severity = 'warning';
        });
      }
    },
    [team, members],
  );

  return (
    <Row gutter={16} {...props}>
      <Col xs={16} style={{ display: 'flex' }}>
        <Avatar
          square
          size='md'
          style={{
            backgroundColor: Colors.TAGLINE,
          }}
          src={member.photoURL}
          circle
        >
          {!member.photoURL ? (member.displayName || '').substring(0, 2) : null}
        </Avatar>
        <MemberInfo>
          <span>{member.displayName}</span>
          <a href={`mailto:${member.email}`}>{member.email}</a>
        </MemberInfo>
      </Col>
      <Col
        xs={8}
        style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'row-reverse',
        }}
      >
        <div>
          <IconButton
            color='red'
            icon={<Icon icon='trash' />}
            onClick={remove}
            disabled={
              loggedUser.role === 'Member' &&
              includes(
                ['', 'Member'],
                get(members, `${loggedUser.id}.teamRole`, ''),
              )
            }
          />
        </div>
        <div style={{ maxWidth: 150, marginRight: 8 }}>
          <InputPicker
            block
            data={roleOptions}
            value={member.teamRole}
            onChange={changeRole}
            cleanable={false}
            disabled={
              loggedUser.role === 'Member' &&
              includes(
                ['', 'Member'],
                get(members, `${loggedUser.id}.teamRole`, ''),
              )
            }
          />
        </div>
      </Col>
    </Row>
  );
};

export default MemberItem;
