import React, { useState, useCallback } from 'react';
import { Switch, Route, useRouteMatch, useHistory, Redirect } from 'react-router-dom';
import { last, get, includes } from 'lodash';

import { TeamContextAware, TeamGoalsContextAware, TeamMembersContextAware } from '../context';
import { TeamPage } from '../pages';
import { OrganizationLayout } from '../layouts';
import {
  OrganizationSettings,
  OrganizationSubscription,
  OrganizationUsers,
  OrganizationTeams,
  OrganizationGoals,
  OrganizationGoal,
  OrganizationDashboard,
  OrganizationIntegrations,
  Guide,
} from '../sections';
import { isMobile } from 'react-device-detect';

const validSections = ['dashboard', 'goals', 'settings', 'subscription', 'users', 'teams', 'integrations', 'guide'];

const OrganizationPage = () => {
  const { path, url } = useRouteMatch();
  const history = useHistory();
  const location = get(window, 'location.pathname', '');
  const lastPath = last(location.split('/'));
  const [active, setActive] = useState(
    includes(location, '/team/')
      ? 'teams'
      : includes(location, '/goal/')
      ? 'goals'
      : includes(validSections, lastPath)
      ? lastPath
      : 'dashboard',
  );
  const [expand, setExpand] = useState(!includes(location, '/team/') && !isMobile);

  const goto = useCallback((section) => {
    setActive(section);
    history.push(`${url}/${section}`);
  }, []);

  return (
    <OrganizationLayout
      active={active}
      onSelect={goto}
      reverse={includes(location, '/team/')}
      expand={expand}
      setExpand={setExpand}
    >
      <Switch>
        <Route exact path={path}>
          <Redirect
            to={{
              pathname: `${location}/dashboard`,
            }}
          />
        </Route>
        <Route path={`${path}/dashboard`}>
          <OrganizationDashboard />
        </Route>
        <Route path={`${path}/goals`}>
          <OrganizationGoals />
        </Route>
        <Route path={`${path}/goal/:goalId`}>
          <OrganizationGoal />
        </Route>
        <Route path={`${path}/settings`}>
          <OrganizationSettings />
        </Route>
        <Route path={`${path}/subscription`}>
          <OrganizationSubscription />
        </Route>
        <Route path={`${path}/users`}>
          <OrganizationUsers />
        </Route>
        <Route path={`${path}/teams`}>
          <OrganizationTeams setExpand={setExpand} />
        </Route>
        <Route path={`${path}/integrations`}>
          <OrganizationIntegrations />
        </Route>
        <Route path={`${path}/guide`}>
          <Guide />
        </Route>
        <Route path={`${path}/team/:teamId`}>
          <TeamContextAware>
            <TeamMembersContextAware>
              <TeamGoalsContextAware>
                <TeamPage />
              </TeamGoalsContextAware>
            </TeamMembersContextAware>
          </TeamContextAware>
        </Route>
      </Switch>
    </OrganizationLayout>
  );
};

export default OrganizationPage;
