import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { useEffect } from 'react';

const ReadOnlyPlugin = ({ value = false }) => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    editor?.setReadOnly(value);
  }, [editor, value]);

  return null;
};

export default ReadOnlyPlugin;
