import React, { useContext, useMemo } from 'react';
import { SelectPicker, Avatar } from 'rsuite';
import Styled from 'styled-components';
import { get } from 'lodash';

import { OrganizationTeamsContext } from '../context';
import { Colors } from '../assets';
import { GoalStatuses } from '../types';

const MiniProfileContainer = Styled.div({
  display: 'flex',
  verticalAlign: 'center',
  textAlign: 'center',
  alignItems: 'center',
  alignContent: 'center',
  cursor: 'pointer',
  flex: 1,
});

const options = GoalStatuses.map((status) => ({ label: status, value: status }));
const colors = {
  'Ahead of Schedule': Colors.COMPLETED,
  'On Track': Colors.GREEN,
  'Has Issues': Colors.YELLOW,
  'At Risk': Colors.RED,
  Delayed: Colors.BLACK,
};

const GoalStatusSelectPicker = ({ value, cleanable = true, ...props }) => {
  return (
    <SelectPicker
      className='goal-status-select-picker'
      placeholder=' '
      {...props}
      data={options}
      cleanable={cleanable}
      value={value}
      renderMenuItem={(label, item) => {
        return (
          <MiniProfileContainer>
            <Avatar
              square
              size='xs'
              style={{
                backgroundColor: colors[item.value],
                borderColor: colors[item.value],
                marginRight: 6,
              }}
            />
            {label}
          </MiniProfileContainer>
        );
      }}
      renderValue={(id) => (
        <MiniProfileContainer>
          <Avatar
            square
            size='xs'
            style={{
              backgroundColor: colors[value],
              borderColor: colors[value],
              marginRight: 6,
            }}
          />
          {value}
        </MiniProfileContainer>
      )}
    />
  );
};

export default GoalStatusSelectPicker;
