import React, { useState, useCallback, useMemo, useEffect, useContext } from 'react';
import { Icon, Uploader, Notification, Button, Modal } from 'rsuite';
import FileViewer from 'react-file-viewer';

import { find, last, get } from 'lodash';

import { LimitationsContext, LoggedUserContext, OrganizationContext, UsageContext } from '../context';
import { Bugsnag, Firebase } from '../services';
import { Colors } from '../assets';
import { uuidv4 } from '../utils';

const MAX_SIZE = 150; // Megabytes

const FileUploader = ({ data, extraData, onCreate = () => {}, onDelete = () => {}, ...props }) => {
  const organization = useContext(OrganizationContext);
  const loggedUser = useContext(LoggedUserContext);
  const limitations = useContext(LimitationsContext);
  const usage = useContext(UsageContext);
  const [files, setFiles] = useState(data || []);
  const [uploading, setUploading] = useState(false);
  const [preview, setPreview] = useState(null);
  const [showPreview, setShowPreview] = useState(false);
  const [downloading, setDownloading] = useState(false);

  const busy = useMemo(
    () => uploading || files.filter((f) => f.status === 'uploading' || f.status === 'error').length > 0,
    [files, uploading],
  );

  useEffect(() => {
    if (!busy) {
      setFiles(data.map((f) => ({ ...f, fileKey: f.id })));
    }
  }, [data]);

  const upload = useCallback(
    async ({ blobFile: file, fileKey }) => {
      const id = uuidv4();
      const snapshot = await Firebase.storage()
        .ref()
        .child(`organizations/${organization.id}/${id}.${file.name.split('.').pop()}`)
        .put(file);
      const url = await snapshot.ref.getDownloadURL();
      await Firebase.firestore()
        .collection('organizations')
        .doc(organization.id)
        .collection('private')
        .doc(organization.id)
        .collection('files')
        .doc(id)
        .set({
          ...extraData,
          url,
          sizeInBytes: file.size,
          type: file.type,
          name: file.name,
          createBy: loggedUser.id,
          createAt: Firebase.firestore.FieldValue.serverTimestamp(),
          updatedBy: loggedUser.id,
          updatedAt: Firebase.firestore.FieldValue.serverTimestamp(),
          deletedBy: null,
          deletedAt: null,
        });
      return { fileKey, url, id };
    },
    [organization, loggedUser, extraData],
  );

  const onChange = useCallback(
    async (newFiles) => {
      setUploading(true);
      let pending = [];
      let currentFiles = files;
      try {
        let error = false;
        pending = newFiles.filter((f) => f.status === 'inited');
        currentFiles = [
          ...newFiles.filter((f) => f.status !== 'inited'),
          ...pending.map((f) => ({ ...f, status: 'uploading' })),
        ];
        setFiles(currentFiles);
        pending.map((f) => {
          if (!get(f, 'blobFile.size') || get(f, 'blobFile.size') / 1024 / 1024 > MAX_SIZE) {
            error = true;
          }
        });
        if (error) {
          throw new Error(`One or multiple files are too large, ${MAX_SIZE}MB is maximum`);
        }
        const fileSizeInKbs = pending.reduce((r, p) => r + p?.blobFile?.size || 0, 0) / 1024;
        if (usage?.storage?.totalInKbs + fileSizeInKbs > limitations?.storage?.maxInKbs) {
          throw new Error(`Organization Maximum Storage Reached, please contact administrator.`);
        }
        const urls = await Promise.all(pending.map((p) => upload(p)));
        const urlsMap = urls.reduce((r, f) => ({ ...r, [f.fileKey]: f.url }), {});
        const idsMap = urls.reduce((r, f) => ({ ...r, [f.fileKey]: f.id }), {});
        const created = pending.map((f) => ({
          ...f,
          status: 'finished',
          url: urlsMap[f.fileKey],
          id: idsMap[f.fileKey],
          size: get(f, 'blobFile.size'),
          type: get(f, 'blobFile.type'),
          name: get(f, 'blobFile.name'),
        }));
        await onCreate(created);
        setFiles([...currentFiles.filter((f) => f.status !== 'uploading'), ...created]);
      } catch (ex) {
        setFiles([
          ...currentFiles.filter((f) => f.status !== 'uploading'),
          ...pending.map((f) => ({ ...f, status: 'error' })),
        ]);
        Notification.error({
          title: 'There has been an error',
          description: get(ex, 'message', 'Please try again...'),
        });
        Bugsnag.notify(ex, (event) => {
          event.severity = 'warning';
        });
      } finally {
        setUploading(false);
      }
    },
    [files, onCreate, upload, limitations, usage],
  );

  const onReupload = useCallback(async () => {
    setUploading(true);
    let pending = [];
    let currentFiles = files;
    try {
      let error = false;
      pending = files.filter((f) => f.status === 'error');
      currentFiles = [
        ...files.filter((f) => f.status !== 'error'),
        ...pending.map((f) => ({ ...f, status: 'uploading' })),
      ];
      setFiles(currentFiles);
      pending.map((f) => {
        if (!get(f, 'blobFile.size') || get(f, 'blobFile.size') / 1024 / 1024 > MAX_SIZE) {
          error = true;
        }
      });
      if (error) {
        throw new Error(`One or multiple files are too large, ${MAX_SIZE}MB is maximum`);
      }
      const fileSizeInKbs = pending.reduce((r, p) => r + p?.blobFile?.size || 0, 0) / 1024;
      if (usage?.storage?.totalInKbs + fileSizeInKbs > limitations?.storage?.maxInKbs) {
        throw new Error(`Organization Maximum Storage Reached, please contact administrator.`);
      }
      const urls = await Promise.all(pending.map((p) => upload(p)));
      const urlsMap = urls.reduce((r, f) => ({ ...r, [f.fileKey]: f.url }), {});
      const idsMap = urls.reduce((r, f) => ({ ...r, [f.fileKey]: f.id }), {});
      const created = pending.map((f) => ({
        ...f,
        status: 'finished',
        url: urlsMap[f.fileKey],
        id: idsMap[f.fileKey],
        size: get(f, 'blobFile.size'),
        type: get(f, 'blobFile.type'),
        name: get(f, 'blobFile.name'),
      }));
      await onCreate(created);
      setFiles([...currentFiles.filter((f) => f.status !== 'uploading'), ...created]);
    } catch (ex) {
      setFiles([
        ...currentFiles.filter((f) => f.status !== 'uploading'),
        ...pending.map((f) => ({ ...f, status: 'error' })),
      ]);
      Notification.error({
        title: 'There has been an error',
        description: get(ex, 'message', 'Please try again...'),
      });
      Bugsnag.notify(ex, (event) => {
        event.severity = 'warning';
      });
    } finally {
      setUploading(false);
    }
  }, [files, onCreate, upload, limitations, usage]);

  const onRemove = useCallback(
    async (file) => {
      try {
        if (!file.id) {
          // not yet uploaded
          return;
        }
        await Firebase.firestore()
        .collection('organizations')
        .doc(organization.id)
        .collection('private')
        .doc(organization.id)
        .collection('files')
        .doc(file.id)
        .update({
          deletedBy: loggedUser.id,
          deletedAt: Firebase.firestore.FieldValue.serverTimestamp(),
        });
        await onDelete(file);
      } catch (ex) {
        setFiles(files); // rollback
        Notification.error({
          title: 'There has been an error',
          description: get(ex, 'message', 'Please try again...'),
        });
        Bugsnag.notify(ex, (event) => {
          event.severity = 'warning';
        });
      }
    },
    [files, onDelete, loggedUser, organization],
  );

  const download = useCallback(
    async (file) => {
      setDownloading(true);
      try {
        const response = await fetch(file.url, {
          method: 'GET',
        });
        const blob = await response.blob();
        const downloadUrl = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', file.name);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      } catch (ex) {
        Notification.error({
          title: 'There has been an error',
          description: get(ex, 'message', 'Please try again...'),
        });
        Bugsnag.notify(ex, (event) => {
          event.severity = 'warning';
        });
      } finally {
        setDownloading(false);
      }
    },
    [downloading],
  );

  return (
    <>
      <Uploader
        {...props}
        listType='text'
        autoUpload={false}
        onChange={onChange}
        onReupload={onReupload}
        onRemove={onRemove}
        fileList={files}
        disabled={uploading}
        onPreview={(file) => {
          setPreview(file);
          setShowPreview(true);
        }}
      >
        <Button block>
          <Icon icon='file-upload' size='lg' />
        </Button>
      </Uploader>
      <Modal size='md' overflow={false} show={showPreview} onHide={() => setShowPreview(false)}>
        <Modal.Header>
          <Modal.Title>{get(preview, 'name')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FileViewer
            fileType={last(get(preview, 'name', '').split('.'))}
            filePath={get(preview, 'url')}
            errorComponent={() => <b>oops...</b>}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button appearance='subtle' block onClick={() => download(preview)} disabled={downloading}>
            Download
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default FileUploader;
