import React, { useState } from 'react';

import { BrandLayout } from '../layouts';
import { WelcomeCard } from '../components';

const Welcome = () => {
  const [background, setBackground] = useState(null);
  return (
    <BrandLayout background={background}>
      <WelcomeCard  setBackground={setBackground}/>
    </BrandLayout>
  );
};

export default Welcome;
