import React, { useState, useCallback, useContext, useEffect, useMemo } from 'react';
import { orderBy, get } from 'lodash';
import Styled from 'styled-components';
import { Row, Col, Notification, Panel } from 'rsuite';
import { useHistory } from 'react-router-dom';

import { Firebase, Mixpanel } from '../services';
import { OrganizationContext, LoggedUserContext } from '../context';
import { Colors, Files } from '../assets';
import FileUploader from './FileUploader';
import { docsToArray } from '../utils';

const ContainerPanel = Styled(Panel)({
  backgroundColor: Colors.WHITE,
});

const GoalFiles = ({ data, ...props }) => {
  const organization = useContext(OrganizationContext);
  const loggedUser = useContext(LoggedUserContext);
  const [goal, setGoal] = useState(data);
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(true);

  const orderedFiles = useMemo(
    () => orderBy(files, [(f) => (get(f, 'updatedAt') ? get(f, 'updatedAt').toDate() : new Date())], ['asc']),
    [files],
  );

  useEffect(() => setGoal(data), [data]);

  useEffect(() => {
    let subscription;
    if (organization.id && get(goal, 'id')) {
      subscription = Firebase.firestore()
        .collection('organizations')
        .doc(organization.id)
        .collection('private')
        .doc(organization.id)
        .collection('files')
        .where('goal', '==', goal?.id)
        .where('deletedAt', '==', null)
        .onSnapshot((docs) => {
          setFiles(docsToArray(docs));
          setLoading(false);
        });
    }
    return () => {
      subscription && subscription();
    };
  }, [organization.id, goal]);

  return (
    <Row gutter={16} style={{ marginBottom: 20 }}>
      <Col xs={24}>
        <h6 style={{ marginBottom: 10 }}>Files</h6>
        <ContainerPanel>
          {orderedFiles.length <= 0 && !loading && (
            <div style={{ textAlign: 'center' }}>
              <img src={Files} style={{ maxWidth: '100%' }} />
            </div>
          )}
          <FileUploader
            multiple
            data={orderedFiles}
            extraData={{ goal: goal?.id }}
            onCreate={() => Mixpanel.track('Uploaded Goal File')}
            onDelete={() => Mixpanel.track('Deleted Goal File')}
          />
        </ContainerPanel>
      </Col>
    </Row>
  );
};

export default GoalFiles;
