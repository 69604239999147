import React, { useState, useCallback, useContext, useRef } from 'react';
import { get } from 'lodash';
import { Form, Schema, Button, Notification, FlexboxGrid } from 'rsuite';

import { Bugsnag, Firebase, Mixpanel } from '../services';
import { LoggedUserContext, WorkspaceContext } from '../context';
import { FormGroup } from '../components';

const { StringType } = Schema.Types;

const passwordFormModel = Schema.Model({
  currentPassword: StringType().isRequired('This field is required'),
  newPassword: StringType()
    .isRequired('This field is required')
    .pattern(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      'Password must have at least 1 lowercase letter, 1 uppercase letter, 1 number and 1 symbol (!@#$%^&*) and be at least 8 characters long',
    ),
  repeatNewPassword: StringType()
    .isRequired('This field is required')
    .addRule((value, data) => value === data.newPassword, 'The new passwords are inconsistent'),
});

const ChangePassword = (props) => {
  const [saving, setSaving] = useState(false);
  const loggedUser = useContext(LoggedUserContext);
  const passwordFormRef = useRef(null);
  const [passwordFormValue, setPasswordFormValue] = useState({});
  const [passwordFormError, setPasswordFormError] = useState({});

  const onChangeNewPassword = useCallback(
    (value) => {
      setPasswordFormValue({
        ...passwordFormValue,
        newPassword: value,
      });
      setTimeout(() => passwordFormRef && passwordFormRef.current && passwordFormRef.current.check(), 20);
    },
    [passwordFormValue],
  );

  const save = useCallback(async () => {
    setSaving(true);
    try {
      Firebase.auth().tenantId = Firebase.auth().currentUser.tenantId;
      await Firebase.auth().signInWithEmailAndPassword(loggedUser.email, passwordFormValue.currentPassword);
      await Firebase.auth().currentUser.updatePassword(passwordFormValue.newPassword);
      setPasswordFormValue({
        currentPassword: '',
        newPassword: '',
        repeatNewPassword: '',
      });
      Mixpanel.track('Changed Password');
      Notification.success({
        title: 'Success',
        description: 'Password updated',
      });
    } catch (ex) {
      Notification.error({
        title: 'There has been an error',
        description: get(ex, 'message', 'Please try again...'),
      });
      Bugsnag.notify(ex, (event) => {
        event.severity = 'warning';
      });
    }
    setSaving(false);
  }, [loggedUser, passwordFormValue]);

  return (
    <Form
      fluid
      key='passwordForm'
      ref={passwordFormRef}
      onChange={setPasswordFormValue}
      onCheck={setPasswordFormError}
      formValue={passwordFormValue}
      model={passwordFormModel}
    >
      <FormGroup
        label='Current Password'
        name='currentPassword'
        error={!!passwordFormError.currentPassword}
        errorMessage={passwordFormError.currentPassword}
        type='password'
      />
      <FormGroup
        label='New Password'
        name='newPassword'
        error={!!passwordFormError.newPassword}
        errorMessage={passwordFormError.newPassword}
        onChange={onChangeNewPassword}
        type='password'
      />
      <FormGroup
        label='Repeat New Password'
        name='repeatNewPassword'
        error={!!passwordFormError.repeatNewPassword}
        errorMessage={passwordFormError.repeatNewPassword}
        type='password'
      />
      <FlexboxGrid justify='end'>
        <FlexboxGrid.Item colspan={4}>
          <Button
            appearance='primary'
            block
            disabled={
              !passwordFormValue.currentPassword ||
              passwordFormError.currentPassword ||
              !passwordFormValue.newPassword ||
              passwordFormError.newPassword ||
              !passwordFormValue.repeatNewPassword ||
              passwordFormError.repeatNewPassword
            }
            loading={saving}
            onClick={save}
          >
            Save
          </Button>
        </FlexboxGrid.Item>
      </FlexboxGrid>
    </Form>
  );
};

export default ChangePassword;
