import React, { useState, useEffect } from 'react';
import Styled from 'styled-components';
import { Whisper, Popover, Button } from 'rsuite';
import ColorPicker from 'rsuite-color-picker';

export const Tag = Styled.div(({ tagColor, style }) => ({
  backgroundColor: tagColor,
  borderRadius: 8,
  width: 10,
  height: '100%',
  cursor: 'pointer',
  ...style,
}));

const GoalTag = ({ tagColor, placement, onChange = () => {}, style = {} }) => {
  const [value, setValue] = useState(tagColor);
  useEffect(() => setValue(tagColor), [tagColor]);

  return (
    <Whisper
      trigger='hover'
      placement={placement || 'right'}
      speaker={
        <Popover>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ width: 50, marginRight: 8 }}>
              <ColorPicker value={value} onChange={({ hex }) => setValue(hex)} disableAlpha />
            </div>
            <div>
              <Button onClick={() => onChange(value)}>Save</Button>
            </div>
          </div>
        </Popover>
      }
      enterable
    >
      <Tag tagColor={tagColor} style={style} />
    </Whisper>
  );
};

export default GoalTag;
