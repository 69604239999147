import React from 'react';

import { BrandLayout } from '../layouts';
import { SignUpCard } from '../components';

const SignUpPage = () => {
  return (
    <BrandLayout>
      <SignUpCard linkToLogin="/login"/>
    </BrandLayout>
  );
};

export default SignUpPage;
