import React, { useState, useCallback, useRef, useEffect } from 'react';
import { get, debounce } from 'lodash';
import Styled from 'styled-components';
import {
  Panel,
  Button,
  Notification,
  FlexboxGrid,
  InputGroup,
  Icon,
  Form,
  Schema,
  Whisper,
  Tooltip,
  Animation,
  Avatar,
  Row,
  Col,
} from 'rsuite';

import { Bugsnag, Firebase, Mixpanel } from '../services';
import { Logo, FormGroup } from '../components';
import { Colors } from '../assets';
import { useLocation } from 'react-router-dom';

const LoginPanel = Styled(Panel)({
  backgroundColor: '#ffffff',
});
const Content = Styled.div({});
const Section = Styled.div({
  marginBottom: 20,
});
const ButtonContainer = Styled.div({
  marginBottom: 10,
});
const SignUpLink = Styled.p({
  textAlign: 'center',
});

const LogoImage = Styled.div(({ url }) => ({
  backgroundImage: `url('${url}')`,
  backgroundPosition: 'center',
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  backgroundColor: Colors.TAGLINE,
  width: '100%',
  height: '100%',
  marginRight: 'auto',
  marginLeft: 'auto',
  borderRadius: 8,
  cursor: 'pointer',
}));

const WorkspaceIdStatus = {
  EXISTS: 'EXISTS',
  LOADING: 'LOADING',
  NOT_FOUND: 'NOT_FOUND',
};

const { StringType } = Schema.Types;
const workspaceFormModel = Schema.Model({
  workspaceId: StringType()
    .minLength(4, 'The field cannot be less than 4 characters')
    .isRequired('This field is required'),
});

const accountFormModel = Schema.Model({
  email: StringType()
    .isRequired('This field is required')
    .isEmail('Please enter a valid email'),
  password: StringType().isRequired('This field is required'),
});

const { Collapse } = Animation;

const LoginCard = ({ linkToSignUp, setBackground }) => {
  const [loading, setLoading] = useState(false);
  const workspaceFormRef = useRef(null);
  const [workspaceFormValue, setWorkspaceFormValue] = useState({});
  const [workspaceFormError, setWorkspaceFormError] = useState({});
  const [workspace, setWorkspace] = useState(null);
  const accountFormRef = useRef(null);
  const [accountFormValue, setAccountFormValue] = useState({});
  const [accountFormError, setAccountFormError] = useState({});
  const [section, setSection] = useState(0);
  const [workspaceIdStatus, setWorkspaceIdStatus] = useState(
    WorkspaceIdStatus.LOADING,
  );
  const query = new URLSearchParams(useLocation().search);
  const workspaceId = query.get('workspaceId');

  useEffect(() => {
    if(workspaceId) {
      setWorkspaceValue(workspaceId);
    }
  }, [workspaceId]);

  const login = useCallback(async () => {
    setLoading(true);
    try {
      Firebase.auth().tenantId = workspace.tenantId;
      await Firebase.auth().signInWithEmailAndPassword(
        accountFormValue.email,
        accountFormValue.password,
      );
      Mixpanel.track('Logged In', { email: accountFormValue.email, workspaceId: workspace.id});
      
    } catch (ex) {
      Notification.error({
        title: 'There has been an error',
        description: get(ex, 'message', 'Please try again...'),
      });
      Bugsnag.notify(ex, (event) => {
        event.severity = 'warning';
      });
    }
    setLoading(false);
  }, [workspace, accountFormValue]);

  const findWorkspace = useCallback(
    debounce(async (workspaceId) => {
      try {
        if (!workspaceId || workspaceId.length < 4)
          throw new Error('invalid workspace id');
        setWorkspaceIdStatus(WorkspaceIdStatus.LOADING);
        const doc = await Firebase.firestore()
          .collection('organizations')
          .doc(workspaceId)
          .collection('public')
          .doc(workspaceId)
          .get();
        if (doc && doc.exists) {
          setWorkspaceIdStatus(WorkspaceIdStatus.EXISTS);
          setWorkspace({ id: doc.id, ...doc.data() });
          setBackground(doc.data().backgroundURL);
        } else {
          setWorkspaceIdStatus(WorkspaceIdStatus.NOT_FOUND);
          setWorkspace(null);
          setBackground(null);
        }
      } catch (ex) {
        setWorkspaceIdStatus(WorkspaceIdStatus.NOT_FOUND);
        setWorkspace(null);
        setBackground(null);
      }
    }, 500),
    [],
  );

  const setWorkspaceValue = useCallback(
    (value) => {
      const newVal = value
        .replace(/[^a-zA-Z0-9 -]/g, '')
        .replace(/ /g, '-')
        .toLowerCase();
      setWorkspaceFormValue({
        workspaceId: newVal,
      });
      setTimeout(
        () =>
          workspaceFormRef &&
          workspaceFormRef.current &&
          workspaceFormRef.current.check(),
        20,
      );
      findWorkspace(newVal);
    },
    [findWorkspace],
  );

  const getWorkspaceIdColor = useCallback((workspaceIdStatus) => {
    switch (workspaceIdStatus) {
      case WorkspaceIdStatus.NOT_FOUND:
        return Colors.RED;
      case WorkspaceIdStatus.LOADING:
        return Colors.GRAY;
      case WorkspaceIdStatus.EXISTS:
        return Colors.GREEN;
      default:
        return;
    }
  }, []);

  const getWorkspaceIdIcon = useCallback((workspaceIdStatus) => {
    switch (workspaceIdStatus) {
      case WorkspaceIdStatus.NOT_FOUND:
        return 'ban';
      case WorkspaceIdStatus.LOADING:
        return 'spinner';
      case WorkspaceIdStatus.EXISTS:
        return 'ok-circle';
      default:
        return;
    }
  }, []);

  const getWorkspaceIdText = useCallback((workspaceIdStatus) => {
    switch (workspaceIdStatus) {
      case WorkspaceIdStatus.NOT_FOUND:
        return 'Workspace Not found';
      case WorkspaceIdStatus.LOADING:
        return 'Loading...';
      case WorkspaceIdStatus.EXISTS:
        return 'Workspace found';
      default:
        return;
    }
  }, []);

  const renderWorkspaceSection = useCallback(
    () => (
      <Form
        fluid={true}
        key='workspaceForm'
        ref={workspaceFormRef}
        onChange={setWorkspaceFormValue}
        onCheck={setWorkspaceFormError}
        formValue={workspaceFormValue}
        model={workspaceFormModel}
      >
        <FormGroup
          label='Workspace ID'
          name='workspaceId'
          hint='This is the unique identifier of your organization'
          error={
            !!workspaceFormError.workspaceId ||
            workspaceIdStatus === WorkspaceIdStatus.NOT_FOUND
          }
          errorMessage={workspaceFormError.workspaceId}
          onChange={setWorkspaceValue}
          addon={
            workspaceFormValue.workspaceId && workspaceFormValue.workspaceId.length >= 4 ? <InputGroup.Addon style={{ right: 0, left: 'auto' }}>
              <Whisper
                placement='top'
                speaker={
                  <Tooltip>{getWorkspaceIdText(workspaceIdStatus)}</Tooltip>
                }
              >
                <Icon
                  style={{ color: getWorkspaceIdColor(workspaceIdStatus) }}
                  icon={getWorkspaceIdIcon(workspaceIdStatus)}
                />
              </Whisper>
            </InputGroup.Addon> : undefined
          }
        />
        <Collapse in={!!workspace}>
          <Section>
            <Row gutter={16}>
              <Col xs={18}>
                <span>Organization</span>
                <h4>{(workspace || {}).displayName}</h4>
              </Col>
              <Col xs={6} style={{ textAlign: 'right' }}>
                <Avatar
                  square
                  size='lg'
                  style={{
                    backgroundColor: Colors.TAGLINE,
                  }}
                >
                  {!(workspace || {}).logoURL ? (
                    ((workspace || {}).displayName || '').substring(0, 2)
                  ) : (
                    <LogoImage url={(workspace || {}).logoURL} />
                  )}
                </Avatar>
              </Col>
            </Row>
          </Section>
        </Collapse>
        <ButtonContainer>
          <FlexboxGrid justify='end'>
            <FlexboxGrid.Item colspan={24}>
              <Button
                appearance='primary'
                block
                disabled={
                  !workspaceFormValue.workspaceId ||
                  workspaceFormError.workspaceId ||
                  workspaceIdStatus !== WorkspaceIdStatus.EXISTS
                }
                onClick={() => setSection(1)}
              >
                Continue
              </Button>
            </FlexboxGrid.Item>
          </FlexboxGrid>
        </ButtonContainer>
      </Form>
    ),
    [
      workspaceFormValue,
      workspaceFormError,
      setWorkspaceValue,
      getWorkspaceIdText,
      getWorkspaceIdColor,
      getWorkspaceIdIcon,
      workspaceIdStatus,
      workspace,
    ],
  );

  const renderAccountSection = useCallback(
    () => (
      <Form
        fluid
        key='accountForm'
        ref={accountFormRef}
        onChange={setAccountFormValue}
        onCheck={setAccountFormError}
        formValue={accountFormValue}
        model={accountFormModel}
      >
        <FormGroup
          label='Email'
          name='email'
          error={!!accountFormError.email}
          errorMessage={accountFormError.email}
          disabled={loading}
        />
        <FormGroup
          label='Password'
          name='password'
          error={!!accountFormError.password}
          errorMessage={accountFormError.password}
          disabled={loading}
          type='password'
        />
        <ButtonContainer>
          <FlexboxGrid justify='space-between'>
            <FlexboxGrid.Item colspan={11}>
              <Button
                appearance='default'
                block
                onClick={() => setSection(0)}
                disabled={loading}
              >
                Back
              </Button>
            </FlexboxGrid.Item>
            <FlexboxGrid.Item colspan={11}>
              <Button
                appearance='primary'
                block
                disabled={
                  !accountFormValue.email ||
                  !accountFormValue.password ||
                  accountFormError.email ||
                  accountFormError.password
                }
                onClick={login}
                loading={loading}
              >
                Next
              </Button>
            </FlexboxGrid.Item>
          </FlexboxGrid>
        </ButtonContainer>
      </Form>
    ),
    [accountFormValue, accountFormError, loading, login],
  );

  const renderCurrentSection = useCallback(() => {
    switch (section) {
      case 0:
        return renderWorkspaceSection();
      case 1:
        return renderAccountSection();
      default:
        return;
    }
  }, [section, renderWorkspaceSection, renderAccountSection]);

  return (
    <LoginPanel shaded>
      <Content>
        <Logo marginBottom={30} />
        <Section>{renderCurrentSection()}</Section>
        <SignUpLink>
          Don't you have an account? <a href={linkToSignUp}>Sign up</a>
        </SignUpLink>
      </Content>
    </LoginPanel>
  );
};

export default LoginCard;
