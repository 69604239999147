import React, { useState, useCallback, useContext, useEffect } from 'react';
import { get, isEqual } from 'lodash';
import { Row, Col, Whisper, Notification, Avatar, Button, Slider, InputNumber, InputGroup, Tooltip } from 'rsuite';
import { useHistory } from 'react-router-dom';

import { Bugsnag, Firebase } from '../services';
import { OrganizationContext, LoggedUserContext } from '../context';
import { Colors } from '../assets';
import RichText, { DEFAULT_VALUE } from './RichText';
import { formatNumber } from '../utils';

const GoalUpdateForm = ({
  goal,
  settings,
  requirement,
  onGoalUpdateCreated = () => {},
  showNotification = true,
  defaultHasFocused = false,
}) => {
  const organization = useContext(OrganizationContext);
  const loggedUser = useContext(LoggedUserContext);

  const [newUpdateDescription, setNewUpdateDescription] = useState(null);
  const [newUpdateProgress, setNewUpdateProgress] = useState(goal?.progress || 0);
  const [newUpdateAchievedAmount, setNewUpdateAchievedAmount] = useState(goal?.achievedAmount || 0);
  const [achievedMilestoneIndex, setAchievedMilestoneIndex] = useState(goal?.achievedMilestoneIndex || 0);
  const [hasFocused, setHasFocused] = useState(defaultHasFocused);

  useEffect(() => setNewUpdateProgress(goal?.progress || 0), [goal?.progress]);

  useEffect(() => {
    if (settings?.progress?.value === 'amount') {
      const targetedAmount = settings?.progress?.targetedAmount;
      const achievedAmount = newUpdateAchievedAmount || 0;
      const progress = Math.round((Number(achievedAmount) / Number(targetedAmount)) * 100);
      setNewUpdateProgress(progress);
    }
  }, [newUpdateAchievedAmount, settings]);

  useEffect(() => {
    if (settings?.progress?.value === 'milestones') {
      setNewUpdateProgress(
        Math.round((Number(achievedMilestoneIndex) / Number(settings?.progress?.milestones.length - 1)) * 100),
      );
    }
  }, [achievedMilestoneIndex, settings]);
  const create = useCallback(
    async (update) => {
      if (requirement) {
        await requirement();
      }
      Firebase.firestore()
        .collection('organizations')
        .doc(organization.id)
        .collection('private')
        .doc(organization.id)
        .collection('goals')
        .doc(goal.id)
        .collection('updates')
        .add({
          ...update,
          progress: Number(update.progress || 0),
          createdBy: loggedUser.id,
          updatedBy: loggedUser.id,
          createdAt: Firebase.firestore.FieldValue.serverTimestamp(),
          updatedAt: Firebase.firestore.FieldValue.serverTimestamp(),
          deletedAt: null,
          ...(settings?.progress?.value === 'amount' ? { achievedAmount: Number(newUpdateAchievedAmount) } : {}),
          ...(settings?.progress?.value === 'milestones'
            ? {
                achievedMilestone: settings?.progress?.milestones[achievedMilestoneIndex],
                achievedMilestoneIndex: achievedMilestoneIndex,
              }
            : {}),
        })
        .then(onGoalUpdateCreated)
        .catch((ex) => {
          Notification.error({
            title: 'There has been an error',
            description: get(ex, 'message', 'Please try again...'),
          });
          Bugsnag.notify(ex, (event) => {
            event.severity = 'warning';
          });
        });
      setNewUpdateDescription(DEFAULT_VALUE);
    },
    [
      organization.id,
      loggedUser.id,
      goal?.id,
      settings,
      newUpdateAchievedAmount,
      achievedMilestoneIndex,
      requirement,
      onGoalUpdateCreated,
      showNotification,
    ],
  );

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
        <Avatar
          circle
          size='md'
          style={{
            backgroundColor: Colors.TAGLINE,
            marginRight: 20,
          }}
          src={loggedUser.photoURL}
        >
          {!loggedUser.photoURL ? (loggedUser.displayName || '').substring(0, 2) : null}
        </Avatar>
        <b style={{ marginRight: 4 }}>{loggedUser.displayName}</b>
        <div style={{ display: 'flex', flex: 1, flexDirection: 'row-reverse' }}>
          <div style={{ display: 'block', maxWidth: 400, width: '100%' }}>
            <Row style={{ display: 'flex', alignItems: 'center' }}>
              <Col xs={24}>
                <div
                  style={
                    settings?.progress?.value === 'milestones' ? {} : { display: 'flex', flexDirection: 'row-reverse' }
                  }
                >
                  {(settings?.progress?.value === 'updates' || !settings?.progress?.value) && (
                    <InputGroup style={{ maxWidth: 110 }}>
                      <InputNumber min={0} max={100} value={newUpdateProgress} onChange={setNewUpdateProgress} />
                      <InputGroup.Addon>%</InputGroup.Addon>
                    </InputGroup>
                  )}
                  {settings?.progress?.value === 'amount' && (
                    <InputGroup style={{ maxWidth: 200 }}>
                      <InputNumber
                        min={0}
                        max={settings?.progress?.targetedAmount}
                        value={newUpdateAchievedAmount}
                        onChange={setNewUpdateAchievedAmount}
                      />
                      <InputGroup.Addon>/ {formatNumber(settings?.progress?.targetedAmount)}</InputGroup.Addon>
                    </InputGroup>
                  )}
                  {settings?.progress?.value === 'milestones' && (
                    <Slider
                      className='progress-slider'
                      min={0}
                      max={settings?.progress?.milestones?.length - 1}
                      value={achievedMilestoneIndex}
                      renderMark={(index) => (
                        <Whisper
                          placement='top'
                          trigger='hover'
                          speaker={<Tooltip>{settings?.progress?.milestones[index]}</Tooltip>}
                        >
                          <p
                            style={{
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              display: 'block',
                              width: 50,
                            }}
                          >
                            {settings?.progress?.milestones[index]}
                          </p>
                        </Whisper>
                      )}
                      onChange={setAchievedMilestoneIndex}
                      tooltip={false}
                      style={{ marginRight: 30 }}
                      graduated
                      progress
                    />
                  )}
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
      <RichText
        placeholder={'New update'}
        value={newUpdateDescription}
        onChange={setNewUpdateDescription}
        extraUploadData={{ goal: goal?.id }}
        onFocus={() => setHasFocused(true)}
        editorInputStyle={{ minHeight: hasFocused ? 100 : 136 }}
        toolbar={hasFocused}
      />
      <div style={{ textAlign: 'right' }}>
        <Button
          appearance='primary'
          onClick={() => create({ description: newUpdateDescription, progress: newUpdateProgress })}
          disabled={!newUpdateDescription || isEqual(newUpdateDescription, DEFAULT_VALUE)}
        >
          Send
        </Button>
      </div>
    </>
  );
};

export default GoalUpdateForm;
