import React, { useState, useCallback, useRef, useEffect } from 'react';
import { get, debounce } from 'lodash';
import Styled from 'styled-components';
import {
  Panel,
  Button,
  Notification,
  FlexboxGrid,
  InputGroup,
  Icon,
  Form,
  Schema,
  Whisper,
  Tooltip,
  Animation,
  Avatar,
  Row,
  Col,
} from 'rsuite';

import { Bugsnag, Firebase, Mixpanel } from '../services';
import { Logo, FormGroup } from '../components';
import { Colors } from '../assets';
import { useHistory, useLocation, useParams } from 'react-router-dom';

const LoginPanel = Styled(Panel)({
  backgroundColor: '#ffffff',
});
const Content = Styled.div({});
const Section = Styled.div({
  marginBottom: 20,
});
const ButtonContainer = Styled.div({
  marginBottom: 10,
});
const SignUpLink = Styled.p({
  textAlign: 'center',
});

const LogoImage = Styled.div(({ url }) => ({
  backgroundImage: `url('${url}')`,
  backgroundPosition: 'center',
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  backgroundColor: Colors.TAGLINE,
  width: '100%',
  height: '100%',
  marginRight: 'auto',
  marginLeft: 'auto',
  borderRadius: 8,
  cursor: 'pointer',
}));

const { StringType } = Schema.Types;
const accountFormModel = Schema.Model({
  password: StringType()
    .isRequired('This field is required')
    .pattern(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      'Password must have at least 1 lowercase letter, 1 uppercase letter, 1 number and 1 symbol (!@#$%^&*) and be at least 8 characters long',
    ),
  repeatPassword: StringType()
    .isRequired('This field is required')
    .addRule((value, data) => value === data.password, 'The passwords are inconsistent'),
});

const { Collapse } = Animation;

const WelcomeCard = ({ linkToSignUp, setBackground }) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [workspace, setWorkspace] = useState(null);
  const accountFormRef = useRef(null);
  const [accountFormValue, setAccountFormValue] = useState({});
  const [accountFormError, setAccountFormError] = useState({});
  const query = new URLSearchParams(useLocation().search);
  const workspaceId = query.get('workspaceId');
  const code = query.get('code');
  const email = query.get('email');

  const setPassword = useCallback(async () => {
    setLoading(true);
    try {
      await Firebase.functions().httpsCallable('AcceptInvite')({
        code,
        workspaceId,
        password: accountFormValue.password,
      });
      Firebase.auth().tenantId = workspace.tenantId;
      await Firebase.auth().signInWithEmailAndPassword(accountFormValue.email, accountFormValue.password);
      Mixpanel.track('Accepted Invite', { email: accountFormValue.email, workspaceId: workspace.id });
    } catch (ex) {
      Notification.error({
        title: 'There has been an error',
        description: "Invitation doesn't exist or has expired, please contact workspace administrator.",
      });
      Bugsnag.notify(ex, (event) => {
        event.severity = 'warning';
      });
    }
    setLoading(false);
  }, [workspace, code, accountFormValue, workspaceId]);

  const validate = useCallback(
    async (workspaceId, code) => {
      try {
        setLoading(true);
        const doc = await Firebase.firestore()
          .collection('organizations')
          .doc(workspaceId)
          .collection('public')
          .doc(workspaceId)
          .get();
        if (doc && doc.exists) {
          Firebase.auth().tenantId = doc.data().tenantId;
          setAccountFormValue({ email });
          setWorkspace({ id: doc.id, ...doc.data() });
          setBackground(doc.data().backgroundURL);
          setLoading(false);
        } else {
          throw new Error('Workspace not found');
        }
      } catch (ex) {
        Notification.error({
          title: 'Workspace not found',
          description: 'Please contact your workspace administrator.',
          duration: 10000,
        });
        Bugsnag.notify(ex, (event) => {
          event.severity = 'warning';
        });
        history.push(`/`);
      }
    },
    [setBackground, history, email],
  );

  useEffect(() => {
    validate(workspaceId, code);
  }, [workspaceId, code, validate]);

  const onChangePassword = useCallback(
    (value) => {
      setAccountFormValue({
        ...accountFormValue,
        password: value,
      });
      setTimeout(() => accountFormRef && accountFormRef.current && accountFormRef.current.check(), 20);
    },
    [accountFormValue],
  );

  return (
    <LoginPanel shaded>
      <Content>
        <Logo marginBottom={30} />
        <Section>
          <Collapse in={!!workspace}>
            <Section>
              <Row gutter={16}>
                <Col xs={18}>
                  <span>Organization</span>
                  <h4>{(workspace || {}).displayName}</h4>
                </Col>
                <Col xs={6} style={{ textAlign: 'right' }}>
                  <Avatar
                    square
                    size='lg'
                    style={{
                      backgroundColor: Colors.TAGLINE,
                    }}
                  >
                    {!(workspace || {}).logoURL ? (
                      ((workspace || {}).displayName || '').substring(0, 2)
                    ) : (
                      <LogoImage url={(workspace || {}).logoURL} />
                    )}
                  </Avatar>
                </Col>
              </Row>
            </Section>
          </Collapse>
          <Form
            fluid
            key='accountForm'
            ref={accountFormRef}
            onChange={setAccountFormValue}
            onCheck={setAccountFormError}
            formValue={accountFormValue}
            model={accountFormModel}
          >
            <FormGroup label='Email' name='email' disabled />
            <FormGroup
              label='New Password'
              name='password'
              error={!!accountFormError.password}
              errorMessage={accountFormError.password}
              onChange={onChangePassword}
              type='password'
              disabled={loading}
            />
            <FormGroup
              label='Repeat New Password'
              name='repeatPassword'
              error={!!accountFormError.repeatPassword}
              errorMessage={accountFormError.repeatPassword}
              type='password'
              disabled={loading}
            />
            <ButtonContainer>
              <FlexboxGrid justify='end'>
                <FlexboxGrid.Item colspan={24}>
                  <Button
                    appearance='primary'
                    block
                    disabled={
                      !accountFormValue.password ||
                      !accountFormValue.repeatPassword ||
                      accountFormError.password ||
                      accountFormError.repeatPassword ||
                      loading
                    }
                    onClick={() => setPassword()}
                    loading={loading}
                  >
                    Accept Invite
                  </Button>
                </FlexboxGrid.Item>
              </FlexboxGrid>
            </ButtonContainer>
          </Form>
        </Section>
      </Content>
    </LoginPanel>
  );
};

export default WelcomeCard;
