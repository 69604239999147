import React, { useState, useContext, useEffect, useMemo, useCallback, useRef } from 'react';
import Styled from 'styled-components';
import {
  Panel,
  Col,
  Row,
  Avatar,
  Placeholder,
  IconButton,
  Icon,
  Notification,
  ButtonGroup,
  Whisper,
  Tooltip,
  Button,
  List,
} from 'rsuite';
import { get, orderBy, last, find, isEqual } from 'lodash';
import moment from 'moment-timezone';

import { Bugsnag, Firebase, Mixpanel } from '../services';
import { Colors } from '../assets';
import { LoggedUserContext, OrganizationContext, TeamContext } from '../context';
import { docsToArray } from '../utils';
import { GoalSelectPicker } from '.';
import { OutcomeItem } from '../components';
import AutogrowInput from './AutogrowInput';
import RichText, { DEFAULT_VALUE } from './RichText';

const Container = Styled(Panel)({
  backgroundColor: Colors.WHITE,
  marginBottom: 20,
  transition: 'height .5s',
});

const ProfileContainer = Styled.div({
  textAlign: 'center',
  minHeight: 76,
});

const DisplayName = Styled.b({
  marginTop: 4,
  marginBottom: 4,
  display: 'block',
});

const OutcomesContainer = Styled.div({
  minHeight: 280,
  marginBottom: 12,
  overflowX: 'hidden',
  overflowY: 'auto',
});

const NewOutcomeContainer = Styled.div({});

const EmptyContainer = Styled.div({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  textAlign: 'center',
});

const OutcomesCard = ({ member, date, goals }) => {
  const loggedUser = useContext(LoggedUserContext);
  const organization = useContext(OrganizationContext);
  const team = useContext(TeamContext);
  const [loading, setLoading] = useState(true);
  const [outcomes, setOutcomes] = useState([]);
  const [orderedOutcomes, setOrderedOutcomes] = useState([]);
  const [newOutcomeDescription, setNewOutcomeDescription] = useState(DEFAULT_VALUE);
  const [newOutcomeGoal, setNewOutcomeGoal] = useState(null);
  const [editor, setEditor] = useState(null);

  useEffect(
    () =>
      setOrderedOutcomes(
        orderBy(outcomes, ['order', (o) => (get(o, 'updatedAt') ? get(o, 'updatedAt').toDate() : 0)], ['asc', 'asc']),
      ),
    [outcomes],
  );

  useEffect(() => {
    let subscription = null;

    if (organization.id && member.id) {
      setLoading(true);
      subscription = Firebase.firestore()
        .collection('organizations')
        .doc(organization.id)
        .collection('private')
        .doc(organization.id)
        .collection('outcomes')
        .where('deletedAt', '==', null)
        .where('assignedTo', '==', member.id)
        .where('date', '==', date)
        .where('team', '==', team.id)
        .onSnapshot((docs) => {
          setOutcomes(docsToArray(docs));
          setLoading(false);
        });
    }

    return () => {
      subscription && subscription();
    };
  }, [member.id, organization.id, team?.id, date]);

  const createNewOutcome = useCallback(async () => {
    if (!newOutcomeDescription) return;

    Firebase.firestore()
      .collection('organizations')
      .doc(organization.id)
      .collection('private')
      .doc(organization.id)
      .collection('outcomes')
      .add({
        date,
        description: newOutcomeDescription,
        goal: newOutcomeGoal,
        assignedTo: member.id,
        completed: false,
        order: get(last(orderedOutcomes), 'order', 0) + 10,
        team: team.id,
        createdBy: loggedUser.id,
        updatedBy: loggedUser.id,
        createdAt: Firebase.firestore.FieldValue.serverTimestamp(),
        updatedAt: Firebase.firestore.FieldValue.serverTimestamp(),
        deletedAt: null,
        goalSequence: newOutcomeGoal ? find(goals.list, ['id', newOutcomeGoal]).goalSequence || [] : [],
      })
      .then(() => {
        Mixpanel.track('Created Outcome');
        Mixpanel.people.increment('Outcomes Created');
      })
      .catch((ex) => {
        Notification.error({
          title: 'There has been an error',
          description: get(ex, 'message', 'Please try again...'),
        });
        Bugsnag.notify(ex, (event) => {
          event.severity = 'warning';
        });
      });

    setNewOutcomeDescription(DEFAULT_VALUE);
    setTimeout(() => editor?.focus(), 50);
  }, [
    orderedOutcomes,
    newOutcomeDescription,
    newOutcomeGoal,
    loggedUser,
    member,
    team,
    goals,
    date,
    organization.id,
    editor,
  ]);

  const update = useCallback(
    async (outcome) => {
      try {
        if (!outcome.description) return;

        await Firebase.firestore()
          .collection('organizations')
          .doc(organization.id)
          .collection('private')
          .doc(organization.id)
          .collection('outcomes')
          .doc(outcome.id)
          .update({
            ...outcome,
            updatedBy: loggedUser.id,
            updatedAt: Firebase.firestore.FieldValue.serverTimestamp(),
          });
      } catch (ex) {
        Notification.error({
          title: 'There has been an error',
          description: get(ex, 'message', 'Please try again...'),
        });
        Bugsnag.notify(ex, (event) => {
          event.severity = 'warning';
        });
      }
    },
    [team.id, loggedUser.id],
  );

  const onSort = useCallback(
    async ({ oldIndex, newIndex }) => {
      if (oldIndex === newIndex) return;
      const list = [...orderedOutcomes];
      const data = list.splice(oldIndex, 1);
      list.splice(newIndex, 0, data[0]);

      const batch = Firebase.firestore().batch();
      for (let n = 0; n < list.length; n++) {
        list[n].order = (n + 1) * 10;
        batch.set(
          Firebase.firestore()
            .collection('organizations')
            .doc(organization.id)
            .collection('private')
            .doc(organization.id)
            .collection('outcomes')
            .doc(list[n].id),
          {
            ...list[n],
            updatedBy: loggedUser.id,
            updatedAt: Firebase.firestore.FieldValue.serverTimestamp(),
          },
          { merge: true },
        );
      }
      setOrderedOutcomes(list);

      await batch.commit();
    },
    [orderedOutcomes, organization.id, loggedUser.id],
  );

  return (
    <Row>
      <Col xs={24}>
        <Container>
          {(loading || (!loading && orderedOutcomes.length > 0)) && (
            <ProfileContainer>
              <Avatar
                circle
                size='md'
                style={{
                  backgroundColor: Colors.TAGLINE,
                }}
                src={member.photoURL}
              >
                {!member.photoURL ? (member.displayName || '').substring(0, 2) : null}
              </Avatar>
              <DisplayName>{member.displayName}</DisplayName>
            </ProfileContainer>
          )}
          <OutcomesContainer style={!loading && orderedOutcomes.length <= 0 ? { minHeight: 376 } : { height: 300 }}>
            {loading && (
              <>
                <Placeholder.Paragraph />
                <Placeholder.Paragraph />
                <Placeholder.Paragraph />
                <Placeholder.Paragraph />
                <Placeholder.Paragraph />
                <Placeholder.Paragraph />
              </>
            )}
            {!loading && orderedOutcomes.length <= 0 && (
              <EmptyContainer>
                <Avatar
                  circle
                  size='lg'
                  className='rs-avatar-xl animate__animated animate__fadeInDown'
                  style={{
                    backgroundColor: Colors.TAGLINE,
                    marginTop: -20,
                  }}
                  src={member.photoURL}
                >
                  {!member.photoURL ? (member.displayName || '').substring(0, 2) : null}
                </Avatar>
                <DisplayName className='animate__animated animate__fadeInUp'>{member.displayName}</DisplayName>
              </EmptyContainer>
            )}
            {!loading && orderedOutcomes.length > 0 && (
              <List sortable pressDelay={300} onSort={onSort} style={{ boxShadow: 'none' }}>
                {orderedOutcomes.map((o, index, arr) => (
                  <List.Item key={o.id} index={index} style={{ boxShadow: 'none', paddingTop: 2, paddingBottom: 0 }}>
                    <OutcomeItem key={o.id} value={o} goals={goals} previous={arr[index - 1]} next={arr[index + 1]} />
                  </List.Item>
                ))}
              </List>
            )}
          </OutcomesContainer>
          <NewOutcomeContainer>
            <Row style={{ marginRight: 0, marginLeft: 0, display: 'flex', flexDirection: 'row' }}>
              <Col
                style={{
                  flex: 1,
                  display: 'flex',
                }}
              >
                <RichText
                  placeholder={'New Outcome'}
                  value={newOutcomeDescription}
                  onChange={setNewOutcomeDescription}
                  onKeyPress={(key) =>
                    key === 'Enter' && newOutcomeDescription && !isEqual(newOutcomeDescription, DEFAULT_VALUE)
                      ? createNewOutcome()
                      : null
                  }
                  toolbar={false}
                  floatingToolbar={false}
                  clickableLinks={true}
                  placeholderClassName='editor-placeholder-inline'
                  editorInputClassName='editor-input-inline'
                  onEditorUpdate={setEditor}
                />
              </Col>
              <Col
                style={{
                  display: 'flex',
                  flexDirection: 'row-reverse',
                }}
              >
                <Whisper placement={'bottom'} speaker={<Tooltip>{'Create (⏎ Enter)'}</Tooltip>}>
                  <IconButton
                    icon={<Icon icon='arrow-right-line' />}
                    disabled={!newOutcomeDescription || isEqual(newOutcomeDescription, DEFAULT_VALUE)}
                    onClick={createNewOutcome}
                  />
                </Whisper>
                <GoalSelectPicker
                  preloadedGoals={goals.list}
                  style={{ marginRight: 4, maxHeight: 36 }}
                  placeholder='Goal'
                  onSelect={setNewOutcomeGoal}
                  value={newOutcomeGoal}
                  cleanable={false}
                  appearance='default'
                  small
                />
              </Col>
            </Row>
          </NewOutcomeContainer>
        </Container>
      </Col>
    </Row>
  );
};

export default OutcomesCard;
