import React, { useState, useCallback, useContext, useRef } from 'react';
import { get, includes } from 'lodash';
import { Form, Button, Notification, Schema, FlexboxGrid, Toggle, Row, Col, DatePicker, Tag } from 'rsuite';
import ColorPicker from 'rsuite-color-picker';

import { Bugsnag, Firebase } from '../services';
import { LoggedUserContext, OrganizationContext, TeamMembersContext } from '../context';
import { FormGroup, UserTagPicker } from '../components';
import { generateIdBasedOn, uuidv4 } from '../utils';
import GoalTag from './GoalTag';
const { StringType, BooleanType, ArrayType } = Schema.Types;

const goalFormModel = Schema.Model({
  displayName: StringType()
    .minLength(4, 'The field cannot be less than 4 characters')
    .isRequired('This field is required'),
  private: BooleanType().isRequired('This field is required'),
  leaders: ArrayType().isRequired('This field is required').minLength(1, 'Choose at least one'),
});

const GoalForm = ({ data: team, isNew, onSave }) => {
  const [saving, setSaving] = useState(false);
  const loggedUser = useContext(LoggedUserContext);
  const organization = useContext(OrganizationContext);
  const goalFormRef = useRef(null);
  const [goalFormValue, setGoalFormValue] = useState(
    isNew
      ? {
          private: false,
          leaders: [],
          members: [],
        }
      : {
          photoURL: team.photoURL || '',
          displayName: team.displayName,
          description: team.description,
          private: team.private,
          leaders: Object.keys(team.leaders),
          members: Object.keys(team.members),
        },
  );
  const [goalFormError, setGoalFormError] = useState({});

  const save = useCallback(async () => {
    setSaving(true);
    try {
      if (isNew) {
        let id = generateIdBasedOn(goalFormValue.displayName);
        const doc = await Firebase.firestore()
          .collection('organizations')
          .doc(organization.id)
          .collection('private')
          .doc(organization.id)
          .collection('teams')
          .doc(id)
          .get();
        if (doc.exists) {
          id += `-${uuidv4().split('-')[0]}`;
          if (
            (
              await Firebase.firestore()
                .collection('organizations')
                .doc(organization.id)
                .collection('private')
                .doc(organization.id)
                .collection('teams')
                .doc(id)
                .get()
            ).exists
          ) {
            throw new Error('unique id already exists');
          }
        }
        await Firebase.firestore()
          .collection('organizations')
          .doc(organization.id)
          .collection('private')
          .doc(organization.id)
          .collection('teams')
          .doc(id)
          .set({
            ...goalFormValue,
            leaders: (goalFormValue.leaders || []).reduce((r, u) => ({ ...r, [u]: true }), {}),
            members: (goalFormValue.members || []).reduce((r, u) => ({ ...r, [u]: true }), {}),
            createdBy: loggedUser.id,
            updatedBy: loggedUser.id,
            createdAt: Firebase.firestore.FieldValue.serverTimestamp(),
            updatedAt: Firebase.firestore.FieldValue.serverTimestamp(),
          });
      } else {
        await Firebase.firestore()
          .collection('organizations')
          .doc(organization.id)
          .collection('private')
          .doc(organization.id)
          .collection('teams')
          .doc(team.id)
          .update({
            ...goalFormValue,
            leaders: (goalFormValue.leaders || []).reduce((r, u) => ({ ...r, [u]: true }), {}),
            members: (goalFormValue.members || []).reduce((r, u) => ({ ...r, [u]: true }), {}),
            updatedBy: loggedUser.id,
            updatedAt: Firebase.firestore.FieldValue.serverTimestamp(),
          });
      }
      if (onSave) onSave();
    } catch (ex) {
      Notification.error({
        title: 'There has been an error',
        description: get(ex, 'message', 'Please try again...'),
      });
      Bugsnag.notify(ex, (event) => {
        event.severity = 'warning';
      });
    }
    setSaving(false);
  }, [isNew, team, loggedUser.id, onSave, goalFormValue, organization]);

  return (
    <Form
      fluid
      key='goalForm'
      ref={goalFormRef}
      onChange={setGoalFormValue}
      onCheck={setGoalFormError}
      formValue={goalFormValue}
      model={goalFormModel}
    >
      <Row style={{ marginBottom: 20 }}>
        <Col xs={12}>
          <FormGroup
            label='Code'
            name='code'
            hint='Unique identifier of goal'
            error={!!goalFormError.code}
            errorMessage={goalFormError.code}
            disabled={saving}
            required
          />
        </Col>
        <Col xs={6}>
          <FormGroup
            label='Color'
            name='color'
            error={!!goalFormError.color}
            errorMessage={goalFormError.color}
            onChange={({ hex }) => setGoalFormValue({ ...goalFormValue, color: hex })}
            disableAlpha
            disabled={saving}
            required
            accepter={ColorPicker}
          />
        </Col>
        <Col xs={6}>
          <GoalTag
            color={goalFormValue.color}
            code={goalFormValue.code}
            style={{ minHeight: 60, width: '100%', textAlign: 'center', fontSize: 18, alignContext: 'center' }}
          />
        </Col>
      </Row>
      <FormGroup
        label='Name'
        name='displayName'
        error={!!goalFormError.displayName}
        errorMessage={goalFormError.displayName}
        disabled={saving}
        required
      />
      <FormGroup
        label='Description'
        name='description'
        componentClass='textarea'
        rows={3}
        error={!!goalFormError.description}
        errorMessage={goalFormError.description}
        disabled={saving}
      />
      <FormGroup
        label='Responsibles'
        name='responsibles'
        error={!!goalFormError.responsibles}
        errorMessage={goalFormError.responsibles}
        disabled={saving}
        accepter={UserTagPicker}
        block
      />
      <FormGroup
        label='Due Date'
        name='dueDate'
        placeholder='Select date'
        error={!!goalFormError.dueDate}
        errorMessage={goalFormError.dueDate}
        disabled={saving}
        accepter={DatePicker}
        oneTap
        block
      />

      <FlexboxGrid justify='end'>
        <FlexboxGrid.Item colspan={4}>
          <Button
            appearance='primary'
            block
            disabled={
              !goalFormValue.displayName ||
              goalFormError.displayName ||
              goalFormValue.private === undefined ||
              goalFormValue.private === null ||
              goalFormError.private ||
              goalFormValue.leaders === undefined ||
              goalFormValue.leaders === null ||
              goalFormValue.leaders.length === 0 ||
              goalFormError.leaders
            }
            loading={saving}
            onClick={save}
          >
            Save
          </Button>
        </FlexboxGrid.Item>
      </FlexboxGrid>
    </Form>
  );
};

export default GoalForm;
