import { useState, useContext, useEffect } from 'react';

import { Firebase } from '../services';
import { OrganizationContext } from '../context';

const usePartner = ({ partner, loadOnce }) => {
  const [loading, setLoading] = useState(true);
  const [value, setValue] = useState(null);
  const organization = useContext(OrganizationContext);

  useEffect(() => {
    let subscription = () => {};
    setLoading(true);
    if (organization.id) {
      subscription = Firebase.firestore()
        .collection('organizations')
        .doc(organization.id)
        .collection('private')
        .doc(organization.id)
        .collection('partners')
        .doc(partner)
        .onSnapshot((doc) => {
          if (doc.exists) {
            setValue(doc.data());
          } else {
            setValue(null);
          }
          setLoading(false);
          if (loadOnce) {
            subscription();
          }
        });
    }
    return subscription;
  }, [organization.id, partner, loadOnce]);

  return { loading, value };
};

export default usePartner;
