import React, { useState, useEffect } from 'react';
import { Panel, Progress } from 'rsuite';
import Styled from 'styled-components';

import { Colors } from '../assets';
import { Logo } from '../components';
import { BrandLayout } from '../layouts';

const LoaderPanel = Styled(Panel)({
  backgroundColor: Colors.WHITE,
  textAlign: 'center',
  paddingTop: 20,
  paddingBottom: 20,
});

const LoadingPage = () => {
  const [value, setValue] = useState(0);

  useEffect(() => {
    if (value < 100) setTimeout(() => setValue(value + 1), 10);
    return;
  }, [value]);

  return (
    <BrandLayout>
      <LoaderPanel shaded>
        <Logo />
        <Progress.Line
          style={{ marginTop: 20 }}
          showInfo={false}
          percent={value}
        />
      </LoaderPanel>
    </BrandLayout>
  );
};

export default LoadingPage;
