import React, { useState, useCallback, useContext, useRef } from 'react';
import { get } from 'lodash';
import Styled from 'styled-components';
import {
  Avatar,
  Row,
  Col,
  InputPicker,
  Dropdown,
  Icon,
  Popover,
  Whisper,
  IconButton,
  Modal,
  Notification,
} from 'rsuite';

import { Bugsnag, Firebase, Mixpanel } from '../services';
import { Colors } from '../assets';
import { UserRoles } from '../types';
import { UserForm } from '../components';
import { LimitationsContext, LoggedUserContext, OrganizationContext, UsageContext } from '../context';

const UserInfo = Styled.div({
  marginLeft: 8,
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'start',
  alignSelf: 'center',
  flexDirection: 'column',
  top: 0,
});

const roleOptions = UserRoles.map((r) => ({
  label: r,
  value: r,
}));

const UserItem = ({ data: user, ...props }) => {
  const organization = useContext(OrganizationContext);
  const loggedUser = useContext(LoggedUserContext);
  const limitations = useContext(LimitationsContext);
  const usage = useContext(UsageContext);
  const [showUserFormModal, setShowUserFormModal] = useState(false);

  const triggerRef = useRef();
  const handleSelectMenu = useCallback(() => triggerRef.current.hide(), [triggerRef]);

  const doSetShowUserFormModal = useCallback(
    (value) => () => setShowUserFormModal(value),
    [],
  );

  const remove = useCallback(async () => {
    try {
      if (window.confirm('Are you sure you want to remove this user?')) {
        await Firebase.firestore()
          .collection('organizations')
          .doc(organization.id)
          .collection('private')
          .doc(organization.id)
          .collection('users')
          .doc(user.id)
          .update({
            deletedBy: loggedUser.id,
            deletedAt: Firebase.firestore.FieldValue.serverTimestamp(),
          });
          Notification.success({
            title: 'Success',
            description: 'User removed successfully',
          });
        Mixpanel.track('Deleted User');
      }
    } catch (ex) {
      Notification.error({
        title: 'There has been an error',
        description: get(ex, 'message', 'Please try again...'),
      });
      Bugsnag.notify(ex, (event) => {
        event.severity = 'warning';
      });
    }
  }, [user.id, organization.id, loggedUser.id]);

  const update = useCallback(
    async (data) => {
      try {
        await Firebase.firestore()
          .collection('organizations')
          .doc(organization.id)
          .collection('private')
          .doc(organization.id)
          .collection('users')
          .doc(user.id)
          .update({
            ...data,
            updatedBy: loggedUser.id,
            updatedAt: Firebase.firestore.FieldValue.serverTimestamp(),
          });
          if (data.recoverPassword) {
            Notification.success({
              title: 'Success',
              description: 'Recover Password Link has been sent',
            });
          }
      } catch (ex) {
        Notification.error({
          title: 'There has been an error',
          description: get(ex, 'message', 'Please try again...'),
        });
        Bugsnag.notify(ex, (event) => {
          event.severity = 'warning';
        });
      }
    },
    [user.id, organization.id, loggedUser.id],
  );

  const disable = useCallback(async () => {
    if (window.confirm('Are you sure you want to disable this user?')) {
      Mixpanel.track('Disabled User');
      return update({ disabled: true });
    }
  }, [update]);

  const enable = useCallback(async () => {
    if (window.confirm('Are you sure you want to enable this user?')) {
      Mixpanel.track('Enabled User');
      return update({ disabled: false });
    }
  }, [update]);

  const recoverPassword = useCallback(async () => {
    if (
      window.confirm(
        'Are you sure you want to send this user a recovery password email?',
      )
    ) {
      Mixpanel.track('Requested Password Recovery for User');
      return update({ recoverPassword: true });
    }
  }, [update]);

  return (
    <Row gutter={16} {...props}>
      <Col xs={16} style={{ display: 'flex' }}>
        <Avatar
          square
          size='md'
          style={{
            backgroundColor: Colors.TAGLINE,
          }}
          src={user.photoURL}
          circle
        >
          {!user.photoURL ? (user.displayName || '').substring(0, 2) : null}
        </Avatar>
        <UserInfo>
          <span>{user.displayName}</span>
          <a href={`mailto:${user.email}`}>{user.email}</a>
        </UserInfo>
      </Col>
      <Col
        xs={8}
        style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'row-reverse',
        }}
      >
        <div>
          <Whisper
            triggerRef={triggerRef}
            trigger='click'
            placement='bottomEnd'
            speaker={
              <Popover placement='bottomEnd' full>
                <Dropdown.Menu onSelect={handleSelectMenu}>
                  <Dropdown.Item
                    icon={<Icon icon='edit' />}
                    onSelect={doSetShowUserFormModal(true)}
                    disabled={loggedUser.id !== user.id && loggedUser.role === 'Member'}
                  >
                    Edit
                  </Dropdown.Item>
                  <Dropdown.Item
                    icon={<Icon icon='envelope-o' />}
                    onSelect={recoverPassword}
                    disabled={loggedUser.id === user.id || loggedUser.role === 'Member'}
                  >
                    Recover Password
                  </Dropdown.Item>
                  <Dropdown.Item
                    icon={
                      <Icon
                        icon={user.disabled ? 'plus-square-o' : 'collasped-o'}
                      />
                    }
                    disabled={user.role === 'Owner' || loggedUser.id === user.id || loggedUser.role === 'Member' || (user.disabled && usage?.users?.active >= limitations?.users?.max)}
                    onSelect={user.disabled ? enable : disable}
                  >
                    {user.disabled ? 'Enable' : 'Disable'}
                  </Dropdown.Item>
                  <Dropdown.Item
                    icon={<Icon icon='close' />}
                    disabled={user.role === 'Owner' || loggedUser.id === user.id || loggedUser.role === 'Member'}
                    onSelect={remove}
                  >
                    Delete
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Popover>
            }
          >
            <IconButton icon={<Icon icon='ellipsis-v' />} />
          </Whisper>
        </div>
        <div style={{ maxWidth: 150, marginRight: 8 }}>
          <InputPicker
            block
            data={roleOptions}
            value={user.role}
            disabled={user.role === 'Owner' || loggedUser.role === 'Member'}
            disabledItemValues={['Owner']}
            onChange={(role) => (role ? update({ role }) : null)}
            cleanable={false}
          />
        </div>
      </Col>
      <Modal
        key={`user-form-${user.id}`}
        show={showUserFormModal}
        onHide={doSetShowUserFormModal(false)}
        overflow={false}
      >
        <Modal.Header>
          <Modal.Title>Edit User</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ paddingBottom: 0 }}>
          <UserForm data={user} onSave={doSetShowUserFormModal(false)} />
        </Modal.Body>
      </Modal>
    </Row>
  );
};

export default UserItem;
