import { useContext, useEffect } from 'react';
import { $createTextNode, TextNode } from 'lexical';
import { mergeRegister } from '@lexical/utils';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $createLinkNode } from '@lexical/link';
import { $createJiraTicketNode } from '../lexical-nodes/JiraTicketNode';
import { IntegrationsContext } from '../context';
import { Firebase } from '../services';

const JIRA_TICKET_REGEX = /[[A-Z]{2,}-\d+]/g;

const jiraTicketTransform = (editor, enabled, host) => (node) => {
  if (!enabled) return;
  const textContent = node.getTextContent();
  const match = JIRA_TICKET_REGEX.exec(textContent);
  if (match) {
    const text = match[0];
    const start = match.index;
    const end = start + text.length - 1;
    const stringBefore = textContent.substring(0, start);
    const stringAfter = textContent.substring(end + 1);
    const jiraTicketNode = $createJiraTicketNode(text);
    node.replace(jiraTicketNode);
    if (stringBefore) {
      jiraTicketNode.insertBefore($createTextNode(stringBefore));
    }
    if (stringAfter) {
      jiraTicketNode.insertAfter($createTextNode(stringAfter));
    }
    const id = text.slice(1, text.length - 1);
    Firebase.functions()
      .httpsCallable('FindJiraIssue')({
        id,
      })
      .then(
        ({
          data: {
            fields: { summary },
          },
        }) => {
          editor.update(() => {
            try {
              const linkNode = $createLinkNode(`https://${host}/browse/${id}`);
              const textNode = $createTextNode(`[${id} ${summary}]`);
              linkNode.append(textNode);
              jiraTicketNode.replace(linkNode);
            } catch (err) {
              // do nothing
            }
          });
        },
      )
      .catch((ex) => {
        editor.update(() => {
          try {
            const textNode = $createTextNode(`[${id} doesn't exist]`);
            jiraTicketNode.replace(textNode);
          } catch (err) {
            // do nothing
          }
        });
      });
  }
};

function useJiraTickets(editor) {
  const { map: integration } = useContext(IntegrationsContext);

  useEffect(
    () =>
      mergeRegister(
        editor.registerNodeTransform(
          TextNode,
          jiraTicketTransform(editor, integration?.jira?.enabled || false, integration?.jira?.host),
        ),
      ),
    [editor, integration],
  );
}

export default function JiraTicketPlugin() {
  const [editor] = useLexicalComposerContext();
  useJiraTickets(editor);
  return null;
}
