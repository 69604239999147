import React, { useContext, useMemo } from 'react';
import { Avatar, Tooltip, Whisper } from 'rsuite';
import { Colors } from '../assets';
import { OrganizationUsersContext } from '../context';

const UserAvatar = ({ style = {}, whisper = false, placement = 'bottom', id, ...props }) => {
  const { map: usersMap } = useContext(OrganizationUsersContext);

  const AvatarData = useMemo(
    () => (
      <Avatar
        circle
        size='md'
        style={{
          backgroundColor: Colors.TAGLINE,
          cursor: 'pointer',
          ...style,
        }}
        src={usersMap[id]?.photoURL}
        {...props}
      >
        {!usersMap[id]?.photoURL ? (usersMap[id]?.displayName || '').substring(0, 2) : null}
      </Avatar>
    ),
    [usersMap, id, style, props],
  );

  return whisper ? (
    <Whisper placement={placement} speaker={<Tooltip>{usersMap[id]?.displayName}</Tooltip>}>
      {AvatarData}
    </Whisper>
  ) : (
    <>{AvatarData}</>
  );
};

export default UserAvatar;
