import React, { useContext, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect, useLocation } from 'react-router-dom';

import {
  AuthContextAware,
  OrganizationContextAware,
  LoggedUserContextAware,
  OrganizationUsersContextAware,
  OrganizationTeamsContextAware,
  IntegrationsContextAware,
  LimitationsContextAware,
  UsageContextAware,
} from './context';
import { LoginPage, SignUpPage, OrganizationPage, LoadingPage, RecoverPassword, Welcome, Demo, Error } from './pages';

import { AuthContext } from './context';
import { get } from 'lodash';
import { FeedbackModule } from './components';
import { Firebase, Mixpanel } from './services';
import { ErrorBoundary } from './services/Bugsnag';

const AnonymousRoute = ({ children, ...rest }) => {
  const auth = useContext(AuthContext);
  const loading = auth.loading;
  const isLoggedIn = !!get(auth, 'session.uid');
  const workspaceId = get(auth, 'token.claims.workspaceId');
  const location = useLocation();

  useEffect(() => {
    Mixpanel.track('Viewed Page', { page: location.pathname });
  }, [location]);

  return loading ? (
    <LoadingPage />
  ) : (
    <Route
      {...rest}
      render={({ location }) =>
        !isLoggedIn || !workspaceId ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: `/${workspaceId}`,
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

const AuthenticatedRoute = ({ children, ...rest }) => {
  const auth = useContext(AuthContext);
  const loading = auth.loading;
  const isLoggedIn = !!get(auth, 'session.uid');
  const workspaceId = get(auth, 'token.claims.workspaceId');
  const location = useLocation();

  useEffect(() => {
    if (workspaceId) {
      Mixpanel.track('Viewed Page', { page: location?.pathname?.replace(`/${workspaceId}`, '') });
    }
  }, [location, workspaceId]);

  return loading ? (
    <LoadingPage />
  ) : (
    <Route
      {...rest}
      render={({ location }) =>
        isLoggedIn && workspaceId ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

const App = () => {
  return (
    <ErrorBoundary FallbackComponent={Error}>
      <AuthContextAware>
        <Router>
          <Switch>
            <AnonymousRoute path='/login'>
              <LoginPage />
            </AnonymousRoute>
            <AnonymousRoute path='/signup'>
              <SignUpPage />
            </AnonymousRoute>
            <AnonymousRoute path='/recover-password'>
              <RecoverPassword />
            </AnonymousRoute>
            <AnonymousRoute path='/welcome'>
              <Welcome />
            </AnonymousRoute>
            <AnonymousRoute path='/demo'>
              <Demo />
            </AnonymousRoute>
            <AuthenticatedRoute path='/:workspaceId'>
              <OrganizationContextAware>
                <LimitationsContextAware>
                  <UsageContextAware>
                    <IntegrationsContextAware>
                      <OrganizationUsersContextAware>
                        <OrganizationTeamsContextAware>
                          <LoggedUserContextAware>
                            <div id='organization-page'>
                              <OrganizationPage />
                            </div>
                            <FeedbackModule />
                          </LoggedUserContextAware>
                        </OrganizationTeamsContextAware>
                      </OrganizationUsersContextAware>
                    </IntegrationsContextAware>
                  </UsageContextAware>
                </LimitationsContextAware>
              </OrganizationContextAware>
            </AuthenticatedRoute>
            <Route>
              <Redirect
                to={{
                  pathname: '/login',
                }}
              />
            </Route>
          </Switch>
        </Router>
      </AuthContextAware>
    </ErrorBoundary>
  );
};

export default App;
