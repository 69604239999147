import React, { useCallback, useContext, useMemo, useRef } from 'react';
import { get, omit } from 'lodash';
import Styled from 'styled-components';
import { Avatar, Row, Col, Dropdown, Icon, Popover, Whisper, IconButton, Notification, Tag } from 'rsuite';

import { Bugsnag, Firebase } from '../services';
import { Colors } from '../assets';

import { LoggedUserContext, OrganizationContext } from '../context';
import moment from 'moment-timezone';

const UserInfo = Styled.div({
  marginLeft: 8,
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'start',
  alignSelf: 'center',
  flexDirection: 'column',
  top: 0,
});

const InviteInfo = ({ data: invite, ...props }) => {
  const organization = useContext(OrganizationContext);
  const loggedUser = useContext(LoggedUserContext);

  const triggerRef = useRef();
  const handleSelectMenu = useCallback(() => triggerRef.current.hide(), [triggerRef]);

  const remove = useCallback(async () => {
    try {
      if (window.confirm('Are you sure you want to delete this invitation?')) {
        await Firebase.firestore()
          .collection('organizations')
          .doc(organization.id)
          .collection('private')
          .doc(organization.id)
          .collection('invites')
          .doc(invite.id)
          .update({ deletedAt: Firebase.firestore.FieldValue.serverTimestamp() });
      }
      Notification.success({
        title: 'Success',
        description: 'Invite deleted successfully',
      });
    } catch (ex) {
      Notification.error({
        title: 'There has been an error',
        description: get(ex, 'message', 'Please try again...'),
      });
      Bugsnag.notify(ex, (event) => {
        event.severity = 'warning';
      });
    }
  }, [invite.id, organization.id]);

  const resend = useCallback(async () => {
    if (window.confirm('Are you sure you want to re-send this invitation?')) {
      await Firebase.firestore()
        .collection('organizations')
        .doc(organization.id)
        .collection('private')
        .doc(organization.id)
        .collection('invites')
        .doc(invite.id)
        .update({
          deletedAt: Firebase.firestore.FieldValue.serverTimestamp(),
        });
      await new Promise((resolve) => setTimeout(resolve, 1000)); // wait 1 sec in case max capacity is reached and functions need to update
      await Firebase.firestore()
        .collection('organizations')
        .doc(organization.id)
        .collection('private')
        .doc(organization.id)
        .collection('invites')
        .doc()
        .set({
          ...omit(invite, ['id']),
          createdBy: loggedUser.id,
          createdAt: Firebase.firestore.FieldValue.serverTimestamp(),
          deletedBy: null,
          deletedAt: null,
        });
      Notification.success({
        title: 'Success',
        description: 'Invite re-sent successfully',
      });
    }
  }, [invite, loggedUser.id, organization.id]);

  const expired = useMemo(
    () => moment(invite?.createdAt?.toDate()).add(30, 'days').toDate() < new Date(Date.now()),
    [invite],
  );

  return (
    <Row gutter={16} {...props}>
      <Col xs={16} style={{ display: 'flex' }}>
        <Avatar
          circle
          size='md'
          style={{
            backgroundColor: Colors.TAGLINE,
          }}
          src={invite.photoURL}
        >
          {!invite.photoURL ? (invite.displayName || '').substring(0, 2) : null}
        </Avatar>
        <UserInfo>
          <span>{invite.displayName}</span>
          <a href={`mailto:${invite.email}`}>{invite.email}</a>
        </UserInfo>
      </Col>
      <Col
        xs={8}
        style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'row-reverse',
        }}
      >
        <div>
          <Whisper
            triggerRef={triggerRef}
            trigger='click'
            placement='bottomEnd'
            speaker={
              <Popover placement='bottomEnd' full>
                <Dropdown.Menu onSelect={handleSelectMenu}>
                  <Dropdown.Item
                    icon={<Icon icon='envelope-o' />}
                    onSelect={resend}
                    disabled={loggedUser.id === invite.id || loggedUser.role === 'Member'}
                  >
                    Re-send
                  </Dropdown.Item>
                  <Dropdown.Item
                    icon={<Icon icon='close' />}
                    disabled={invite.role === 'Owner' || loggedUser.id === invite.id || loggedUser.role === 'Member'}
                    onSelect={remove}
                  >
                    Delete
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Popover>
            }
          >
            <IconButton icon={<Icon icon='ellipsis-v' />} />
          </Whisper>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', alignContent: 'center', marginRight: 8 }}>
          <Tag color={expired ? 'red' : 'green'}>{`${expired ? 'Invitation Expired' : 'Invited'}`}</Tag>
        </div>
      </Col>
    </Row>
  );
};

export default InviteInfo;
