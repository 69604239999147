import React, { useState } from 'react';

import { BrandLayout } from '../layouts';
import { ErrorCard } from '../components';

const Welcome = () => {
  const [background, setBackground] = useState(null);
  return (
    <BrandLayout background={background}>
      <ErrorCard  setBackground={setBackground}/>
    </BrandLayout>
  );
};

export default Welcome;
