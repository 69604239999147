import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { FOCUS_COMMAND, BLUR_COMMAND, COMMAND_PRIORITY_LOW } from 'lexical';
import { useEffect } from 'react';
import { mergeRegister } from '@lexical/utils';

const UpdatePlugin = ({ onFocus, onBlur }) => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    return mergeRegister(
      editor.registerCommand(FOCUS_COMMAND, onFocus, COMMAND_PRIORITY_LOW),
      editor.registerCommand(BLUR_COMMAND, onBlur, COMMAND_PRIORITY_LOW),
    );
  }, [editor, onFocus, onBlur]);

  return null;
};

export default UpdatePlugin;
