import { useState, useContext, useEffect, useCallback } from 'react';
import { get } from 'lodash';
import { Notification } from 'rsuite';

import { Bugsnag, Firebase } from '../services';
import { LoggedUserContext, OrganizationContext } from '../context';
import { docsToArray, docsToMap, generateIdBasedOn, randomDarkColor, uuidv4 } from '../utils';
import { DEFAULT_VALUE } from '../components/RichText';

const useGoals = ({ team, parent, organizational, goalSequenceContains, filter, preloadedGoals }) => {
  const [loading, setLoading] = useState(true);
  const [goals, setGoals] = useState({ list: [], map: {} });
  const loggedUser = useContext(LoggedUserContext);
  const organization = useContext(OrganizationContext);

  useEffect(() => {
    let subscription = () => {};
    if (organization.id) {
      if (preloadedGoals) {
        setGoals({ list: preloadedGoals, map: preloadedGoals.reduce((r, g) => ({ ...r, [g.id]: g }), {}) });
        setLoading(false);
      } else {
        let query = Firebase.firestore()
          .collection('organizations')
          .doc(organization.id)
          .collection('private')
          .doc(organization.id)
          .collection('goals')
          .where('deletedAt', '==', null);

        if (team !== undefined) {
          query = query.where('team', '==', team);
        }
        if (parent !== undefined) {
          query = query.where('parent', '==', parent);
        }
        if (organizational !== undefined) {
          query = query.where('organizational', '==', organizational);
        }
        if (goalSequenceContains !== undefined) {
          query = query.where('goalSequence', 'array-contains', goalSequenceContains);
        }

        if (filter !== undefined) {
          filter.forEach((f) => {
            query = query.where(f[0], f[1], f[2]);
          });
        }

        subscription = query.onSnapshot((docs) => {
          setGoals({
            list: docsToArray(docs),
            map: docsToMap(docs),
          });
          setLoading(false);
        });
      }
    }
    return subscription;
  }, [organization.id, team, parent, goalSequenceContains, organizational, preloadedGoals, filter]);

  const create = useCallback(
    async ({ displayName, dueDate, tagColor, goalSequence = [], ...rest }) => {
      try {
        if (!displayName) return;
        let id = generateIdBasedOn(displayName);
        const doc = await Firebase.firestore()
          .collection('organizations')
          .doc(organization.id)
          .collection('private')
          .doc(organization.id)
          .collection('goals')
          .doc(id)
          .get();
        if (doc.exists) {
          id += `-${uuidv4().split('-')[0]}`;
          if (
            (
              await Firebase.firestore()
                .collection('organizations')
                .doc(organization.id)
                .collection('private')
                .doc(organization.id)
                .collection('goals')
                .doc(id)
                .get()
            ).exists
          ) {
            throw new Error('unique id already exists');
          }
        }
        await Firebase.firestore()
          .collection('organizations')
          .doc(organization.id)
          .collection('private')
          .doc(organization.id)
          .collection('goals')
          .doc(id)
          .set({
            displayName,
            dueDate,
            tagColor: tagColor || randomDarkColor(),
            createdBy: loggedUser.id,
            updatedBy: loggedUser.id,
            createdAt: Firebase.firestore.FieldValue.serverTimestamp(),
            updatedAt: Firebase.firestore.FieldValue.serverTimestamp(),
            deletedAt: null,
            archivedAt: null,
            completedAt: null,
            team: team || null,
            parent: parent || null,
            organizational: organizational || false,
            goalSequence: [...goalSequence, id],
            status: 'On Track',
            description: DEFAULT_VALUE,
            ...rest,
          });
        Notification.success({
          title: 'Success',
          description: 'Goal has been created',
        });
      } catch (ex) {
        Notification.error({
          title: 'There has been an error',
          description: get(ex, 'message', 'Please try again...'),
        });
        Bugsnag.notify(ex, (event) => {
          event.severity = 'warning';
        });
      }
    },
    [loggedUser.id, organization.id, parent, team, organizational],
  );

  return { loading, goals, create };
};

export default useGoals;
