import React, { useContext } from 'react';
import { Avatar } from 'rsuite';

import { Colors } from '../assets';
import { OrganizationUsersContext } from '../context';

const UserDisplay = ({ id, totalContribution, completionRate, showEmail, style = {}, ...props }) => {
  const { map: usersMap } = useContext(OrganizationUsersContext);

  return (
    <div style={{ display: 'flex', alignItems: 'center', marginTop: 10, marginBottom: 10, ...style }}>
      <Avatar
        circle
        size='md'
        style={{
          backgroundColor: Colors.TAGLINE,
          marginRight: 10,
          minWidth: 38,
        }}
        src={usersMap[id]?.photoURL}
      >
        {!usersMap[id]?.photoURL ? (usersMap[id]?.displayName || 'Deleted User').substring(0, 2) : null}
      </Avatar>
      <div style={{ marginRight: 4, display: 'grid' }}>
        <p
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            fontWeight: 'bold',
            display: 'block',
          }}
        >
          {usersMap[id]?.displayName || 'Deleted User'}
        </p>
        {totalContribution && (
          <span>{`${totalContribution} outcomes - ${(completionRate * 100).toFixed(2)}% completed`}</span>
        )}
        {showEmail && <span>{usersMap[id]?.email}</span>}
      </div>
    </div>
  );
};

export default UserDisplay;
