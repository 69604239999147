import React, { useCallback, useContext, useMemo, useState } from 'react';
import {
  SelectPicker,
  Icon,
  Whisper,
  Tooltip,
  Modal,
  Placeholder,
  Button,
  Input,
  InputGroup,
  IconButton,
} from 'rsuite';
import Styled from 'styled-components';
import { get } from 'lodash';
import { useGoals } from '../hooks';
import { unGroupGoalsWithParentsWithRenames, groupGoalsWithParents } from '../utils';
import { Colors } from '../assets';
import GoalDisplay from './GoalDisplay';
import { GoalSelectPickerItem } from '.';
import { useHistory, useLocation } from 'react-router-dom';
import { OrganizationContext, TeamContext } from '../context';

const Container = Styled.div({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
});

const DeleteContainer = Styled.div({
  backgroundColor: Colors.WHITE,
  borderColor: Colors.RED,
  borderWidth: 2,
  borderStyle: 'solid',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginTop: 10,
  padding: 10,
  borderRadius: 4,
  cursor: 'pointer',
  position: 'relative',
  textAlign: 'center',
  justifyContent: 'center',
  color: Colors.RED,
  fontWeight: 'bold',
  transitionProperty: 'background-color, color',
  transitionDuration: '.2s',
  ':hover': {
    backgroundColor: Colors.RED,
    color: Colors.WHITE,
  },
});

const GoalSelectPicker = ({
  team,
  parent,
  organizational,
  goalSequenceContains,
  value,
  small,
  preloadedGoals,
  placeholder,
  onSelect,
  onClose,
  ...props
}) => {
  const history = useHistory();
  const location = useLocation();
  const organization = useContext(OrganizationContext);
  const teamCtx = useContext(TeamContext);
  const [isOpen, setIsOpen] = useState(false);
  const [search, setSearch] = useState('');
  const { loading, goals } = useGoals({ team, parent, organizational, goalSequenceContains, preloadedGoals });
  const goalOptions = useMemo(
    () => [
      ...groupGoalsWithParents(
        goals?.list.filter((g) => (g.displayName || '').toLowerCase().search(search.toLowerCase()) >= 0),
      ),
    ],
    [goals.list, search],
  );
  const close = useCallback(() => {
    setIsOpen(false);
    setTimeout(() => setSearch(''), 300);
    if (onClose) {
      onClose();
    }
  }, [onClose]);

  const goToOrganizationGoals = useCallback(() => {
    const path = `/${organization.id}/goals`;
    if (path !== location.pathname) {
      history.push(path);
    } else {
      close();
    }
  }, [organization.id, history, location, close]);

  const goToTeamObjectives = useCallback(() => {
    const path = `/${organization.id}/team/${teamCtx.id}/objectives`;
    if (path !== location.pathname) {
      history.push(path);
    } else {
      close();
    }
  }, [organization.id, teamCtx, history, location, close]);

  return (
    <>
      <Whisper
        placement={'top'}
        speaker={value && small ? <Tooltip>{goals?.map[value]?.displayName || value}</Tooltip> : <div />}
      >
        {!value ? (
          <Button {...props} onClick={() => setIsOpen(true)}>
            {placeholder}
          </Button>
        ) : !small ? (
          <Container>
            <Icon icon='circle' style={{ color: get(goals?.map[value], 'tagColor'), marginRight: 4 }} />
            {get(goals?.map[value], 'label')}
          </Container>
        ) : (
          <IconButton
            {...props}
            icon={<Icon icon='circle' style={{ color: get(goals?.map[value], 'tagColor') }} />}
            onClick={() => setIsOpen(true)}
          />
        )}
      </Whisper>
      <Modal show={isOpen} onHide={close}>
        <Modal.Header>
          <Modal.Title>Select a Goal</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading && (
            <>
              <Placeholder.Paragraph />
              <Placeholder.Paragraph />
              <Placeholder.Paragraph />
              <Placeholder.Paragraph />
            </>
          )}
          {!loading && (
            <>
              <div
                style={{
                  position: 'fixed',
                  top: 60,
                  width: '94%',
                  backgroundColor: Colors.WHITE,
                  zIndex: 100,
                  padding: 4,
                  paddingBottom: 8,
                  paddingLeft: 0,
                }}
              >
                <InputGroup inside>
                  <Input placeholder='search' value={search} onChange={setSearch} />
                  <InputGroup.Button>
                    <Icon icon='search' />
                  </InputGroup.Button>
                </InputGroup>
              </div>
              <div>
                <div style={{ paddingTop: 30 }}>
                  {value && (
                    <DeleteContainer
                      onClick={() => {
                        onSelect(null);
                        close();
                      }}
                    >
                      Clear
                    </DeleteContainer>
                  )}
                  {goalOptions.length > 0 ? (
                    goalOptions.map((g) => (
                      <GoalSelectPickerItem
                        goal={g}
                        onSelect={(id) => {
                          onSelect(id);
                          close();
                        }}
                        selectedGoal={goals?.map[value]}
                      />
                    ))
                  ) : (
                    <>
                      <p style={{ textAlign: 'center', paddingTop: 40, fontWeight: 'bold' }}>No Results</p>
                      {organizational && (
                        <p style={{ textAlign: 'center' }}>
                          To create your first Goal{' '}
                          <a style={{ cursor: 'pointer' }} onClick={goToOrganizationGoals}>
                            click here
                          </a>
                        </p>
                      )}
                      {!organizational && (
                        <p style={{ textAlign: 'center' }}>
                          To assign an Organization Goal to this team{' '}
                          <a style={{ cursor: 'pointer' }} onClick={goToOrganizationGoals}>
                            click here
                          </a>
                          <br />
                          To create your first Team Objective{' '}
                          <a style={{ cursor: 'pointer' }} onClick={goToTeamObjectives}>
                            click here
                          </a>
                        </p>
                      )}
                    </>
                  )}
                </div>
              </div>
            </>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default GoalSelectPicker;
