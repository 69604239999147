import React, { useContext } from 'react';
import { Row, Col, Panel, Avatar, Icon } from 'rsuite';
import Styled from 'styled-components';

import { OrganizationContext } from '../context';
import { Colors } from '../assets';

const Container = Styled.div({
  margin: 30,
});

const Title = Styled.h2({
  marginBottom: 30,
});

const ContainerPanel = Styled(Panel)({
  backgroundColor: Colors.WHITE,
});

const Content = [
  {
    title: 'Definitions',
    content: [
      {
        icon: 'target',
        title: 'What is an Organization Goal?',
        description:
          'An Organization Goal is a target the company wants to achieve, this if commonly set by company leadership to define what the company will look like in the future.',
      },
      {
        icon: 'user',
        title: 'What is a User?',
        description:
          'A User is a member of the organization, that has access to all organization goals and specific teams. There are 3 roles available right now (Owner / Admin / Member).',
      },
      {
        icon: 'group',
        title: 'What is a Team?',
        description:
          'A Team is a group of users that focus on an specific subject, these are composed of leaders & members and contain group specific analytics / objectives / outcomes.',
      },
      {
        icon: 'people-group',
        title: 'What is a Team Member?',
        description:
          'A Team Member is a User that belongs to an specific team, either as a Leader or a Member. Note that users can belong to multiple teams and Organization Admins have access to all Teams by default.',
      },
      {
        icon: 'bullseye',
        title: 'What is a Team Objective?',
        description:
          'A Team Objective is either an Organization Goal/Sub-Goal/OKR that is assigned to an specific team or a Goal specifically created for the team (non organizational - will not show/aggregate as an Organization Goal).',
      },
      {
        icon: 'task',
        title: 'What is an Outcome?',
        description:
          'An Outcome is either a pending or completed task for an specific day of work, this is commonly filled with updates given during daily meetings and can have goals assigned to aggregate to company analytics.',
      },
      {
        icon: 'task',
        title: 'What are Sub-Goals/OKRs?',
        description:
          'A Sub-Goal/OKR is any Goal/Objective that was created having a Parent Goal. This can be done on the Organization Goal / Team Objective / Goal Details Screens.',
      },
      {
        icon: 'task',
        title: 'What is a Team Space?',
        description:
          "A Team Space is basically the new menu/sections that appear once you tap on the Blue Arrow Button on the Teams Screen. Here you'll find Team Analytics/Objectives/Outcomes specifically for that team.",
      },
    ],
  },
  {
    title: 'How to',
    content: [
      {
        icon: 'target',
        title: 'Create an Organization Goal',
        description:
          'To Create an Organization Goal you can go to the Goals Screen, at this point there should be an input at the bottom saying "New Goal", you write a brief description of it, add a due date if needed and click on "Add Goal". The Newly created Goal should appear on the list soon after.',
      },
      {
        icon: 'user',
        title: 'Create a User',
        description:
          'To Create a User you can go to the Users Screen, tap on "Add User", add a Name / Email / Role, then tap "Save". This will send an email to that user, so they can complete the registration process and set a password to login into the platform, this invitation will expire in 30 days if not used.',
      },
      {
        icon: 'group',
        title: 'Create a Team',
        description:
          'To Create a Team you can go to the Teams Screen, tap on "Add Team", add a Name / Leader, then tap "Save". The team should appear on the list soon after, if you click on the Blue Arrow Button, it should take you to the Team Space.',
      },
      {
        icon: 'people-group',
        title: 'Add Members to a Team',
        description:
          'To Add Members to a Team, you can do it on the Teams Screen, by editing with one of the team items, you can also do it on the Team Space > Configuration or Team Space > Members.',
      },
      {
        icon: 'bullseye',
        title: 'Create a Team Objective',
        description:
          'To Create a Team Objective you can either assign one of the already created organization goals to a Team or go into the Team Space > Objectives, Tap on New Objective and write a brief description of it, afterwards you can click on Add Objective to finish the process.',
      },
      {
        icon: 'task',
        title: 'Create Outcomes',
        description:
          'To Create an Outcome you can need to go to a Team Space > Outcomes and if you are a member of the team, you can add items to your card and even assign a Goal to the specific outcome you are creating. This outcome can be completed by tapping the checkbox and aggregates to all the analytics on the system.',
      },
      {
        icon: 'target',
        title: 'Create Sub-Goals/OKRs',
        description:
          'To Create a Sub-Goal/OKRs you can do it on the Organization Goal / Team Objectives Screen and add it by selecting a Parent Goal on it, this will create this new goal under the parent one. You can also do it under the Goal Screen > Sub-Goals and create it directly there without having to specify the Parent Goal.',
      },
      {
        icon: 'cubes',
        title: 'Use Jira Integration',
        description:
          'Once you have configured the Jira Integration with a Host / User / API Key on Integrations, you just need to use the following syntax on Outcomes / Goal Descriptions / Goal Updates. Ex: [TEST-100]',
      },
      {
        icon: 'cogs',
        title: 'Change Goal Progress Tracking',
        description:
          'On the Goal Screen > Advance Settings, you can change the way progress is tracked for that specific Goal. A Goal can be tracked by Goal Updates, Achieved Amount, Achieved Milestone and Calculated by Sub-Goals.',
      },
    ],
  },
  {
    title: 'Frequently Asked Questions',
    content: [
      {
        icon: 'question',
        title: 'Considerations on Active User',
        description:
          'An Active User is an User that is not Deleted or Disabled. An Active User also Includes Pending/Expired Invitations (Given that it reserves a spot for them and you can re-invite them).',
      },
      {
        icon: 'question',
        title: 'Considerations on cancelling a subscription',
        description:
          'All extra User Slots that surpass the Free Tier (5 Users) will get disabled, starting from invitations -> users -> admins, until the user active count is no longer exceeded.',
      },
      {
        icon: 'question',
        title: 'What if i need more help?',
        description:
          'On the bottom right there is a blue button in which you can contact us to Ask for Help / Give us Feedback / Request a Feature or Report a Bug. You can also contact us at support@intry.io',
      },
    ],
  },
];

const Guide = () => {
  const organization = useContext(OrganizationContext);

  return (
    <Container>
      <Row gutter={16}>
        <Col xs={16}>
          <Title>Guide</Title>
        </Col>
      </Row>
      {Content.map((contentGroup, i) => (
        <Row gutter={16}>
          <Col xs={24} style={{ marginBottom: 20, marginTop: i ? 20 : 0 }}>
            <ContainerPanel>
              <h4>{contentGroup.title}</h4>
            </ContainerPanel>
          </Col>
          {contentGroup.content.map((c) => (
            <Col lg={6} md={12} xs={24} style={{ marginBottom: 20 }}>
              <ContainerPanel style={{ minHeight: 300 }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Avatar style={{ background: Colors.ACCENT, color: Colors.WHITE }} size='sm'>
                    <Icon icon={c.icon} />
                  </Avatar>
                  <h5 style={{ marginLeft: 10 }}>{c.title}</h5>
                </div>
                <br />
                <p>{c.description}</p>
              </ContainerPanel>
            </Col>
          ))}
        </Row>
      ))}
    </Container>
  );
};

export default Guide;
