import React, { useState, useCallback, useContext, useRef } from 'react';
import { get, includes } from 'lodash';
import { Form, Button, Notification, Schema, FlexboxGrid, Toggle } from 'rsuite';

import { Bugsnag, Firebase, Mixpanel } from '../services';
import { LoggedUserContext, OrganizationContext, TeamMembersContext } from '../context';
import { FormGroup, UserTagPicker } from '../components';
import { generateIdBasedOn, uuidv4 } from '../utils';
const { StringType, BooleanType, ArrayType } = Schema.Types;

const teamFormModel = Schema.Model({
  displayName: StringType()
    .minLength(4, 'The field cannot be less than 4 characters')
    .isRequired('This field is required'),
  private: BooleanType().isRequired('This field is required'),
  leaders: ArrayType().isRequired('This field is required').minLength(1, 'Choose at least one'),
});

const TeamForm = ({ data: team, isNew, onSave }) => {
  const [saving, setSaving] = useState(false);
  const loggedUser = useContext(LoggedUserContext);
  const organization = useContext(OrganizationContext);
  const teamMembers = useContext(TeamMembersContext);
  const teamFormRef = useRef(null);
  const [teamFormValue, setTeamFormValue] = useState(
    isNew
      ? {
          private: false,
          leaders: [],
          members: [],
          description: null,
        }
      : {
          photoURL: team.photoURL || '',
          displayName: team.displayName,
          description: team.description || null,
          private: team.private,
          leaders: Object.keys(team.leaders),
          members: Object.keys(team.members),
        },
  );
  const [teamFormError, setTeamFormError] = useState({});

  const save = useCallback(async () => {
    setSaving(true);
    try {
      if (isNew) {
        let id = generateIdBasedOn(teamFormValue.displayName);
        const doc = await Firebase.firestore()
          .collection('organizations')
          .doc(organization.id)
          .collection('private')
          .doc(organization.id)
          .collection('teams')
          .doc(id)
          .get();
        if (doc.exists) {
          id += `-${uuidv4().split('-')[0]}`;
          if (
            (
              await Firebase.firestore()
                .collection('organizations')
                .doc(organization.id)
                .collection('private')
                .doc(organization.id)
                .collection('teams')
                .doc(id)
                .get()
            ).exists
          ) {
            throw new Error('unique id already exists');
          }
        }
        await Firebase.firestore()
          .collection('organizations')
          .doc(organization.id)
          .collection('private')
          .doc(organization.id)
          .collection('teams')
          .doc(id)
          .set({
            ...teamFormValue,
            leaders: (teamFormValue.leaders || []).reduce((r, u) => ({ ...r, [u]: true }), {}),
            members: (teamFormValue.members || []).reduce((r, u) => ({ ...r, [u]: true }), {}),
            createdBy: loggedUser.id,
            updatedBy: loggedUser.id,
            deletedBy: null,
            createdAt: Firebase.firestore.FieldValue.serverTimestamp(),
            updatedAt: Firebase.firestore.FieldValue.serverTimestamp(),
            deletedAt: null,
          });
          Mixpanel.track('Created Team');
          Mixpanel.people.increment("Teams Created");
      } else {
        await Firebase.firestore()
          .collection('organizations')
          .doc(organization.id)
          .collection('private')
          .doc(organization.id)
          .collection('teams')
          .doc(team.id)
          .update({
            ...teamFormValue,
            leaders: (teamFormValue.leaders || []).reduce((r, u) => ({ ...r, [u]: true }), {}),
            members: (teamFormValue.members || []).reduce((r, u) => ({ ...r, [u]: true }), {}),
            updatedBy: loggedUser.id,
            updatedAt: Firebase.firestore.FieldValue.serverTimestamp(),
          });
          Mixpanel.track('Updated Team');
      }
      if (onSave) onSave();
    } catch (ex) {
      Notification.error({
        title: 'There has been an error',
        description: get(ex, 'message', 'Please try again...'),
      });
      Bugsnag.notify(ex, (event) => {
        event.severity = 'warning';
      });
    }
    setSaving(false);
  }, [isNew, team, loggedUser.id, onSave, teamFormValue, organization]);

  return (
    <Form
      fluid
      key='teamForm'
      ref={teamFormRef}
      onChange={setTeamFormValue}
      onCheck={setTeamFormError}
      formValue={teamFormValue}
      model={teamFormModel}
    >
      <FormGroup image label='Photo' name='photoURL' />
      <FormGroup
        label='Name'
        name='displayName'
        error={!!teamFormError.displayName}
        errorMessage={teamFormError.displayName}
        disabled={saving}
      />
      <FormGroup
        label='Description'
        name='description'
        componentClass='textarea'
        rows={3}
        error={!!teamFormError.description}
        errorMessage={teamFormError.description}
        disabled={saving}
      />
      <FormGroup
        label='Private'
        name='private'
        hint='Private teams are only visible to Owners, Admins of the organization and Leaders, Members of the team'
        error={!!teamFormError.private}
        errorMessage={teamFormError.private}
        disabled={saving}
        accepter={Toggle}
        checked={teamFormValue.private}
        block
      />
      <FormGroup
        label='Leaders'
        name='leaders'
        error={!!teamFormError.leaders}
        errorMessage={teamFormError.leaders}
        disabled={saving}
        accepter={UserTagPicker}
        disabledItemValues={teamFormValue.members}
        block
      />
      <FormGroup
        label='Members'
        name='members'
        error={!!teamFormError.members}
        errorMessage={teamFormError.members}
        disabled={saving}
        accepter={UserTagPicker}
        disabledItemValues={teamFormValue.leaders}
        maxHeight={80}
        block
      />
      <FlexboxGrid justify='end'>
        <FlexboxGrid.Item colspan={4}>
          <Button
            appearance='primary'
            block
            disabled={
              !teamFormValue.displayName ||
              teamFormError.displayName ||
              teamFormValue.private === undefined ||
              teamFormValue.private === null ||
              teamFormError.private ||
              teamFormValue.leaders === undefined ||
              teamFormValue.leaders === null ||
              teamFormValue.leaders.length === 0 ||
              teamFormError.leaders ||
              (loggedUser.role === 'Member' &&
                includes(
                  ['', 'Member'],
                  get(
                    get(teamMembers, 'map', {
                      [loggedUser.id]: {
                        teamRole: get(team, `leaders.${loggedUser.id}`, false) ? 'Leader' : '',
                      },
                    }),
                    `${loggedUser.id}.teamRole`,
                    '',
                  ),
                ))
            }
            loading={saving}
            onClick={save}
          >
            Save
          </Button>
        </FlexboxGrid.Item>
      </FlexboxGrid>
    </Form>
  );
};

export default TeamForm;
