import React, { useState } from 'react';
import { Row, Col, Panel, Tag, IconButton, Icon, Animation } from 'rsuite';
import Styled from 'styled-components';

import { Colors, Slack, Jira } from '../assets';
import { JiraIntegrationForm } from '../components';

const Container = Styled.div({
  margin: 30,
});

const Title = Styled.h2({
  marginBottom: 30,
});

const ContainerPanel = Styled(Panel)({
  backgroundColor: Colors.WHITE,
  marginBottom: 20,
});

const ContainerData = Styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  justifyItems: 'center',
  alignItems: 'center',
});

const OrganizationIntegrations = () => {
  const [showJiraForm, setShowJiraForm] = useState(false);
  return (
    <Container>
      <Row gutter={16}>
        <Col xs={16}>
          <Title>Integrations</Title>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={24}>
          <ContainerPanel>
            <ContainerData>
              <img src={Slack} style={{ height: 60, marginRight: 20 }} />
              <div>
                <Tag color='violet'>Soon</Tag>
              </div>
            </ContainerData>
          </ContainerPanel>
        </Col>
        <Col xs={24}>
          <ContainerPanel>
            <ContainerData>
              <img src={Jira} style={{ height: 60, marginRight: 20 }} />
              <div>
                <IconButton
                  appearance='default'
                  onClick={() => setShowJiraForm(!showJiraForm)}
                  icon={<Icon icon={`caret-${showJiraForm ? 'up' : 'down'}`} />}
                />
              </div>
            </ContainerData>
            <Animation.Collapse in={showJiraForm}>
              <div>
                <JiraIntegrationForm />
              </div>
            </Animation.Collapse>
          </ContainerPanel>
        </Col>
      </Row>
    </Container>
  );
};

export default OrganizationIntegrations;
