import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import {
  KEY_ENTER_COMMAND,
  KEY_ARROW_DOWN_COMMAND,
  KEY_ARROW_LEFT_COMMAND,
  KEY_ARROW_RIGHT_COMMAND,
  KEY_ARROW_UP_COMMAND,
  KEY_BACKSPACE_COMMAND,
  KEY_DELETE_COMMAND,
  KEY_ESCAPE_COMMAND,
  KEY_MODIFIER_COMMAND,
  KEY_SPACE_COMMAND,
  KEY_TAB_COMMAND,
  COMMAND_PRIORITY_LOW,
} from 'lexical';
import { useEffect } from 'react';
import { mergeRegister } from '@lexical/utils';

const OnKeyPressPlugin = ({ onKeyPress }) => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    return mergeRegister(
      editor.registerCommand(KEY_ENTER_COMMAND, () => onKeyPress('Enter'), COMMAND_PRIORITY_LOW),
      editor.registerCommand(KEY_ARROW_DOWN_COMMAND, () => onKeyPress('Arrow Down'), COMMAND_PRIORITY_LOW),
      editor.registerCommand(KEY_ARROW_LEFT_COMMAND, () => onKeyPress('Arrow Left'), COMMAND_PRIORITY_LOW),
      editor.registerCommand(KEY_ARROW_RIGHT_COMMAND, () => onKeyPress('Arrow Right'), COMMAND_PRIORITY_LOW),
      editor.registerCommand(KEY_ARROW_UP_COMMAND, () => onKeyPress('Arrow Up'), COMMAND_PRIORITY_LOW),
      editor.registerCommand(KEY_BACKSPACE_COMMAND, () => onKeyPress('Backspace'), COMMAND_PRIORITY_LOW),
      editor.registerCommand(KEY_DELETE_COMMAND, () => onKeyPress('Delete'), COMMAND_PRIORITY_LOW),
      editor.registerCommand(KEY_ESCAPE_COMMAND, () => onKeyPress('Scape'), COMMAND_PRIORITY_LOW),
      editor.registerCommand(KEY_MODIFIER_COMMAND, () => onKeyPress('Modified'), COMMAND_PRIORITY_LOW),
      editor.registerCommand(KEY_SPACE_COMMAND, () => onKeyPress('Space'), COMMAND_PRIORITY_LOW),
      editor.registerCommand(KEY_TAB_COMMAND, () => onKeyPress('Tab'), COMMAND_PRIORITY_LOW),
    );
  }, [editor, onKeyPress]);

  return null;
};

export default OnKeyPressPlugin;
