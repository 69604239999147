import React, { useContext, useState, useMemo, useCallback, useEffect } from 'react';
import {
  Row,
  Col,
  PanelGroup,
  Panel,
  InputGroup,
  Icon,
  Input,
  Button,
  Modal,
  Loader,
  InputPicker,
  CheckboxGroup,
  Checkbox,
  DatePicker,
  Placeholder,
  Notification,
  Nav,
  Progress,
  Breadcrumb,
} from 'rsuite';
import { useHistory } from 'react-router-dom';
import Styled from 'styled-components';
import { get, groupBy, includes, orderBy, last, debounce, isEqual } from 'lodash';
import moment from 'moment-timezone';

import { LoggedUserContext, OrganizationContext } from '../context';
import { Colors, Achievement as AchievementIcon } from '../assets';
import {
  AutogrowInput,
  GoalDescription,
  GoalFiles,
  GoalDetails,
  GoalUpdates,
  GoalOutcomes,
  GoalAnalytics,
  GoalSubGoals,
  GoalProgressSetting,
} from '../components';
import { Bugsnag, Firebase, Mixpanel } from '../services';
import { docsToArray, generateIdBasedOn, randomDarkColor, uuidv4 } from '../utils';
import { useGoals, useGoalSetting } from '../hooks';

const Container = Styled.div({
  padding: 30,
});

const Title = Styled.h2({});

const ContainerPanel = Styled(Panel)({
  backgroundColor: Colors.WHITE,
});

const OrganizationGoal = ({ setExpand }) => {
  const history = useHistory();
  const loggedUser = useContext(LoggedUserContext);
  const organization = useContext(OrganizationContext);
  const location = get(window, 'location.pathname', '');
  const teamId = location.includes('/objective/') ? location.split('/')[location.split('/').length - 3] : null;
  const goalId = last(location.split('/'));
  const [goal, setGoal] = useState(null);
  const [displayName, setDisplayName] = useState(goal?.displayName);
  const [isInputFocused, setIsInputFocused] = useState(false);
  const {
    goals: { map: goalsMap },
  } = useGoals({ goalSequenceContains: goal?.goalSequence[0] || goalId });
  const { value: progressSetting } = useGoalSetting({ goalId, setting: 'progress' });
  const [loading, setLoading] = useState(true);
  const [section, setSection] = useState('details');

  useEffect(() => {
    if (!isInputFocused) {
      setDisplayName(goal?.displayName);
    }
    return () => {};
  }, [goal]);

  const updateFromProps = useCallback(async () => {
    if (!isEqual(displayName, goal?.displayName)) {
      setDisplayName(goal?.displayName);
    }
  }, [displayName, goal]);

  useEffect(() => {
    let subscription;
    if (organization.id) {
      subscription = Firebase.firestore()
        .collection('organizations')
        .doc(organization.id)
        .collection('private')
        .doc(organization.id)
        .collection('goals')
        .doc(goalId)
        .onSnapshot(
          (doc) => {
            if (doc.exists && !doc.data().deletedAt) {
              setGoal({ ...doc.data(), id: doc.id });
              setLoading(false);
            } else {
              history.push(`/${organization.id}/goals`);
            }
          },
          (error) => history.push(`/${organization.id}/goals`),
        );
    }
    return () => {
      subscription && subscription();
    };
  }, [organization.id, goalId]);

  const update = useCallback(
    async (nGoal) => {
      try {
        if (!nGoal.displayName) return;

        await Firebase.firestore()
          .collection('organizations')
          .doc(organization.id)
          .collection('private')
          .doc(organization.id)
          .collection('goals')
          .doc(nGoal.id)
          .update({
            ...nGoal,
            updatedBy: loggedUser.id,
            updatedAt: Firebase.firestore.FieldValue.serverTimestamp(),
          });
        Mixpanel.track('Updated Goal');
      } catch (ex) {
        Notification.error({
          title: 'There has been an error',
          description: get(ex, 'message', 'Please try again...'),
        });
        Bugsnag.notify(ex, (event) => {
          event.severity = 'warning';
        });
      }
    },
    [organization.id, loggedUser.id],
  );

  const updateWithDebounce = useCallback(debounce(update, 500), [update]);

  return (
    <Container>
      <Row gutter={16}>
        <Col xs={24} style={{ marginBottom: 10 }}>
          <Title>Goal</Title>
          <Breadcrumb style={{ paddingLeft: 2 }}>
            {goal &&
              goal.goalSequence.length > 1 &&
              goal.goalSequence.map((g) => (
                <Breadcrumb.Item active={g === goal.id} onClick={() => history.push(g)}>
                  {goalsMap[g]?.displayName}
                </Breadcrumb.Item>
              ))}
          </Breadcrumb>
        </Col>
      </Row>
      <Row gutter={16} style={{ marginBottom: 20 }}>
        <Col xs={24}>
          <ContainerPanel
            style={{
              borderLeft: `2px solid ${get(goal, 'tagColor', 'white')}`,
              borderRight: `2px solid ${get(goal, 'tagColor', 'white')}`,
              paddingBottom: 20,
            }}
          >
            <Col xs={12}>
              <AutogrowInput
                value={loading ? '...' : displayName}
                onChange={(displayName) => {
                  setDisplayName(displayName);
                  updateWithDebounce({ ...goal, displayName });
                }}
                onFocus={() => setIsInputFocused(true)}
                onBlur={() => {
                  setIsInputFocused(false);
                  updateFromProps();
                }}
                style={{
                  fontSize: 22,
                  fontWeight: 600,
                  paddingTop: 0,
                  borderColor: 'transparent',
                  paddingLeft: 0,
                  paddingBottom: 0,
                  ':focus': {
                    borderColor: 'transparent',
                  },
                  ':hover': {
                    borderColor: 'transparent',
                  },
                }}
              />
            </Col>

            <Col xs={12}>
              <Progress.Line
                percent={get(goal, 'progress', 0)}
                status={Number(get(goal, 'progress', 0)) === 100 ? 'success' : ''}
              />
            </Col>
          </ContainerPanel>
        </Col>
      </Row>
      <Row gutter={16} style={{ marginBottom: 20 }}>
        <Col md={24} lg={24}>
          <ContainerPanel>
            <Nav
              activeKey={section}
              onSelect={(sec) => {
                setSection(sec);
                Mixpanel.track('Visited Goal Section', { section: sec });
              }}
            >
              <Nav.Item eventKey='details' icon={<Icon icon='detail' />}>
                Details
              </Nav.Item>
              {/* <Nav.Item eventKey='milestones' icon={<Icon icon='tasks' />}>
                Milestones
              </Nav.Item> */}
              <Nav.Item eventKey='sub-goals' icon={<Icon icon='align-right' />}>
                Sub-goals
              </Nav.Item>
              <Nav.Item eventKey='outcomes' icon={<Icon icon='task' />}>
                Outcomes
              </Nav.Item>
              <Nav.Item eventKey='analytics' icon={<Icon icon='bar-chart' />}>
                Analytics
              </Nav.Item>
              {/* <Nav.Item eventKey='history' icon={<Icon icon='history' />}>
                History (soon)
              </Nav.Item> */}
              <Nav.Item eventKey='advanced' icon={<Icon icon='gears2' />}>
                Advanced Settings
              </Nav.Item>
            </Nav>
          </ContainerPanel>
        </Col>
      </Row>
      <Row gutter={16} style={{ marginBottom: 20 }}>
        {section === 'details' && (
          <Col md={24} lg={16}>
            <GoalDescription id={goal?.id} data={goal?.description} />
            <GoalUpdates data={goal} settings={{ progress: progressSetting }} />
          </Col>
        )}
        {section === 'details' && (
          <Col md={24} lg={8}>
            <GoalDetails data={goal} />
            <GoalFiles data={goal} />
          </Col>
        )}
        {section === 'outcomes' && (
          <Col md={24} lg={24}>
            <GoalOutcomes data={goal} />
          </Col>
        )}
        {section === 'analytics' && (
          <Col md={24} lg={24}>
            <GoalAnalytics data={goal} />
          </Col>
        )}
        {section === 'sub-goals' && (
          <Col md={24} lg={24}>
            <GoalSubGoals data={goal} onSelect={() => setSection('details')} teamId={teamId} />
          </Col>
        )}
        {section === 'advanced' && (
          <Col md={24} lg={24}>
            <GoalProgressSetting data={goal} />
          </Col>
        )}
      </Row>
    </Container>
  );
};

export default OrganizationGoal;
