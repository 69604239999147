import React, { useState, useCallback, useEffect, useMemo } from 'react';
import Styled from 'styled-components';
import { Row, Col, DatePicker, Icon, Panel, Dropdown } from 'rsuite';
import moment from 'moment-timezone';

import { Colors } from '../assets';
import { isMobile } from 'react-device-detect';

const ContainerPanel = Styled(Panel)({
  backgroundColor: Colors.WHITE,
  zIndex: 4,
  overflow: 'visible',
});

const DatePickerContainer = Styled.div({
  position: 'absolute',
  opacity: 0,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
});

const AdvanceDatePickerContainer = Styled.div({
  position: 'absolute',
  opacity: 0,
  top: '50%',
  right: 0,
  transform: 'translate(-50%, -50%)',
  maxWidth: 100,
});

const BoldDropdown = Styled(Dropdown)({
  a: {
    fontWeight: 'bold',
    color: '#575757',
  },
});

export const FILTER_BY = {
  day: {
    name: 'day',
    format: 'yyyy-MM-DD',
    formatOnDisplay: 'LL',
    display: 'Filter by Day',
    subtract: 'days',
  },
  week: {
    name: 'week',
    format: 'yyyy-[W]WW',
    display: 'Filter by Week',
    formatOnDisplay: 'yyyy-[W]WW',
    subtract: 'weeks',
  },
  month: {
    name: 'month',
    format: 'yyyy-MM',
    display: 'Filter by Month',
    formatOnDisplay: 'MMMM, yyyy',
    subtract: 'months',
  },
  quarter: {
    name: 'quarter',
    format: 'yyyy-[Q]Q',
    display: 'Filter by Quarter',
    formatOnDisplay: '[Q]Q, yyyy',
    subtract: 'quarters',
  },
  year: { name: 'year', format: 'yyyy', display: 'Filter by Year', formatOnDisplay: 'yyyy', subtract: 'years' },
  all: { name: 'all', format: '[ALL]', display: 'Show All Time' },
};

const LAST_N = 10;

const DateSelector = ({ style = {}, onChange = () => {}, value, advanced, justifyContent, ...props }) => {
  const [calendar, setCalendar] = useState({
    date: value || moment().format('YYYY-MM-DD'),
    filter: FILTER_BY.day.name,
  });

  useEffect(() => {
    onChange({ ...calendar, format: FILTER_BY[calendar.filter].format });
  }, [calendar]);

  const onChangeDate = useCallback(
    (newDate) => setCalendar((cal) => ({ ...cal, date: moment(newDate).format(FILTER_BY[calendar.filter].format) })),
    [calendar],
  );

  const changeDateWith = useCallback(
    (n) =>
      setCalendar((cal) => ({
        ...cal,
        date: moment(calendar.date, FILTER_BY[calendar.filter].format)
          [n > 0 ? 'add' : 'subtract'](Math.abs(n), FILTER_BY[calendar.filter].subtract)
          .format(FILTER_BY[calendar.filter].format),
      })),
    [calendar],
  );

  const startAndEndOfWeek = useMemo(
    () =>
      calendar.filter === FILTER_BY?.week?.name
        ? { start: moment(calendar.date).startOf('week'), end: moment(calendar.date).clone().endOf('week') }
        : null,
    [calendar],
  );

  const lastWeeks = useMemo(() => {
    let current = moment();
    const weeks = [];
    for (let n = 0; n < LAST_N; n++) {
      let startOfWeek = current.clone().startOf('week');
      let endOfWeek = current.clone().endOf('week');
      weeks.push({
        key: current.format(FILTER_BY.week.format),
        name: `${startOfWeek.format(FILTER_BY.day.formatOnDisplay)} - ${endOfWeek.format(
          FILTER_BY.day.formatOnDisplay,
        )}`,
      });
      current = current.subtract(7, 'days');
    }
    return weeks;
  }, []);

  const lastMonths = useMemo(() => {
    let current = moment();
    const months = [];
    for (let n = 0; n < LAST_N; n++) {
      months.push({
        key: current.format(FILTER_BY.month.format),
        name: `${current.format(FILTER_BY.month.formatOnDisplay)}`,
      });
      current = current.subtract(1, 'months');
    }
    return months;
  }, []);

  const lastQuarters = useMemo(() => {
    let current = moment();
    const quarters = [];
    for (let n = 0; n < LAST_N; n++) {
      quarters.push({
        key: current.format(FILTER_BY.quarter.format),
        name: `${current.format(FILTER_BY.quarter.formatOnDisplay)}`,
      });
      current = current.subtract(1, 'quarters');
    }
    return quarters;
  }, []);

  const lastYears = useMemo(() => {
    let current = moment();
    const years = [];
    for (let n = 0; n < LAST_N; n++) {
      years.push({
        key: current.format(FILTER_BY.year.format),
        name: `${current.format(FILTER_BY.year.format)}`,
      });
      current = current.subtract(1, 'years');
    }
    return years;
  }, []);

  if (!advanced) {
    return (
      <ContainerPanel style={style}>
        <Row gutter={24}>
          <Col xs='4' style={{ cursor: 'pointer' }} onClick={() => changeDateWith(-1)}>
            <Icon icon='left' />
          </Col>
          <Col xs='16' style={{ textAlign: 'center', cursor: 'pointer' }}>
            <DatePickerContainer>
              <DatePicker
                appearance='subtle'
                placeholder='Subtle'
                value={calendar.date}
                onChange={onChangeDate}
                cleanable={false}
                placement='bottom'
                oneTap
                {...props}
              />
            </DatePickerContainer>
            <strong>{moment(calendar.date).format('LL')}</strong>
          </Col>
          <Col xs='4' style={{ textAlign: 'right', cursor: 'pointer' }} onClick={() => changeDateWith(+1)}>
            <Icon icon='right' />
          </Col>
        </Row>
      </ContainerPanel>
    );
  }

  return (
    <ContainerPanel style={style}>
      <Row gutter={24} style={{ cursor: 'pointer', display: 'flex', ...(isMobile ? { flexDirection: 'column', textAlign: 'center'} : {}) }}>
        <Col>
          <Dropdown title={FILTER_BY[calendar.filter].display}>
            {Object.keys(FILTER_BY).map((k) => (
              <Dropdown.Item
                value={k}
                active={k === calendar.filter}
                onSelect={() =>
                  setCalendar((c) => ({
                    filter: k,
                    date:
                      c.filter === FILTER_BY.all.name
                        ? moment(Date.now()).format(FILTER_BY[k].format)
                        : moment(calendar.date, FILTER_BY[c.filter].format).format(FILTER_BY[k].format),
                  }))
                }
              >
                {FILTER_BY[k].display}
              </Dropdown.Item>
            ))}
          </Dropdown>
        </Col>
        {calendar.filter !== FILTER_BY?.all?.name && (
          <Col style={{ flex: 1, marginLeft: -12 }}>
            <div
              style={{
                cursor: 'pointer',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: justifyContent || 'center',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  cursor: 'pointer',
                  marginRight: 8,
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                onClick={() => changeDateWith(-1)}
              >
                <Icon icon='left' />
              </div>
              <div
                style={{
                  textAlign: 'center',
                  cursor: 'pointer',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {calendar.filter === FILTER_BY?.day?.name && (
                  <div style={{ padding: '8px 12px' }}>
                    <AdvanceDatePickerContainer>
                      <DatePicker
                        appearance='subtle'
                        placeholder='Subtle'
                        value={calendar.date}
                        onChange={onChangeDate}
                        cleanable={false}
                        placement='bottom'
                        oneTap
                        {...props}
                      />
                    </AdvanceDatePickerContainer>
                    <strong>{moment(calendar.date).format('LL')}</strong>
                  </div>
                )}
                {calendar.filter === FILTER_BY?.week?.name && (
                  <BoldDropdown
                    title={`${startAndEndOfWeek.start.format(
                      FILTER_BY.day.formatOnDisplay,
                    )} - ${startAndEndOfWeek.end.format(FILTER_BY.day.formatOnDisplay)}`}
                  >
                    {lastWeeks.map((o) => (
                      <Dropdown.Item
                        value={o.key}
                        active={o.key === calendar.date}
                        onSelect={() => setCalendar((cal) => ({ ...cal, date: o.key }))}
                      >
                        {o.name}
                      </Dropdown.Item>
                    ))}
                  </BoldDropdown>
                )}
                {calendar.filter === FILTER_BY?.month?.name && (
                  <BoldDropdown
                    title={`${moment(calendar.date, FILTER_BY.month.format).format(FILTER_BY.month.formatOnDisplay)}`}
                  >
                    {lastMonths.map((o) => (
                      <Dropdown.Item
                        value={o.key}
                        active={o.key === calendar.date}
                        onSelect={() => setCalendar((cal) => ({ ...cal, date: o.key }))}
                      >
                        {o.name}
                      </Dropdown.Item>
                    ))}
                  </BoldDropdown>
                )}
                {calendar.filter === FILTER_BY?.quarter?.name && (
                  <BoldDropdown
                    title={`${moment(calendar.date, FILTER_BY.quarter.format).format(
                      FILTER_BY.quarter.formatOnDisplay,
                    )}`}
                  >
                    {lastQuarters.map((o) => (
                      <Dropdown.Item
                        value={o.key}
                        active={o.key === calendar.date}
                        onSelect={() => setCalendar((cal) => ({ ...cal, date: o.key }))}
                      >
                        {o.name}
                      </Dropdown.Item>
                    ))}
                  </BoldDropdown>
                )}
                {calendar.filter === FILTER_BY?.year?.name && (
                  <BoldDropdown
                    title={`${moment(calendar.date, FILTER_BY.year.format).format(FILTER_BY.year.formatOnDisplay)}`}
                  >
                    {lastYears.map((o) => (
                      <Dropdown.Item
                        value={o.key}
                        active={o.key === calendar.date}
                        onSelect={() => setCalendar((cal) => ({ ...cal, date: o.key }))}
                      >
                        {o.name}
                      </Dropdown.Item>
                    ))}
                  </BoldDropdown>
                )}
              </div>
              <div
                style={{
                  textAlign: 'right',
                  cursor: 'pointer',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginLeft: 8,
                }}
                onClick={() => changeDateWith(+1)}
              >
                <Icon icon='right' />
              </div>
            </div>
          </Col>
        )}
      </Row>
    </ContainerPanel>
  );
};

export default DateSelector;
