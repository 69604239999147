import React, { useCallback, useContext, useState, useRef, useMemo } from 'react';
import { Dropdown, Icon, Whisper, Popover, Modal, Button, Alert, Tooltip } from 'rsuite';
import Styled from 'styled-components';
import { find } from 'lodash';
import html2canvas from 'html2canvas';

import { AuthContext, LoggedUserContext, OrganizationContext } from '../context';
import { Colors } from '../assets';
import UserDisplay from './UserDisplay';
import AutogrowInput from './AutogrowInput';
import { Bugsnag, Firebase, Mixpanel } from '../services';

const Container = Styled.div({
  position: 'fixed',
  right: 20,
  bottom: 20,
  zIndex: 9999,
});
const QuestionContainer = Styled.div({
  marginTop: 20,
});
const ButtonContainer = Styled.div({
  padding: 12,
  borderRadius: 100,
  backgroundColor: Colors.PRIMARY,
  color: Colors.WHITE,
  boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
  cursor: 'pointer',
  ':hover': {
    '-webkit-filter': 'brightness(90%)',
    '-webkit-transition': 'all .5s ease',
    '-moz-transition': 'all .5s ease',
    '-o-transition': 'all .5s ease',
    '-ms-transition': 'all .5s ease',
    transition: 'all .5s ease',
  }
});

const options = [
  {
    key: 'help',
    title: 'Ask for Help',
    description: '(ETA: 1 day)',
    icon: 'help-o',
    questions: ['How can we help you?'],
  },
  {
    key: 'feedback',
    title: 'Give us Feedback',
    icon: 'star-half-o',
    questions: ['What did you like?', 'How can we improve?'],
  },
  {
    key: 'feature',
    title: 'Request a Feature',
    icon: 'check-square-o',
    questions: ['Which feature are you missing?', 'What problem would it solve for you?'],
  },
  { key: 'bug', title: 'Report a Bug', icon: 'bug', questions: ['Leave a comment'] },
];

const FeedbackModule = () => {
  const auth = useContext(AuthContext);
  const organization = useContext(OrganizationContext);
  const user = useContext(LoggedUserContext);

  const triggerRef = useRef();
  const handleSelectMenu = useCallback(() => triggerRef.current.hide(), [triggerRef]);

  const [isOpen, setIsOpen] = React.useState(false);
  const [sending, setSaving] = React.useState(false);
  const [selectedOption, setSelectedOption] = React.useState(null);
  const [responses, setResponses] = React.useState([]);
  const [rate, setRate] = useState(0);

  const canSend = useMemo(() => {
    if (selectedOption?.key === 'feedback' && !rate) return;
    if (selectedOption?.questions?.length !== responses.filter((r) => r).length) return;

    return true;
  }, [selectedOption, responses, rate]);

  const send = useCallback(async () => {
    setSaving(true);
    try {
      const canvas = await html2canvas(document.querySelector('#organization-page'));
      const blob = await new Promise((resolve) => canvas.toBlob((blob) => resolve(blob), 'image/png'));
      const doc = Firebase.firestore().collection('feedbacks').doc();
      const snapshot = await Firebase.storage().ref().child(`feedbacks/${doc.id}.png`).put(blob, { customMetadata: { organizationId: organization.id, userId: user.id } });
      const captureURL = (await snapshot.ref.getDownloadURL()) || null;
      await doc.set({
        captureURL,
        organization: {
          id: organization.id,
          tenantId: auth.token.claims.aud,
          displayName: organization.displayName,
          logoURL: organization.logoURL || null,
          timezone: organization.timezone,
          createdAt: organization.createdAt,
        },
        user: {
          id: user.id,
          displayName: user.displayName,
          email: user.email,
          photoURL: user.photoURL || null,
          role: user.role,
        },
        metadata: {
          browser: window?.navigator?.userAgent || null,
          platform: window?.navigator?.platform || null,
          language: window?.navigator?.language || null,
          url: window?.location?.href || null,
          screen: {
            width: window?.screen?.width || null,
            height: window?.screen?.height || null,
          },
        },
        type: selectedOption.key,
        responses: [
          ...(selectedOption.key === 'feedback'
            ? [{ question: 'How happy are you with our product?', answer: rate }]
            : []),
          ...selectedOption.questions.map((q, i) => ({ question: q, answer: responses[i] })),
        ],
        createdAt: Firebase.firestore.FieldValue.serverTimestamp(),
      });
      Mixpanel.track('Sent Feedback', { type: selectedOption.key });
      Alert.success('Comments have been sent!');
      setIsOpen(false);
    } catch (error) {
      Alert.error('There has been an error, please try again...');
      Bugsnag.notify(error, (event) => {
        event.severity = 'warning';
      });
    }

    setSaving(false);
  }, [rate, responses, selectedOption, organization, user, auth]);

  return (
    <Container>
      <Whisper
        placement='topEnd'
        trigger='click'
        triggerRef={triggerRef}
        speaker={
          <Popover full>
            <Dropdown.Menu onSelect={handleSelectMenu}>
              {options.map(({ key, title, icon }) => (
                <Dropdown.Item
                  key={key}
                  icon={<Icon icon={icon} />}
                  onSelect={() => {
                    setSelectedOption(find(options, ['key', key]));
                    setResponses(find(options, ['key', key]).questions.map(() => ''));
                    setRate(0);
                    setIsOpen(true);
                  }}
                >
                  {title}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Popover>
        }
      >
        <Whisper trigger='hover' placement='left' speaker={<Tooltip>Need help or want to leave us a comment?</Tooltip>}>
          <ButtonContainer>
            <Icon icon='commenting-o' size='2x' />
          </ButtonContainer>
        </Whisper>
      </Whisper>
      <Modal size='xs' show={isOpen} onHide={() => setIsOpen(false)}>
        <Modal.Header>
          <Modal.Title>
            {selectedOption?.title}
            <span style={{ fontSize: 10 }}>
              <br />
              {selectedOption?.description}
            </span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <UserDisplay id={user?.id} showEmail />
          {selectedOption?.key === 'feedback' && (
            <QuestionContainer>
              <b>How happy are you with our product?</b>
              <br />
              <Icon
                icon={`star${rate < 1 ? '-o' : ''}`}
                size='3x'
                onClick={() => setRate(1)}
                style={{ cursor: 'pointer', color: Colors.YELLOW }}
              />
              <Icon
                icon={`star${rate < 2 ? '-o' : ''}`}
                size='3x'
                onClick={() => setRate(2)}
                style={{ cursor: 'pointer', color: Colors.YELLOW }}
              />
              <Icon
                icon={`star${rate < 3 ? '-o' : ''}`}
                size='3x'
                onClick={() => setRate(3)}
                style={{ cursor: 'pointer', color: Colors.YELLOW }}
              />
              <Icon
                icon={`star${rate < 4 ? '-o' : ''}`}
                size='3x'
                onClick={() => setRate(4)}
                style={{ cursor: 'pointer', color: Colors.YELLOW }}
              />
              <Icon
                icon={`star${rate < 5 ? '-o' : ''}`}
                size='3x'
                onClick={() => setRate(5)}
                style={{ cursor: 'pointer', color: Colors.YELLOW }}
              />
            </QuestionContainer>
          )}
          {selectedOption?.questions.map((question, index) => (
            <QuestionContainer key={index}>
              <b>{question}</b>
              <AutogrowInput
                rows={2}
                value={responses[index]}
                disabled={sending}
                onChange={(val) => {
                  const newResponses = [...responses];
                  newResponses[index] = val;
                  setResponses(newResponses);
                }}
              />
            </QuestionContainer>
          ))}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setIsOpen(false)} appearance='subtle'>
            Cancel
          </Button>
          <Button onClick={send} appearance='primary' disabled={!canSend || sending} loading={sending}>
            Send
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default FeedbackModule;
