import React, { useContext, useState, useMemo, useCallback } from 'react';
import { Row, Col, Panel, InputGroup, Icon, Input, Button, Modal } from 'rsuite';
import Styled from 'styled-components';
import { get, orderBy } from 'lodash';

import { LimitationsContext, LoggedUserContext, OrganizationUsersContext, UsageContext } from '../context';
import { Colors, User as UserIcon } from '../assets';
import { InviteItem, UserForm, UserItem } from '../components';
import { useInvites } from '../hooks';
import { isMobile } from 'react-device-detect';

const Container = Styled.div({
  margin: 30,
});

const Title = Styled.h2({
  marginBottom: 30,
});

const ContainerPanel = Styled(Panel)({
  backgroundColor: Colors.WHITE,
});

const OrganizationUsers = () => {
  const loggedUser = useContext(LoggedUserContext);
  const limitations = useContext(LimitationsContext);
  const usage = useContext(UsageContext);
  const { list: users } = useContext(OrganizationUsersContext);
  const { invites } = useInvites();
  const [search, setSearch] = useState('');
  const [showUserFormModal, setShowUserFormModal] = useState(false);

  const filteredUsers = useMemo(
    () =>
      users.filter(
        (u) =>
          (u.displayName || '').toLowerCase().search(search.toLowerCase()) >= 0 ||
          (u.email || '').toLowerCase().search(search.toLowerCase()) >= 0,
      ),
    [users, search],
  );

  const orderedUsers = useMemo(
    () =>
      orderBy(
        filteredUsers,
        [
          (t) => (t.disabled ? 1 : 0),
          (t) => (t.role === 'Owner' ? 2 : t.role === 'Admin' ? 1 : 0),
          (t) => (get(t, 'updatedAt') ? get(t, 'updatedAt').toDate() : new Date()),
        ],
        ['asc', 'desc', 'desc'],
      ),
    [filteredUsers],
  );

  const usedEmails = useMemo(
    () => [...users.map((u) => u.email), ...invites.list.map((i) => i.email)],
    [users, invites],
  );

  const doSetShowUserFormModal = useCallback((value) => () => setShowUserFormModal(value), []);

  return (
    <Container>
      <Row gutter={16}>
        <Col xs={16}>
          <Title>Users ({usage?.users?.active}/{limitations?.users?.max})</Title>
        </Col>
      </Row>
      <Row gutter={16} style={{ marginBottom: 20 }}>
        <Col xs={24} md={20} style={isMobile ? { marginBottom: 20 } : {}}>
          <ContainerPanel>
            <InputGroup inside>
              <Input placeholder='search' style={{ border: 'none' }} value={search} onChange={setSearch} />
              <InputGroup.Button>
                <Icon icon='search' />
              </InputGroup.Button>
            </InputGroup>
          </ContainerPanel>
        </Col>
        <Col xs={24} md={4}>
          <Button
            block
            appearance='primary'
            style={{ height: 74, fontSize: 18 }}
            onClick={doSetShowUserFormModal(true)}
            disabled={loggedUser.role === 'Member' || usage?.users?.active >= limitations?.users?.max}
          >
            Add User
          </Button>
        </Col>
      </Row>
      {orderedUsers?.length > 0 || invites.list.length > 0 ? (
        <Row gutter={16}>
          {orderedUsers.map((user, index) => (
            <Col xs={24} style={{ marginBottom: 10 }}>
              <ContainerPanel
                style={
                  user.disabled
                    ? {
                        borderColor: Colors.COMPLEMENTARY,
                        borderWidth: 1,
                        borderStyle: 'dashed',
                      }
                    : {}
                }
              >
                <UserItem key={`user-${user.id}`} data={user} />
              </ContainerPanel>
            </Col>
          ))}
          {invites.list.map((invite, index) => (
            <Col xs={24} style={{ marginBottom: 10 }}>
              <ContainerPanel>
                <InviteItem key={`invite-${invite.id}`} data={invite} />
              </ContainerPanel>
            </Col>
          ))}
        </Row>
      ) : (
        <Row gutter={16}>
          <Col xs={24} style={{ marginBottom: 10 }}>
            <ContainerPanel
              style={{
                minHeight: 90,
                textAlign: 'center',
                paddingTop: 40,
                paddingBottom: 40,
              }}
            >
              <img src={UserIcon} style={{ width: '100%', maxWidth: 400 }} />
              <h5 style={{ marginTop: 20 }}>No users found</h5>
              <span>You can start by adding one</span>
            </ContainerPanel>
          </Col>
        </Row>
      )}
      <Modal key='add-new-user' show={showUserFormModal} onHide={doSetShowUserFormModal(false)} overflow={false}>
        <Modal.Header>
          <Modal.Title>Add User</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ paddingBottom: 0 }}>
          <UserForm isNew onSave={doSetShowUserFormModal(false)} usedEmails={usedEmails} />
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default OrganizationUsers;
