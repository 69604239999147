import { useState, useContext, useEffect, useCallback } from 'react';
import { get } from 'lodash';
import { Notification } from 'rsuite';

import { Bugsnag, Firebase, Mixpanel } from '../services';
import { LoggedUserContext, OrganizationContext } from '../context';

const useIntegration = ({ key, loadOnce, onlyPublic }) => {
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [publicValue, setPublicValue] = useState(null);
  const [privateValue, setPrivateValue] = useState(null);
  const loggedUser = useContext(LoggedUserContext);
  const organization = useContext(OrganizationContext);

  useEffect(() => {
    let subscriptions = [];
    setLoading(true);
    if (organization?.id && loggedUser?.id) {
      const publicDoc = Firebase.firestore()
        .collection('organizations')
        .doc(organization.id)
        .collection('private')
        .doc(organization.id)
        .collection('integrations')
        .doc(key);
      const privateDoc = publicDoc.collection('private').doc(key);
      if (loadOnce) {
        if (onlyPublic || loggedUser?.role === 'Member') {
          publicDoc.get().then((doc) => {
            setPublicValue(doc.exists ? doc.data() : null);
            setLoading(false);
          });
        } else {
          publicDoc.get().then((doc) => {
            setPublicValue(doc.exists ? doc.data() : null);

            privateDoc.get().then((doc2) => {
              setPrivateValue(doc2.exists ? doc2.data() : null);
              setLoading(false);
            });
          });
        }
      } else {
        subscriptions.push(
          publicDoc.onSnapshot((doc) => {
            setPublicValue(doc.exists ? doc.data() : null);
            setLoading(false);
          }),
        );
        if (!onlyPublic && loggedUser?.role !== 'Member') {
          subscriptions.push(
            privateDoc.onSnapshot((doc) => {
              setPrivateValue(doc.exists ? doc.data() : null);
              setLoading(false);
            }),
          );
        }
      }
    }
    return () => subscriptions && subscriptions.length && subscriptions.map((s) => s());
  }, [organization.id, key, loadOnce, onlyPublic, loggedUser]);

  const update = useCallback(
    async (publicUpdatedValue, privateUpdatedValue) => {
      setSaving(true);
      try {
        if (!publicUpdatedValue || !privateUpdatedValue) return;
        const batch = Firebase.firestore().batch();
        batch.set(
          Firebase.firestore()
            .collection('organizations')
            .doc(organization.id)
            .collection('private')
            .doc(organization.id)
            .collection('integrations')
            .doc(key),
          {
            ...publicUpdatedValue,
            updatedBy: loggedUser.id,
            updatedAt: Firebase.firestore.FieldValue.serverTimestamp(),
          },
        );
        batch.set(
          Firebase.firestore()
            .collection('organizations')
            .doc(organization.id)
            .collection('private')
            .doc(organization.id)
            .collection('integrations')
            .doc(key)
            .collection('private')
            .doc(key),
          {
            ...privateUpdatedValue,
            updatedBy: loggedUser.id,
            updatedAt: Firebase.firestore.FieldValue.serverTimestamp(),
          },
        );
        await batch.commit();
        setPublicValue({
          ...publicUpdatedValue,
          updatedBy: loggedUser.id,
          updatedAt: Firebase.firestore.FieldValue.serverTimestamp(),
        });
        setPrivateValue({
          ...privateUpdatedValue,
          updatedBy: loggedUser.id,
          updatedAt: Firebase.firestore.FieldValue.serverTimestamp(),
        });
        Notification.success({
          title: 'Success',
          description: 'Integration has been updated',
        });
        Mixpanel.track('Updated Integration', { integration: key });
      } catch (ex) {
        Notification.error({
          title: 'There has been an error',
          description: get(ex, 'message', 'Please try again...'),
        });
        Bugsnag.notify(ex, (event) => {
          event.severity = 'warning';
        });
      } finally {
        setSaving(false);
      }
    },
    [loggedUser.id, organization.id, key],
  );

  return { loading, saving, update, publicValue, privateValue };
};

export default useIntegration;
