import React, { createContext, useState, useEffect, useContext } from 'react';

import { Firebase } from '../services';

import { OrganizationContext, AuthContext } from '../context';
export const Context = createContext();

const LoggedUserContext = (props) => {
  const organization = useContext(OrganizationContext);
  const auth = useContext(AuthContext);
  const [user, setUser] = useState({});

  useEffect(() => {
    let subscription;
    if (auth.session && organization.id) {
      subscription = Firebase.firestore()
        .collection('organizations')
        .doc(organization.id)
        .collection('private')
        .doc(organization.id)
        .collection('users')
        .doc(auth.session.uid)
        .onSnapshot((snapshot) =>
          setUser({
            ...snapshot.data(),
            id: auth.session.uid,
            minDisplayName:
              snapshot.data()?.displayName?.length > 13
                ? `${snapshot.data()?.displayName?.substring(0, 12)}...`
                : snapshot.data()?.displayName,
          }),
        );
    } else {
      setUser({});
    }
    return () => subscription && subscription();
  }, [auth.session, organization.id]);

  return <Context.Provider value={user}>{props.children}</Context.Provider>;
};

export default LoggedUserContext;
