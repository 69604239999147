import React, { useState, useEffect, useRef, useCallback, useContext } from 'react';
import { Row, Col, Placeholder, Button, Loader, Toggle, Form, Schema, Notification } from 'rsuite';
import { get, isEqual, pick } from 'lodash';
import useIntegration from '../hooks/useIntegration';
import { FormGroup } from '.';
import { Firebase } from '../services';
import { LoggedUserContext, OrganizationContext } from '../context';

const DEFAULT_VALUE = { enabled: false };

const formModel = Schema.Model({
  enabled: Schema.Types.BooleanType().isRequired('This field is required'),
  host: Schema.Types.StringType().isRequired('This field is required'),
  username: Schema.Types.StringType().isRequired('This field is required'),
  password: Schema.Types.StringType().isRequired('This field is required'),
});

const JiraIntegrationForm = () => {
  const organization = useContext(OrganizationContext);
  const loggedUser = useContext(LoggedUserContext);
  const { loading, saving, publicValue, privateValue, update } = useIntegration({ key: 'jira', loadOnce: true });
  const [integration, setIntegration] = useState({});
  const formRef = useRef(null);
  const [formError, setFormError] = useState({});
  const [checking, setChecking] = useState(false);

  useEffect(() => {
    setIntegration(!publicValue && !privateValue ? DEFAULT_VALUE : { ...(publicValue || {}), ...(privateValue || {}) });
  }, [publicValue, privateValue]);

  const checkAndUpdate = useCallback(
    async (integrationIntent) => {
      setChecking(true);
      try {
        let result = false;
        if (integrationIntent.enabled) {
          const {
            data: { success },
          } = await Firebase.functions().httpsCallable('CheckJiraIntegration')(integrationIntent);
          result = success;
        } else {
          result = true;
        }
        if (result) {
          update(pick(integrationIntent, 'enabled', 'host'), pick(integrationIntent, 'username', 'password'));
        } else {
          throw new Error('Invalid Jira integration');
        }
      } catch (ex) {
        Notification.error({
          title: 'There has been an error',
          description: get(ex, 'message', 'Please try again...'),
        });
      } finally {
        setChecking(false);
      }
    },
    [update],
  );

  return (
    <Row gutter={16}>
      <Col xs={24}>
        <Row gutter={16}>
          <Col>
            {loading ? (
              <Placeholder.Paragraph rows={4}>
                <Loader center content='loading' />
              </Placeholder.Paragraph>
            ) : (
              <Row gutter={16}>
                <Col xs={24}>
                  <Form
                    fluid
                    key='jiraForm'
                    ref={formRef}
                    onChange={setIntegration}
                    onCheck={setFormError}
                    formValue={integration}
                    model={formModel}
                  >
                    <Row gutter={16}>
                      <Col xs={24} md={12} style={{ marginTop: 10 }}>
                        <FormGroup
                          label='Enabled'
                          name='enabled'
                          error={!!formError.private}
                          errorMessage={formError.private}
                          disabled={saving || checking || loggedUser?.role === 'Member'}
                          accepter={Toggle}
                          checked={integration?.enabled}
                          block
                        />
                      </Col>
                      <Col xs={24} md={12} style={{ marginTop: 10 }}>
                        <FormGroup
                          label='Host'
                          name='host'
                          hint={`Host used to connect to Jira. Example: ${organization.id}.atlassian.net`}
                          error={integration?.enabled && !!formError.host}
                          errorMessage={integration?.enabled ? formError.host : ''}
                          disabled={!integration?.enabled || saving || checking || loggedUser?.role === 'Member'}
                        />
                        <FormGroup
                          label='Username or Email'
                          name='username'
                          hint='We recommend creating an user specifically for this integration'
                          error={integration?.enabled && !!formError.username}
                          errorMessage={integration?.enabled ? formError.username : ''}
                          disabled={!integration?.enabled || saving || checking || loggedUser?.role === 'Member'}
                        />
                        <FormGroup
                          label='API Key'
                          name='password'
                          type='password'
                          hint={
                            <span>
                              Go to Atlassian > Account Settings > Security Settings or{' '}
                              <a
                                href='https://id.atlassian.com/manage-profile/security/api-tokens'
                                target='_blank'
                                style={{ color: 'white', textDecoration: 'underline' }}
                              >
                                Click Here
                              </a>
                            </span>
                          }
                          error={integration?.enabled && !!formError.password}
                          errorMessage={integration?.enabled ? formError.password : ''}
                          disabled={!integration?.enabled || saving || checking || loggedUser?.role === 'Member'}
                        />
                      </Col>
                    </Row>

                    <Row gutter={16}>
                      <Col xs={24} style={{ marginTop: 20 }}>
                        <div style={{ textAlign: 'right' }}>
                          <Button
                            appearance='primary'
                            onClick={() => checkAndUpdate(integration)}
                            disabled={
                              (!publicValue && !privateValue
                                ? isEqual(integration, DEFAULT_VALUE)
                                : isEqual(integration, { ...(publicValue || {}), ...(privateValue || {}) })) ||
                              (integration?.enabled &&
                                (!integration.host ||
                                  formError.host ||
                                  !integration.username ||
                                  formError.username ||
                                  !integration.password ||
                                  formError.password)) ||
                              saving ||
                              checking ||
                              loggedUser?.role === 'Member'
                            }
                            loading={saving || checking}
                          >
                            Save
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default JiraIntegrationForm;
