import { useState, useContext, useEffect } from 'react';

import { Firebase } from '../services';
import { OrganizationContext } from '../context';
import { docsToArray, docsToMap } from '../utils';

const useInvites = () => {
  const [loading, setLoading] = useState(true);
  const [invites, setInvites] = useState({ list: [], map: {} });
  const organization = useContext(OrganizationContext);

  useEffect(() => {
    let subscription = () => {};
    if (organization.id) {
        let query = Firebase.firestore()
        .collection('organizations')
        .doc(organization.id)
        .collection('private')
        .doc(organization.id)
        .collection('invites')
        .where('deletedAt', '==', null);

      subscription = query.onSnapshot((docs) => {
        setInvites({
          list: docsToArray(docs),
          map: docsToMap(docs),
        });
        setLoading(false);
      });
    }
    return subscription;
  }, [organization.id])

  return { loading, invites };
};

export default useInvites;
