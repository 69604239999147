import React, { useContext, useState, useMemo, useEffect } from 'react';
import { Row, Col } from 'rsuite';
import { useLocation } from 'react-router-dom';
import Styled from 'styled-components';
import moment from 'moment-timezone';
import { orderBy } from 'lodash';

import { LoggedUserContext, OrganizationContext, TeamContext, TeamGoalsContext, TeamMembersContext } from '../context';
import OutcomesCard from '../components/OutcomesCard';
import { Firebase } from '../services';
import { docsToArray } from '../utils';
import { DateSelector } from '../components';

const Container = Styled.div({
  padding: 30,
});

const Title = Styled.h2({
  marginBottom: 30,
});

const TeamOutcomes = ({ setExpand }) => {
  const loggedUser = useContext(LoggedUserContext);
  const organization = useContext(OrganizationContext);
  const team = useContext(TeamContext);
  const { list: members } = useContext(TeamMembersContext);
  const goals = useContext(TeamGoalsContext);
  const query = new URLSearchParams(useLocation().search);
  const [date, setDate] = useState(query.get('date') || moment().format('YYYY-MM-DD'));

  const orderedMembers = useMemo(
    () => orderBy(members, [(m) => (m.id === loggedUser.id ? 1 : 0), 'displayName'], ['desc', 'asc']),
    [members, loggedUser.id],
  );

  return (
    <Container>
      <Row gutter={16}>
        <Col>
          <Title>Team Outcomes</Title>
        </Col>
      </Row>
      <DateSelector onChange={({ date }) => setDate(date)} date={date} style={{marginBottom: 20}}/>
      <Row gutter={16}>
        {orderedMembers.map((m) => (
          <Col xs={24} md={12} lg={8}>
            <OutcomesCard member={m} date={date} goals={goals}/>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default TeamOutcomes;
