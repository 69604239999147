import React, { useEffect } from 'react';
import Styled from 'styled-components';
import { Panel } from 'rsuite';

import { Logo } from '../components';

const LoginPanel = Styled(Panel)({
  backgroundColor: '#ffffff',
});
const Content = Styled.div({});
const Section = Styled.div({});

const ErrorCard = ({ setBackground }) => {
  useEffect(() => {
    setTimeout(() => {
      window.location.href = '/';
    }, 5000);
  }, []);

  return (
    <LoginPanel shaded>
      <Content>
        <Logo marginBottom={30} />
        <Section>
          <b>There has been an error, reporting & sending you back to dashboard...</b>
        </Section>
      </Content>
    </LoginPanel>
  );
};

export default ErrorCard;
