import { get } from 'lodash';
import React, { useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Icon } from 'rsuite';
import Styled from 'styled-components';
import { Colors } from '../assets';
import { OrganizationContext } from '../context';
import GoalTag, { Tag } from './GoalTag';

const Container = Styled.div({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginTop: 10,
  marginBottom: 10,
  cursor: 'pointer',
  position: 'relative',
});

const GoalDisplay = ({ goal, teamId, totalContribution, completionRate, style = {}, ...props }) => {
  const history = useHistory();
  const organization = useContext(OrganizationContext);
  const goToGoal = useCallback(() => {
    if (teamId) {
      history.push(`/${organization.id}/team/${teamId}/objective/${goal.id}`);
    } else {
      history.push(`/${organization.id}/goal/${goal.id}`);
    }
  }, [goal.id, organization.id, history, teamId]);

  return (
    <Container onClick={goToGoal}>
      <Tag
        tagColor={goal.tagColor || Colors.BRAND}
        style={{
          alignSelf: 'center',
          position: 'absolute',
        }}
      />
      <div style={{ marginLeft: 16, display: 'grid' }}>
        <p
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            fontWeight: 'bold',
            display: 'block',
          }}
        >
          {get(goal, 'displayName', 'Deleted Goal')}
        </p>
        {totalContribution && (
          <span>{`${totalContribution} outcomes - ${(completionRate * 100).toFixed(2)}% completed`}</span>
        )}
      </div>
    </Container>
  );
};

export default GoalDisplay;
