import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { useEffect } from 'react';

const OnEditorUpdatePlugin = ({ onEditorUpdate }) => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    onEditorUpdate(editor);
  }, [editor, onEditorUpdate]);

  return null;
};

export default OnEditorUpdatePlugin;
