import React, { useState, useContext, useEffect, useMemo } from 'react';
import Styled from 'styled-components';
import { Row, Col, Panel } from 'rsuite';
import { orderBy } from 'lodash';

import { Firebase, Mixpanel } from '../services';
import { OrganizationContext } from '../context';
import { Colors } from '../assets';
import { docsToArray } from '../utils';
import GoalUpdateItem from './GoalUpdateItem';
import GoalUpdateForm from './GoalUpdateForm';

const ContainerPanel = Styled(Panel)({
  backgroundColor: Colors.WHITE,
});

const GoalUpdates = ({ data: goal, settings, ...props }) => {
  const organization = useContext(OrganizationContext);
  const [updates, setUpdates] = useState([]);

  const orderedUpdates = useMemo(
    () => orderBy(updates, [(u) => u?.createdAt?.toDate() || new Date()], ['desc']),
    [updates],
  );

  useEffect(() => {
    let subscription;
    if (organization.id && goal?.id) {
      subscription = Firebase.firestore()
        .collection('organizations')
        .doc(organization.id)
        .collection('private')
        .doc(organization.id)
        .collection('goals')
        .doc(goal.id)
        .collection('updates')
        .onSnapshot((docs) => setUpdates(docsToArray(docs)));
    }
    return () => {
      subscription && subscription();
    };
  }, [organization.id, goal?.id]);

  return (
    <Row gutter={16} style={{ marginBottom: 20 }}>
      <Col xs={24}>
        <h6 style={{ marginBottom: 10 }}>Updates</h6>
        {orderedUpdates.map((u) => (
          <GoalUpdateItem key={u.id} data={u} goal={goal} settings={settings} />
        ))}
        <ContainerPanel style={{ marginBottom: 20 }}>
          <GoalUpdateForm
            goal={goal}
            settings={settings}
            onGoalUpdateCreated={() => {
              Mixpanel.track('Created Goal Update');
              Mixpanel.people.increment("Goal Updates Created");
            }}
          />
        </ContainerPanel>
      </Col>
    </Row>
  );
};

export default GoalUpdates;
