import React, { useState } from 'react';

import { BrandLayout } from '../layouts';
import { RecoverPasswordCard } from '../components';

const RecoverPassword = () => {
  const [background, setBackground] = useState(null);
  return (
    <BrandLayout background={background}>
      <RecoverPasswordCard  setBackground={setBackground}/>
    </BrandLayout>
  );
};

export default RecoverPassword;
