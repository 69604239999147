import React, { useState, useCallback, useContext, useEffect, useMemo } from 'react';
import { debounce, get } from 'lodash';
import Styled from 'styled-components';
import {
  Row,
  Col,
  Dropdown,
  Icon,
  Popover,
  Whisper,
  IconButton,
  Notification,
  DatePicker,
  Progress,
  Panel,
  Avatar,
  Slider,
  InputNumber,
  InputGroup,
  Divider,
} from 'rsuite';
import { useHistory } from 'react-router-dom';
import moment from 'moment-timezone';

import { Bugsnag, Firebase } from '../services';
import { OrganizationContext, LoggedUserContext, OrganizationUsersContext } from '../context';
import { Colors } from '../assets';
import GoalTag from './GoalTag';
import AutogrowInput from './AutogrowInput';
import RichText from './RichText';
import { formatNumber } from '../utils';

const ContainerPanel = Styled(Panel)({
  backgroundColor: Colors.WHITE,
});

const GoalUpdateItem = ({ data, goal, settings, ...props }) => {
  const history = useHistory();
  const organization = useContext(OrganizationContext);
  const { map: usersMap } = useContext(OrganizationUsersContext);
  const loggedUser = useContext(LoggedUserContext);
  const [update, setUpdate] = useState(data);

  const date = useMemo(() => moment(data?.createdAt?.toDate()).format('MMMM DD, YYYY [at] hh:mma'), [data]);

  useEffect(() => setUpdate(data), [data]);

  const updateRecord = useCallback(
    async (nUpdate) => {
      try {
        await Firebase.firestore()
          .collection('organizations')
          .doc(organization.id)
          .collection('private')
          .doc(organization.id)
          .collection('goals')
          .doc(goal.id)
          .collection('updates')
          .doc(update.id)
          .update({
            ...nUpdate,
            updatedBy: loggedUser.id,
            updatedAt: Firebase.firestore.FieldValue.serverTimestamp(),
          });
        Notification.success({
          title: 'Success',
          description: 'Update has been successfully shared.',
        });
      } catch (ex) {
        Notification.error({
          title: 'There has been an error',
          description: get(ex, 'message', 'Please try again...'),
        });
        Bugsnag.notify(ex, (event) => {
          event.severity = 'warning';
        });
      }
    },
    [organization.id, loggedUser.id, goal.id, update.id],
  );

  const updateRecordWithDebounce = useCallback(debounce(updateRecord, 1000), [updateRecord]);

  return (
    <ContainerPanel style={{ marginBottom: 20 }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Avatar
          circle
          size='md'
          style={{
            backgroundColor: Colors.TAGLINE,
            marginRight: 20,
          }}
          src={usersMap[update.createdBy]?.photoURL}
        >
          {!usersMap[update.createdBy]?.photoURL
            ? (usersMap[update.createdBy]?.displayName || '').substring(0, 2)
            : null}
        </Avatar>
        <div style={{ marginRight: 4, display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
          <b>{usersMap[update.createdBy]?.displayName}</b>
          <span>{date}</span>
        </div>
        <div style={{ display: 'flex', flex: 1, flexDirection: 'row-reverse' }}>
          {(settings?.progress?.value === 'updates' || settings?.progress?.value === undefined) && (
            <b>{`${update?.progress || 0}%`}</b>
          )}
          {settings?.progress?.value === 'amount' && update?.achievedAmount && (
            <b>
              {formatNumber(update?.achievedAmount || 0) + ' / ' + formatNumber(settings?.progress?.targetedAmount)}
            </b>
          )}
          {settings?.progress?.value === 'milestones' && update?.achievedMilestone && (
            <div>
              {settings?.progress?.milestones.map((m, index) => (
                <>
                  {(update?.achievedMilestoneIndex || 0) === index ? (
                    <b>
                      <u>{m}</u>
                    </b>
                  ) : (
                    <span>{m}</span>
                  )}
                  {index === settings?.progress?.milestones?.length - 1 ? null : <Divider vertical />}
                </>
              ))}
            </div>
          )}
        </div>
      </div>
      {update?.createdBy !== loggedUser?.id ? (
        <p>{update?.description}</p>
      ) : (
        <RichText placeholder={'New update'} value={update?.description} toolbar={false} readOnly={true} />
      )}
    </ContainerPanel>
  );
};

export default GoalUpdateItem;
