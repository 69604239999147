import React, { createContext, useState, useEffect } from 'react';

import { Bugsnag, Firebase, Mixpanel } from '../services';

export const Context = createContext();

const AuthContext = (props) => {
  const [auth, setAuth] = useState({ loading: true });

  useEffect(() => {
    const subscription = Firebase.auth().onAuthStateChanged((session) => {
      if (session) {
        Firebase.auth()
          .currentUser.getIdTokenResult()
          .then((token) => {
            setAuth({ session, token, loading: false });
            session.getIdToken(true);
            Mixpanel.identify(`${token?.claims?.workspaceId}.${session.uid}`);
            Mixpanel.people.set({
              workspaceId: token?.claims?.workspaceId,
              tenantId: session.tenantId,
              userId: session.uid,
              role: token?.claims?.role,
              '$name': session.displayName,
              '$email': session.email,
            });
            Mixpanel.set_group('Role', token?.claims?.role);
            Bugsnag.setUser(`${token?.claims?.workspaceId}.${session.uid}`, session.email, session.displayName);
          })
          .catch((ex) => {
            setAuth({ loading: false });
          });
      } else {
        setAuth({ loading: false });
      }
    });
    return () => subscription && subscription();
  }, []);

  return <Context.Provider value={auth}>{props.children}</Context.Provider>;
};

export default AuthContext;
