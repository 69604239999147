import { find } from 'lodash';
import React, { useMemo, useState } from 'react';
import { Badge, Button, Icon, Placeholder, Tag } from 'rsuite';
import Styled from 'styled-components';

import { Colors } from '../assets';

const Container = Styled.div(({ popular, loading }) => ({
  display: 'inline-flex',
  flexDirection: 'column',
  width: 300,
  paddingTop: 30,
  paddingBottom: 30,
  paddingLeft: 40,
  paddingRight: 40,
  margin: 10,
  borderRadius: 8,
  backgroundColor: popular ? Colors.CLOUD : Colors.WHITE,
  ...(!loading ? { alignItems: 'start' } : {}),
}));

const Name = Styled.h4({
  color: Colors.BLACK,
  marginBottom: 12,
});

const Description = Styled.span({
  color: Colors.GRAY,
  marginBottom: 20,
});
const PriceContainer = Styled.div({
  color: Colors.BLACK,
  marginBottom: 20,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-end',
});

const Price = Styled.h2({
  color: Colors.BLACK,
  marginRight: 8,
});

const Interval = Styled.span({
  color: Colors.GRAY,
  paddingBottom: 10,
  textAlign: 'left',
  fontSize: 12,
});
const EmptyTag = Styled.div({
  width: '100%',
  marginBottom: 8,
});

const FeaturesContainer = Styled.div({
  textAlign: 'start',
  color: Colors.BLACK,
});

const Feature = Styled.div({
  color: Colors.BLACK,
  marginTop: 4,
});

const SubscriptionProduct = ({ product, interval, loading, disabled, onSubscribe = () => {} }) => {
  const [subscribing, setSubscribing] = useState(false);
  const price = useMemo(() => find(product?.prices || [], { interval }), [product, interval]);
  const savings = useMemo(
    () =>
      (find(product?.prices || [], { interval: 'month' })?.displayedAmount || 0) * 12 -
      (find(product?.prices || [], { interval: 'year' })?.displayedAmount || 1),
    [product],
  );
  return (
    <Container popular={product?.popular} loading={loading}>
      {loading ? (
        <>
          <Placeholder.Graph active />
          <Placeholder.Paragraph style={{ marginTop: 30 }} />
          <Placeholder.Paragraph style={{ marginTop: 10 }} />
          <Placeholder.Paragraph style={{ marginTop: 10 }} />
        </>
      ) : (
        <>
          {product?.popular ? (
            <Tag color='orange' className='animate__animated animate__fadeInDown'>
              Popular
            </Tag>
          ) : (
            <EmptyTag />
          )}
          <Name className='animate__animated animate__fadeInDown'>{product?.name}</Name>
          <Description className='animate__animated animate__fadeInDown'>{product?.description}</Description>
          <PriceContainer className='animate__animated animate__fadeInDown'>
            <Price>${price?.interval === 'month' ? price?.displayedAmount : price?.displayedAmount / 12}</Price>
            <Interval
              style={{
                maxWidth: price?.interval === 'month' ? 40 : 80,
              }}
            >
              {price?.interval === 'month' ? 'per month' : 'per month, billed yearly'}
            </Interval>
          </PriceContainer>
          <Badge content={interval === 'year' ? `Saves $${savings}` : false} style={{ width: '100%' }}>
            <Button
              appearance='primary'
              onClick={() => {
                onSubscribe(product?.id, price?.id);
                setSubscribing(true);
              }}
              block
              style={{ fontWeight: 'bold', marginBottom: 12 }}
              loading={subscribing}
              disabled={subscribing || disabled}
              className='animate__animated animate__fadeIn'
            >
              Subscribe
            </Button>
          </Badge>
          {product?.features?.length > 0 && (
            <FeaturesContainer className='animate__animated animate__fadeIn'>
              This includes:
              {product?.features?.map((feature) => (
                <Feature>
                  <Icon icon='check-circle' style={{ color: Colors.GREEN, marginRight: 8 }} />
                  {feature}
                </Feature>
              ))}
            </FeaturesContainer>
          )}
        </>
      )}
    </Container>
  );
};

export default SubscriptionProduct;
