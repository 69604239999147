import React, { useState, useCallback, useContext, useRef } from 'react';
import { get, omitBy } from 'lodash';
import {
  Form,
  Button,
  Notification,
  Schema,
  FlexboxGrid,
  InputPicker,
} from 'rsuite';

import { Bugsnag, Firebase } from '../services';
import {
  OrganizationContext,
  LoggedUserContext,
  TeamContext,
  TeamMembersContext,
} from '../context';
import { FormGroup, UserSelectPicker } from '../components';
import { TeamRoles } from '../types';

const { StringType } = Schema.Types;

const memberFormModel = Schema.Model({
  user: StringType().isRequired('This field is required'),
  role: StringType()
    .isOneOf(TeamRoles, 'Needs to be valid role')
    .isRequired('This field is required'),
});

const roleOptions = TeamRoles.map((r) => ({
  label: r,
  value: r,
}));

const MemberForm = ({ onSave }) => {
  const [saving, setSaving] = useState(false);
  const loggedUser = useContext(LoggedUserContext);
  const organization = useContext(OrganizationContext);
  const team = useContext(TeamContext);
  const { map: members } = useContext(TeamMembersContext);
  const memberFormRef = useRef(null);
  const [memberFormValue, setMemberFormValue] = useState({});
  const [memberFormError, setMemberFormError] = useState({});

  const save = useCallback(async () => {
    setSaving(true);
    try {
      await Firebase.firestore()
        .collection('organizations')
        .doc(organization.id)
        .collection('private')
        .doc(organization.id)
        .collection('teams')
        .doc(team.id)
        .update({
          ...team,
          leaders: {
            ...Object.keys(
              omitBy(
                members,
                (m) => m.teamRole !== 'Leader' || m.id === memberFormValue.user,
              ),
            ).reduce((r, m) => ({ ...r, [m]: true }), {}),
            ...(memberFormValue.role === 'Leader'
              ? { [memberFormValue.user]: true }
              : {}),
          },
          members: {
            ...Object.keys(
              omitBy(
                members,
                (m) => m.teamRole !== 'Member' || m.id === memberFormValue.user,
              ),
            ).reduce((r, m) => ({ ...r, [m]: true }), {}),
            ...(memberFormValue.role === 'Member'
              ? { [memberFormValue.user]: true }
              : {}),
          },
          createdAt: Firebase.firestore.FieldValue.serverTimestamp(),
          updatedAt: Firebase.firestore.FieldValue.serverTimestamp(),
        });
      if (onSave) onSave();
    } catch (ex) {
      Notification.error({
        title: 'There has been an error',
        description: get(ex, 'message', 'Please try again...'),
      });
      Bugsnag.notify(ex, (event) => {
        event.severity = 'warning';
      });
    }
    setSaving(false);
  }, [loggedUser.id, onSave, memberFormValue, organization, team, members]);

  return (
    <Form
      fluid
      key='memberForm'
      ref={memberFormRef}
      onChange={setMemberFormValue}
      onCheck={setMemberFormError}
      formValue={memberFormValue}
      model={memberFormModel}
    >
      <FormGroup
        label='User'
        name='user'
        error={!!memberFormError.user}
        errorMessage={memberFormError.user}
        disabled={saving}
        accepter={UserSelectPicker}
        disabledItemValues={Object.keys(members)}
        placeholder='No user selected'
        block
      />
      <FormGroup
        label='Team Role'
        name='role'
        hint='Team roles are specific per team'
        error={!!memberFormError.role}
        errorMessage={memberFormError.role}
        accepter={InputPicker}
        data={roleOptions}
        placeholder='No role selected'
        cleanable={false}
      />
      <FlexboxGrid justify='end'>
        <FlexboxGrid.Item colspan={4}>
          <Button
            appearance='primary'
            block
            disabled={
              !memberFormValue.user ||
              memberFormError.user ||
              !memberFormValue.role ||
              memberFormError.role
            }
            loading={saving}
            onClick={save}
          >
            Save
          </Button>
        </FlexboxGrid.Item>
      </FlexboxGrid>
    </Form>
  );
};

export default MemberForm;
