import moment from 'moment-timezone';
import { find, findIndex, first, sortBy } from 'lodash';

export const uuidv4 = () =>
  ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16),
  );

export const docsToArray = (docs) => {
  const result = [];
  if (!docs || docs.length <= 0) return result;
  docs.forEach((doc) => result.push({ ...doc.data(), id: doc.id }));
  return result;
};

export const docsToMap = (docs) => {
  const result = {};
  if (!docs || docs.length <= 0) return result;
  docs.forEach((doc) => {
    result[doc.id] = doc.data();
  });
  return result;
};

export const generateIdBasedOn = (value) =>
  value
    .replace(/[^a-zA-Z0-9 -]/g, '')
    .replace(/ /g, '-')
    .toLowerCase();

export const formatDate = (date) => moment(date).format('LL');

export const randomDarkColor = () => {
  var lum = -0.25;
  var hex = String('#' + Math.random().toString(16).slice(2, 8).toUpperCase()).replace(/[^0-9a-f]/gi, '');
  if (hex.length < 6) {
      hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  var rgb = "#",
      c, i;
  for (i = 0; i < 3; i++) {
      c = parseInt(hex.substr(i * 2, 2), 16);
      c = Math.round(Math.min(Math.max(0, c + (c * lum)), 255)).toString(16);
      rgb += ("00" + c).substr(c.length);
  }
  return rgb;
}

export const generateCodeBasedOn = (value) =>
  value
    .replace(/[^a-zA-Z0-9]/g, '')
    .toUpperCase().substring(0, 3);

export const groupGoalsWithParents = (goals) => {
    if (!goals || !goals.length) {
      return [];
    }
    
    const result = [];
    let pendingGoals = [...sortBy(goals, (g) => g.goalSequence.length).reverse()];
    while (pendingGoals.length > 0) {
      const goal = pendingGoals.shift();
      const parent = find(pendingGoals, ['id', goal.parent]);
      const parentIndex = findIndex(pendingGoals, ['id', goal.parent]);
      if(!parent) {
        result.push(goal);
      } else {
        pendingGoals[parentIndex] = {...parent, subGoals: [...(parent.subGoals || []), goal]};
      }
    }
    return result;
}

export const unGroupGoalsWithParentsWithRenames = (goals, iteration = 0) => {
  if (!goals || !goals.length) {
    return [];
  }
  const result = [];
  goals.forEach((goal) => {
    result.push({ ...goal, displayName: `${createEmptyArray(iteration).map(_ => '►').join('')} ${goal.displayName}`});
    if(goal.subGoals && goal.subGoals.length > 0) {
      result.push(...unGroupGoalsWithParentsWithRenames(goal.subGoals, iteration + 1));
    }
  });
  return result;
}

export const createEmptyArray = (length) => {
  const result = [];
  for (let i = 0; i < length; i++) {
    result.push(null);
  }
  return result;
}

export const formatNumber = (number) => number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const formatKbs = (kbs, decimals = 2) => {
  const bytes = kbs * 1024;
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

