import React, { createContext, useState, useEffect, useContext } from 'react';

import { Firebase } from '../services';
import { docsToArray } from '../utils';

import { OrganizationContext } from '.';
export const Context = createContext();

const UsageContext = (props) => {
  const organization = useContext(OrganizationContext);
  const [usage, setUsage] = useState({});

  useEffect(() => {
    let subscription;
    if (organization.id) {
      subscription = Firebase.firestore()
        .collection('organizations')
        .doc(organization.id)
        .collection('private')
        .doc(organization.id)
        .collection('usage')
        .onSnapshot((snapshots) => {
          const list = docsToArray(snapshots);
          const map = list.reduce((r, u) => ({ ...r, [u.id]: u }), {});
          setUsage(map);
        });
    } else {
      setUsage({});
    }
    return () => subscription && subscription();
  }, [organization.id]);

  return <Context.Provider value={usage}>{props.children}</Context.Provider>;
};

export default UsageContext;
