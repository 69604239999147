const Dev = {
  firebase: {
    apiKey: 'AIzaSyBMPmJriYnUh9qVSulDQkM9d55O46r_VC4',
    authDomain: 'intry-681ca.firebaseapp.com',
    databaseURL: 'https://intry-681ca.firebaseio.com',
    projectId: 'intry-681ca',
    storageBucket: 'intry-681ca.appspot.com',
    messagingSenderId: '765749636888',
    appId: '1:765749636888:web:65e479ec8ef6cc2b54f86d',
    measurementId: 'G-8GZLNT7Y5E',
    useEmulator: true,
  },
  bugsnag: {
    appVersion: '0.1.0',
    releaseStage: 'development',
    enabled: false,
    apiKey: '7f5f07da6e056a42d6863d8bd0e4e1e2',
  },
  mixpanel: {
    enabled: false,
    debug: true,
    apiKey: '7c7318f35ef98bf3906c79b6e3b38fa3',
  },
};

export default Dev;
