import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { CLEAR_HISTORY_COMMAND } from 'lexical';
import { isEqual } from 'lodash';
import { useEffect } from 'react';

const UpdatePlugin = ({ value }) => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if (value && !isEqual(value, JSON.parse(JSON.stringify(editor.getEditorState().toJSON())))) {
      editor.setEditorState(editor.parseEditorState(JSON.stringify(value)));
      editor.dispatchCommand(CLEAR_HISTORY_COMMAND, undefined);
    }
  }, [value]);

  return null;
};

export default UpdatePlugin;
