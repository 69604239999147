import React, { useState, useContext, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { Animation, Icon, IconButton } from 'rsuite';
import { get, last } from 'lodash';
import Styled from 'styled-components';

import { Colors } from '../assets';
import { OrganizationContext } from '../context';
import GoalTag, { Tag } from './GoalTag';

const Container = Styled.div(({ backgroundColor }) => ({
  backgroundColor,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginTop: 10,
  padding: 10,
  borderRadius: 4,
  cursor: 'pointer',
  position: 'relative',
}));

const GoalSelectPickerItem = ({ goal, selectedGoal, onSelect }) => {
  const history = useHistory();
  const organization = useContext(OrganizationContext);
  const selected = useMemo(() => selectedGoal?.id === goal.id || selectedGoal?.goalSequence?.includes(goal.id), [goal, selectedGoal]);
  const [showSubGoals, setShowSubGoals] = useState(selected && last(selectedGoal?.goalSequence) !== goal.id);

  return (
    <div key={goal.key}>
      <Container backgroundColor={selected ? Colors.ACCENT : Colors.CLOUD}>
        <Tag
          tagColor={goal.tagColor}
          style={{
            paddingTop: 14,
            paddingBottom: 14,
          }}
          onClick={() => onSelect(goal.id)}
        />
        <div style={{ marginLeft: 8, flex: 1 }}  onClick={() => onSelect(goal.id)}>
          <p
            style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              lineHeight: `30px`,
              fontWeight: selected ? 'bold' : 'normal',
              ...(selected ? {color: Colors.WHITE} : {}),
            }}
          >
            {get(goal, 'displayName')}
          </p>
        </div>
        {goal?.subGoals?.length > 0 && (
          <div>
            <IconButton
              appearance='subtle'
              onClick={() => setShowSubGoals(!showSubGoals)}
              icon={<Icon icon={`caret-${showSubGoals ? 'up' : 'down'}`} />}
            />
          </div>
        )}
      </Container>
      {goal?.subGoals?.length > 0 && (
        <Animation.Collapse in={showSubGoals}>
          <div style={{ marginLeft: 20 }}>
            {goal.subGoals.map((sg) => (
              <GoalSelectPickerItem goal={sg} selectedGoal={selectedGoal} onSelect={onSelect}/>
            ))}
          </div>
        </Animation.Collapse>
      )}
    </div>
  );
};

export default GoalSelectPickerItem;
