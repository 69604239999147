import React, { createContext, useState, useEffect, useContext } from 'react';

import { Firebase } from '../services';
import { docsToArray } from '../utils';

import { OrganizationContext } from '.';
export const Context = createContext();

const IntegrationsContext = (props) => {
  const organization = useContext(OrganizationContext);
  const [integrations, setIntegrations] = useState({ list: [], map: {} });

  useEffect(() => {
    let subscription;
    if (organization.id) {
      subscription = Firebase.firestore()
        .collection('organizations')
        .doc(organization.id)
        .collection('private')
        .doc(organization.id)
        .collection('integrations')
        .onSnapshot((snapshots) => {
          const list = docsToArray(snapshots);
          const map = list.reduce((r, u) => ({ ...r, [u.id]: u }), {});
          setIntegrations({ list, map });
        });
    } else {
      setIntegrations({ list: [], map: {} });
    }
    return () => subscription && subscription();
  }, [organization.id]);

  return <Context.Provider value={integrations}>{props.children}</Context.Provider>;
};

export default IntegrationsContext;
