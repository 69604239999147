import React, { useState, useMemo, useContext, useEffect } from 'react';
import { get, orderBy, first, find, last } from 'lodash';
import Styled from 'styled-components';
import { Row, Col, Table, Panel, Toggle, Checkbox, Avatar } from 'rsuite';
import { useHistory } from 'react-router-dom';
import moment from 'moment-timezone';
import { LineChart, BarChart, PieChart, TreeChart, Bars, Line } from '@rsuite/charts';

import { Firebase, Mixpanel } from '../services';
import { OrganizationContext, LoggedUserContext, OrganizationTeamsContext, OrganizationUsersContext } from '../context';
import { Colors } from '../assets';
import DateSelector, { FILTER_BY } from './DateSelector';
import { docsToArray } from '../utils';
import UserAvatar from './UserAvatar';
import UserDisplay from './UserDisplay';
import TeamDisplay from './TeamDisplay';

const OTHER_DATES_LENGTH = 5;

const ContainerPanel = Styled(Panel)({
  backgroundColor: Colors.WHITE,
  marginBottom: 20,
});

const CounterContainerPanel = Styled(Panel)({
  backgroundColor: Colors.WHITE,
  marginBottom: 20,
  paddingTop: 6,
  paddingBottom: 5,
});

const CompletedCell = ({ rowData, dataKey, ...props }) => (
  <Table.Cell {...props} style={{ paddingTop: 4, paddingLeft: 0, paddingBottom: 0, paddingRight: 0 }}>
    <Checkbox checked={rowData.completed} />
  </Table.Cell>
);
const ResponsibleCell = ({ rowData, dataKey, ...props }) => (
  <Table.Cell {...props} style={{ paddingTop: 8, textAlign: 'right' }}>
    <UserAvatar id={rowData.assignedTo} size='sm' whisper />
  </Table.Cell>
);

const GoalOutcomes = ({ data: goal, ...props }) => {
  const history = useHistory();
  const organization = useContext(OrganizationContext);
  const { map: usersMap } = useContext(OrganizationUsersContext);
  const loggedUser = useContext(LoggedUserContext);
  const [analytics, setAnalytics] = useState({});
  const { map: teamsMap } = useContext(OrganizationTeamsContext);
  const [goals, setGoals] = useState({ list: [], map: {} });
  const [loading, setLoading] = useState(false);
  const [calendar, setCalendar] = useState({
    date: moment().format(FILTER_BY.day.format),
    format: FILTER_BY.day.format,
    filter: FILTER_BY.day.name,
  });

  useEffect(() => {
    let subscription;
    if (organization?.id && goal?.id) {
      subscription = Firebase.firestore()
        .collection('organizations')
        .doc(organization.id)
        .collection('private')
        .doc(organization.id)
        .collection('goals')
        .where('deletedAt', '==', null)
        .where('goalSequence', 'array-contains', last(goal.goalSequence))
        .onSnapshot((snapshots) => {
          const list = docsToArray(snapshots);
          const map = list.reduce((r, u) => ({ ...r, [u.id]: u }), {});
          setGoals({ list, map });
        });
    }
    return () => subscription && subscription();
  }, [organization?.id, goal]);

  useEffect(() => {
    let subscriptions;
    setLoading(true);
    if (organization?.id && goal?.id) {
      subscriptions = [...Array(calendar.filter === FILTER_BY.all.name ? 1 : OTHER_DATES_LENGTH).keys()]
        .map((minus) =>
          calendar.filter === FILTER_BY.all.name
            ? 'ALL'
            : moment(calendar.date, calendar.format)
                .subtract(minus, FILTER_BY[calendar.filter].subtract)
                .format(calendar.format),
        )
        .map((dateDoc) =>
          Firebase.firestore()
            .collection('organizations')
            .doc(organization.id)
            .collection('private')
            .doc(organization.id)
            .collection('analytics')
            .doc(dateDoc)
            .collection('byGoal')
            .doc(goal?.id)
            .onSnapshot((snap) => {
              setAnalytics((currentAnalytics) => ({ ...currentAnalytics, [dateDoc]: snap.data() }));
              setLoading(false);
            }),
        );
    }
    return () => subscriptions && subscriptions.length && subscriptions.map((s) => s());
  }, [organization?.id, goal?.id, calendar]);

  const outcomesCount = useMemo(() => get(analytics[calendar.date], `total`, 0), [calendar, analytics]);
  const teamsCount = useMemo(
    () =>
      Object.keys(get(analytics[calendar.date], `byTeam`, {}))
        .map((id) => ({ id, ...get(analytics[calendar.date], `byTeam.${id}`, {}) }))
        .filter((t) => t.total > 0).length,
    [calendar, analytics],
  );
  const membersCount = useMemo(
    () =>
      Object.keys(get(analytics[calendar.date], `byMember`, {}))
        .map((id) => ({ id, ...get(analytics[calendar.date], `byMember.${id}`, {}) }))
        .filter((u) => u.total > 0).length,
    [calendar, analytics],
  );
  const top5Contributors = useMemo(
    () =>
      orderBy(
        Object.keys(get(analytics[calendar.date], `byMember`, {})).map((id) => ({
          id,
          ...get(analytics[calendar.date], `byMember.${id}`, {}),
          completionRate:
            get(analytics[calendar.date], `byMember.${id}.completed`, 0) /
            get(analytics[calendar.date], `byMember.${id}.total`, 0),
        })),
        ['total', 'completionRate'],
        ['desc'],
      )
        .filter((g) => g.total > 0)
        .slice(0, 5) || [],
    [calendar, analytics],
  );
  const topContributor = useMemo(() => {
    const result = first(top5Contributors) || {};

    return result.total > 0 ? result : null;
  }, [top5Contributors]);
  const topTeam = useMemo(() => {
    const result =
      first(
        orderBy(
          Object.keys(get(analytics[calendar.date], `byTeam`, {})).map((id) => ({
            id,
            ...get(analytics[calendar.date], `byTeam.${id}`, {}),
            completionRate:
              get(analytics[calendar.date], `byTeam.${id}.completed`, 0) /
              get(analytics[calendar.date], `byTeam.${id}.total`, 0),
          })),
          ['total'],
          ['desc'],
        ),
      ) || {};

    return result.total > 0 ? result : null;
  }, [calendar, analytics]);
  const outcomesCompletedCount = useMemo(() => get(analytics[calendar.date], `completed`, 0), [calendar, analytics]);
  const outcomesPendingCount = useMemo(() => get(analytics[calendar.date], `pending`, 0), [calendar, analytics]);
  const teamsByOutcomes = useMemo(
    () =>
      orderBy(
        Object.keys(get(analytics[calendar.date], `byTeam`, {})).map((id) => ({
          id,
          ...get(analytics[calendar.date], `byTeam.${id}`, {}),
        })),
        ['total'],
        ['desc'],
      )
        .filter((g) => g.total > 0)
        .slice(0, 5)
        .map((t) => [get(teamsMap, `${t.id}`, {}).displayName || 'Deleted Team', t.completed, t.pending]),
    [calendar, analytics, teamsMap],
  );
  const usersByOutcomes = useMemo(
    () =>
      top5Contributors.map((u) => [get(usersMap, `${u.id}`, {}).displayName || 'Deleted User', u.completed, u.pending]),
    [top5Contributors, usersMap],
  );
  const outcomesPerDay = useMemo(
    () =>
      [...Array(OTHER_DATES_LENGTH).keys()]
        .map((minus) => moment(calendar.date, calendar.format).subtract(minus, FILTER_BY[calendar.filter].subtract))
        .map((mappedDate) => [
          mappedDate.format(FILTER_BY[calendar.filter].formatOnDisplay),
          get(analytics[mappedDate.format(calendar.format)], `total`, 0),
          get(analytics[mappedDate.format(calendar.format)], `completed`, 0),
          get(analytics[mappedDate.format(calendar.format)], `pending`, 0),
        ])
        .reverse(),
    [calendar, analytics],
  );

  const goalTreeChart = useMemo(() => {
    const { list } = goals;
    if (!list || !list.length) {
      return {};
    }
    const parent = goal;
    if (!parent) {
      return {};
    }
    const findChildren = (parentId) => list.filter((gc) => gc.parent === parentId);
    const findChildrenRecursive = (p) => ({
      name: `${p.displayName} \nTeam: ${p.team ? teamsMap[p.team].displayName : organization.displayName}\nOutcomes: ${
        parent?.id !== p.id
          ? get(analytics[calendar.date], `byGoal.${p.id}.total`, 0)
          : get(analytics[calendar.date], `total`, 0)
      }`,
      children: findChildren(p.id).map(findChildrenRecursive),
    });
    return findChildrenRecursive(parent);
  }, [goals, teamsMap, analytics, calendar.date, organization.displayName, goal]);

  return (
    <Row gutter={16} style={{ marginBottom: 20 }}>
      <Col xs={24} style={{ display: 'flex', alignItems: 'flex-end', marginBottom: 20 }}>
        <div style={{ flex: 1 }}>
          <h6>Analytics</h6>
        </div>
        <div>
          <DateSelector
            onChange={(cal) => {
              Mixpanel.track('Filtered Goal Analytics', { by: cal?.filter });
              setCalendar(cal);
            }}
            value={calendar.date}
            placement='left'
            advanced
          />
        </div>
      </Col>
      <Col xs={24} md={24} lg={6}>
        <Row>
          <Col xs={24} md={12} lg={24}>
            <CounterContainerPanel>
              <span>Created outcomes</span>
              <h1>{outcomesCount}</h1>
            </CounterContainerPanel>
          </Col>
          <Col xs={24} md={12} lg={24}>
            <CounterContainerPanel>
              <span>Teams working on it</span>
              <h1>{teamsCount}</h1>
            </CounterContainerPanel>
          </Col>
          <Col xs={24} md={12} lg={24}>
            <CounterContainerPanel>
              <span>Members working on it</span>
              <h1>{membersCount}</h1>
            </CounterContainerPanel>
          </Col>
          <Col xs={24} md={12} lg={24}>
            <CounterContainerPanel>
              <span>Top contributor</span>
              <br />
              {topContributor ? (
                <UserDisplay
                  id={topContributor.id}
                  totalContribution={topContributor.total}
                  completionRate={topContributor.completionRate}
                />
              ) : (
                <div style={{ display: 'flex', alignItems: 'center', marginTop: 20, marginBottom: 20 }}>
                  <b>N/A</b>
                </div>
              )}
            </CounterContainerPanel>
          </Col>
          <Col xs={24}>
            <CounterContainerPanel>
              <span>Top Team</span>
              <br />
              {topTeam ? (
                <TeamDisplay
                  team={teamsMap[topTeam.id]}
                  totalContribution={topTeam.total}
                  completionRate={topTeam.completionRate}
                />
              ) : (
                <div style={{ display: 'flex', alignItems: 'center', marginTop: 20, marginBottom: 20 }}>
                  <b>N/A</b>
                </div>
              )}
            </CounterContainerPanel>
          </Col>
        </Row>
      </Col>
      <Col xs={24} md={24} lg={18}>
        <Row>
          <Col xs={24} md={12}>
            <ContainerPanel>
              <span>Outcomes status</span>
              <PieChart
                data={
                  outcomesCompletedCount === 0 && outcomesPendingCount === 0
                    ? []
                    : [
                        ['Completed', outcomesCompletedCount],
                        ['Pending', outcomesPendingCount],
                      ]
                }
                color={[Colors.COMPLETED, Colors.PENDING]}
                loading={loading}
              />
            </ContainerPanel>
          </Col>

          <Col xs={24} md={12}>
            <ContainerPanel>
              <span>Teams by outcomes</span>
              <BarChart data={teamsByOutcomes} loading={loading}>
                <Bars name='Completed' color={Colors.COMPLETED} stack='a' />
                <Bars name='Pending' color={Colors.PENDING} stack='a' />
              </BarChart>
            </ContainerPanel>
          </Col>

          <Col xs={24}>
            <ContainerPanel>
              <span>Members by outcomes</span>
              <BarChart data={usersByOutcomes} loading={loading}>
                <Bars name='Completed' color={Colors.COMPLETED} stack='a' />
                <Bars name='Pending' color={Colors.PENDING} stack='a' />
              </BarChart>
            </ContainerPanel>
          </Col>
        </Row>
      </Col>
      {calendar.filter !== FILTER_BY.all.name && (
        <Col xs={24} md={24} lg={24}>
          <ContainerPanel>
            <span>Outcomes history</span>
            <LineChart data={outcomesPerDay} loading={loading}>
              <Line name='Total' />
              <Line name='Completed' color={Colors.COMPLETED} />
              <Line name='Pending' color={Colors.PENDING} />
            </LineChart>
          </ContainerPanel>
        </Col>
      )}
      <Col xs={24} md={24} lg={24}>
        <ContainerPanel>
          <span>Goals map</span>
          <TreeChart data={[goalTreeChart]} loading={loading} />
        </ContainerPanel>
      </Col>
    </Row>
  );
};

export default GoalOutcomes;
