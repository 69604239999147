import React, { useState, useMemo } from 'react';
import Styled from 'styled-components';
import moment from 'moment';
import { Row, Col, Panel, Placeholder, Input, Button, DatePicker, CheckboxGroup,
  Checkbox } from 'rsuite';
  import { get, includes, orderBy } from 'lodash';
import { Colors, Achievement as AchievementIcon } from '../assets';
import { useGoals } from '../hooks';
import GoalItem from './GoalItem';
import { Mixpanel } from '../services';

const ContainerPanel = Styled(Panel)({
  backgroundColor: Colors.WHITE,
});

const GoalOutcomes = ({ data: goal, onSelect, teamId, ...props }) => {
  const { loading, goals, create } = useGoals({ parent: goal.id });
  const [newGoal, setNewGoal] = useState('');
  const [newGoalDate, setNewGoalDate] = useState(null);
  const [filter, setFilter] = useState(['showCompleted']);

  const filteredGoals = useMemo(
    () =>
      goals.list.filter(
        (t) =>
          ((!includes(filter, 'showCompleted') && !t.completedAt) || includes(filter, 'showCompleted')) &&
          ((!includes(filter, 'showArchived') && !t.archivedAt) || includes(filter, 'showArchived'))
      ),
    [goals.list, filter],
  );

  const orderedGoals = useMemo(
    () =>
      orderBy(
        filteredGoals,
        [
          (t) => (t.archived ? 1 : 0),
          (t) => (get(t, 'dueDate') ? get(t, 'dueDate').toDate() : 0),
        ],
        ['asc', 'asc'],
      ),
    [filteredGoals],
  );

  return (
    <>
      <Row gutter={16} style={{ marginBottom: 20 }}>
        <Col xs={24} style={{ display: 'flex', alignItems: 'flex-end', marginBottom: 20 }}>
          <div style={{ flex: 1 }}>
            <h6>Sub-Goals</h6>
          </div>
          <div>
          <ContainerPanel>
            <CheckboxGroup inline name='goal-checkbox-filter' value={filter} onChange={setFilter}>
              <Checkbox value='showCompleted'>Show Completed</Checkbox>
              <Checkbox value='showArchived'>Show Archived</Checkbox>
            </CheckboxGroup>
          </ContainerPanel>
          </div>
        </Col>

        <Col xs={24}>
          <Row gutter={16} style={{ minHeight: '55vh' }}>
            <Col>
              {loading ? (
                <Row gutter={16}>
                  <Col xs={24} style={{ marginBottom: 10 }}>
                    <ContainerPanel style={{ minHeight: 80 }}>
                      <Placeholder.Paragraph graph='square' />
                    </ContainerPanel>
                  </Col>
                  <Col xs={24} style={{ marginBottom: 10 }}>
                    <ContainerPanel style={{ minHeight: 80 }}>
                      <Placeholder.Paragraph graph='square' />
                    </ContainerPanel>
                  </Col>
                  <Col xs={24} style={{ marginBottom: 10 }}>
                    <ContainerPanel style={{ minHeight: 80 }}>
                      <Placeholder.Paragraph graph='square' />
                    </ContainerPanel>
                  </Col>
                  <Col xs={24} style={{ marginBottom: 10 }}>
                    <ContainerPanel style={{ minHeight: 80 }}>
                      <Placeholder.Paragraph graph='square' />
                    </ContainerPanel>
                  </Col>
                  <Col xs={24} style={{ marginBottom: 10 }}>
                    <ContainerPanel style={{ minHeight: 80 }}>
                      <Placeholder.Paragraph graph='square' />
                    </ContainerPanel>
                  </Col>
                </Row>
              ) : orderedGoals.length ? (
                orderedGoals.map((goal) => (
                  <div style={{marginBottom: 10}}>
                    <GoalItem key={`goal-${goal.id}`} data={goal} onSelect={onSelect} teamId={teamId}/></div>
                ))
              ) : (
                <Row gutter={16}>
                  <Col xs={24} style={{ marginBottom: 10 }}>
                    <ContainerPanel
                      style={{
                        minHeight: 90,
                        textAlign: 'center',
                        paddingTop: 40,
                        paddingBottom: 40,
                      }}
                    >
                      <img src={AchievementIcon} style={{ width: '100%', maxWidth: 400 }} />
                      <h5 style={{ marginTop: 20 }}>No sub-goals found</h5>
                      <span>You can start by creating one bellow</span>
                    </ContainerPanel>
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
      <Row
        gutter={16}
        style={{
          bottom: 0,
          paddingBottom: 20,
          paddingTop: 20,
          backgroundColor: Colors.CLOUD,
          position: 'sticky',
          zIndex: 6,
        }}
      >
        <Col xs={20}>
          <ContainerPanel>
            <Row>
              <Col xs={20}>
                <Input placeholder='New goal' style={{ border: 'none' }} value={newGoal} onChange={setNewGoal} />
              </Col>
              <Col xs={4} style={{ textAlign: 'right' }}>
                <DatePicker
                  format='DD MMM, YYYY'
                  placeholder='No due date'
                  appearance='subtle'
                  placement='topStart'
                  value={newGoalDate}
                  onChange={setNewGoalDate}
                  cleanable={false}
                  ranges={[
                    {
                      label: 'yesterday',
                      value: moment().add(-1, 'day').toDate(),
                    },
                    {
                      label: 'today',
                      value: new Date(),
                    },
                    ...(newGoalDate
                      ? [
                          {
                            label: 'Remove',
                            value: null,
                          },
                        ]
                      : []),
                  ]}
                  oneTap
                />
              </Col>
            </Row>
          </ContainerPanel>
        </Col>
        <Col xs={4}>
          <Button
            block
            appearance='primary'
            style={{ height: 74, fontSize: 18 }}
            onClick={async () => {
              await create({
                displayName: newGoal,
                dueDate: newGoalDate,
                tagColor: goal.tagColor,
                organizational: goal.organizational,
                team: goal.team,
                goalSequence: goal.goalSequence,
              });
              Mixpanel.track('Created Goal', { organizational: goal.organizational, fromSubGoal: true });
              Mixpanel.people.increment("Goals Created");
              setNewGoal('');
              setNewGoalDate(null);
            }}
            disabled={!newGoal}
          >
            Add Goal
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default GoalOutcomes;
