import React from 'react';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

import config from '../config';

if (config.bugsnag.enabled) {
  Bugsnag.start({
    ...config.bugsnag,
    plugins: [new BugsnagPluginReact()],
  });
}

export const ErrorBoundary =
  Bugsnag.getPlugin('react')?.createErrorBoundary(React) || (({ children }) => <div>{children}</div>);

export default Bugsnag;
