import React from 'react';
import { createRoot } from 'react-dom/client';
import 'animate.css';
import 'rsuite-color-picker/lib/styles.css';
import './assets/styles/rsuite.css';
import './assets/styles/lexical.css';
import App from './App';

const container = document.getElementById('root');
const root = createRoot(container); 
root.render(<App />);