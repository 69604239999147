import React, { useState, useCallback, useContext, useEffect } from 'react';
import { debounce, get, isEqual } from 'lodash';
import Styled from 'styled-components';
import {
  Row,
  Col,
  Dropdown,
  Icon,
  Popover,
  Whisper,
  IconButton,
  Notification,
  DatePicker,
  Progress,
  Panel,
  Button,
} from 'rsuite';
import { useHistory } from 'react-router-dom';
import moment from 'moment-timezone';

import { Bugsnag, Firebase, Mixpanel } from '../services';
import { OrganizationContext, LoggedUserContext } from '../context';
import { Colors } from '../assets';
import GoalTag from './GoalTag';
import AutogrowInput from './AutogrowInput';
import RichText from './RichText';

const ContainerPanel = Styled(Panel)({
  backgroundColor: Colors.WHITE,
});

const GoalDescription = ({ id, data, ...props }) => {
  const history = useHistory();
  const organization = useContext(OrganizationContext);
  const loggedUser = useContext(LoggedUserContext);
  const [description, setDescription] = useState(data);
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [saving, setSaving] = useState(false);
  const [hasFocused, setHasFocused] = useState(false);

  useEffect(() => {
    if (!isInputFocused) {
      setDescription(data);
    }
  }, [data]);

  const update = useCallback(
    async (description) => {
      setSaving(true);
      try {
        await Firebase.firestore()
          .collection('organizations')
          .doc(organization.id)
          .collection('private')
          .doc(organization.id)
          .collection('goals')
          .doc(id)
          .update({
            description,
            updatedBy: loggedUser.id,
            updatedAt: Firebase.firestore.FieldValue.serverTimestamp(),
          });
        Mixpanel.track('Updated Goal');
        Notification.success({
          title: 'Success',
          description: 'Description has been successfully updated.',
        });
      } catch (ex) {
        Notification.error({
          title: 'There has been an error',
          description: get(ex, 'message', 'Please try again...'),
        });
        Bugsnag.notify(ex, (event) => {
          event.severity = 'warning';
        });
      }
      setSaving(false);
    },
    [organization?.id, loggedUser?.id, id],
  );

  const updateWithDebounce = useCallback(debounce(update, 500), [update]);

  return (
    <Row gutter={16} style={{ marginBottom: 20 }}>
      <Col xs={24}>
        <h6 style={{ marginBottom: 10 }}>Description</h6>
        <ContainerPanel style={{ minHeight: 458 }}>
          <RichText
            placeholder={'Currently without description'}
            value={description}
            onFocus={() => {
              setIsInputFocused(true);
              setHasFocused(true);
            }}
            onBlur={() => setIsInputFocused(false)}
            onChange={setDescription}
            editorInputStyle={{ minHeight: hasFocused ? 340 : 376 }}
            extraUploadData={{ goal: id }}
            toolbar={hasFocused}
          />
          <div style={{ textAlign: 'right' }}>
            <Button
              appearance='primary'
              onClick={() => updateWithDebounce(description)}
              disabled={isEqual(data, description) || saving}
              loading={saving}
            >
              Save
            </Button>
          </div>
        </ContainerPanel>
      </Col>
    </Row>
  );
};

export default GoalDescription;
