import React, { createContext, useContext } from 'react';

import { TeamContext } from '.';
import { useGoals } from '../hooks';
export const Context = createContext();

const TeamGoalsContext = (props) => {
  const team = useContext(TeamContext);
  const {
    loading,
    goals: { list, map },
  } = useGoals({ team: team.id });

  return <Context.Provider value={{ loading, list, map }}>{props.children}</Context.Provider>;
};

export default TeamGoalsContext;
