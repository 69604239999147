import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { FlexboxGrid, Row, Col, Icon, Panel, DatePicker } from 'rsuite';
import Styled from 'styled-components';
import moment from 'moment-timezone';

import { OutcomesCard } from '../components';

import { Colors } from '../assets';
import { Firebase } from '../services';
import { formatDate } from '../utils';

const DateContainer = Styled(Panel)({
  width: '100%',
  backgroundColor: Colors.WHITE,
  paddingHorizontal: 20,
  marginBottom: 24,
});

const DatePickerContainer = Styled.div({
  position: 'absolute',
  opacity: 0,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
});

const Outcomes = ({ teamId, dateFormatted }) => {
  const [date, setDate] = useState(dateFormatted);
  const [team, setTeam] = useState();
  const users = useMemo(() => {
    if (!team) return [];
    return Object.keys(team.members).map((uid) => ({
      id: uid,
      ...team.members[uid],
    }));
  }, [team]);

  useEffect(() => {
    Firebase.firestore()
      .collection('teams')
      .doc(teamId)
      .get()
      .then((doc) => setTeam(doc.data()));
  }, [teamId]);

  const onChangeDate = useCallback((newDate) => setDate(moment(newDate).format('YYYY-MM-DD')), []);

  const changeDateWith = useCallback(
    (days) => setDate(moment(date)[days > 0 ? 'add' : 'subtract'](Math.abs(days), 'days').format('YYYY-MM-DD')),
    [date],
  );

  const OutcomesCards = useCallback(
    () =>
      users.map((user) => (
        <Col xs={24} md={12} lg={8}>
          <OutcomesCard teamId={teamId} dateFormatted={date} user={user} />
        </Col>
      )),
    [users, teamId, date],
  );

  return (
    <Row gutter={16}>
      <Col xs={24}>
        <DateContainer>
          <Row>
            <Col xs='4' style={{ cursor: 'pointer' }} onClick={() => changeDateWith(-1)}>
              <Icon icon='left' />
            </Col>
            <Col xs='16' style={{ textAlign: 'center', cursor: 'pointer' }}>
              <DatePickerContainer>
                <DatePicker
                  appearance='subtle'
                  placeholder='Subtle'
                  value={date}
                  onChange={onChangeDate}
                  cleanable={false}
                />
              </DatePickerContainer>
              <strong>{formatDate(date)}</strong>
            </Col>
            <Col xs='4' style={{ textAlign: 'right', cursor: 'pointer' }} onClick={() => changeDateWith(1)}>
              <Icon icon='right' />
            </Col>
          </Row>
        </DateContainer>
      </Col>
      <OutcomesCards />
    </Row>
  );
};

export default Outcomes;
