import React, { useState, useMemo, useContext, useEffect } from 'react';
import { Row, Col, Panel } from 'rsuite';
import { LineChart, BarChart, PieChart, Bars, Line } from '@rsuite/charts';
import Styled from 'styled-components';
import moment from 'moment-timezone';
import { get, orderBy, first, find, last } from 'lodash';
import {isMobile} from 'react-device-detect';

import { OrganizationUsersContext, TeamContext } from '../context';
import { DateSelector, UserDisplay, TeamDisplay, GoalDisplay } from '../components';
import { FILTER_BY } from '../components/DateSelector';
import { Firebase, Mixpanel } from '../services';
import { OrganizationContext, LoggedUserContext, OrganizationTeamsContext } from '../context';
import { Colors } from '../assets';
import { docsToArray, groupGoalsWithParents } from '../utils';
import { useGoals } from '../hooks';

const OTHER_DATES_LENGTH = 5;

const CounterContainerPanel = Styled(Panel)({
  backgroundColor: Colors.WHITE,
  marginBottom: 20,
  paddingTop: 6,
  paddingBottom: 5,
});

const Container = Styled.div({
  margin: 30,
});

const Title = Styled.h2({
  marginBottom: 30,
});

const ContainerPanel = Styled(Panel)({
  backgroundColor: Colors.WHITE,
  marginBottom: 20,
});

const OrganizationDashboard = () => {
  const organization = useContext(OrganizationContext);
  const { list: organizationUsers, map: usersMap } = useContext(OrganizationUsersContext);
  const loggedUser = useContext(LoggedUserContext);
  const [analytics, setAnalytics] = useState({});
  const { map: teamsMap } = useContext(OrganizationTeamsContext);
  const [loading, setLoading] = useState(false);
  const [calendar, setCalendar] = useState({
    date: moment().format(FILTER_BY.day.format),
    format: FILTER_BY.day.format,
    filter: FILTER_BY.day.name,
  });
  const { goals } = useGoals({ parent: null, organizational: true });
  const [top5Goals, setTop5Goals] = useState([]);
  const [top5Members, setTop5Members] = useState([]);
  const [top5Teams, setTop5Teams] = useState([]);

  const parentGoalIds = useMemo(() => {
    if (!goals) return [];
    return groupGoalsWithParents(goals.list).map((g) => g.id);
  }, [goals]);

  useEffect(() => {
    let subscriptions;
    setLoading(true);
    if (organization?.id) {
      subscriptions = [...Array(calendar.filter === FILTER_BY.all.name ? 1 : OTHER_DATES_LENGTH).keys()]
        .map((minus) =>
          calendar.filter === FILTER_BY.all.name
            ? 'ALL'
            : moment(calendar.date, calendar.format)
                .subtract(minus, FILTER_BY[calendar.filter].subtract)
                .format(calendar.format),
        )
        .map((dateDoc) =>
          Firebase.firestore()
            .collection('organizations')
            .doc(organization.id)
            .collection('private')
            .doc(organization.id)
            .collection('analytics')
            .doc(dateDoc)
            .onSnapshot((snap) => {
              setAnalytics((currentAnalytics) => ({ ...currentAnalytics, [dateDoc]: snap.data() }));
              setLoading(false);
            }),
        );
    }
    return () => subscriptions && subscriptions.length && subscriptions.map((s) => s());
  }, [organization?.id, calendar]);

  useEffect(() => {
    let subscription;
    if (organization?.id) {
      subscription = Firebase.firestore()
        .collection('organizations')
        .doc(organization.id)
        .collection('private')
        .doc(organization.id)
        .collection('analytics')
        .doc(
          calendar.filter === FILTER_BY.all.name
            ? 'ALL'
            : moment(calendar.date, calendar.format).format(calendar.format),
        )
        .collection('byGoal')
        .where('total', '>', 0)
        .where('organizationalParent', '==', true)
        .orderBy('total', 'desc')
        .limit(5)
        .onSnapshot((docs) => {
          setTop5Goals(docsToArray(docs).map((a) => ({ ...a, completionRate: (a.completed || 0) / (a.total || 0) })));
        });
    }
    return () => subscription && subscription();
  }, [organization?.id, calendar]);

  useEffect(() => {
    let subscription;
    if (organization?.id) {
      subscription = Firebase.firestore()
        .collection('organizations')
        .doc(organization.id)
        .collection('private')
        .doc(organization.id)
        .collection('analytics')
        .doc(
          calendar.filter === FILTER_BY.all.name
            ? 'ALL'
            : moment(calendar.date, calendar.format).format(calendar.format),
        )
        .collection('byMember')
        .where('total', '>', 0)
        .orderBy('total', 'desc')
        .limit(5)
        .onSnapshot((docs) => {
          setTop5Members(docsToArray(docs).map((a) => ({ ...a, completionRate: (a.completed || 0) / (a.total || 0) })));
        });
    }
    return () => subscription && subscription();
  }, [organization?.id, calendar]);

  useEffect(() => {
    let subscription;
    if (organization?.id) {
      subscription = Firebase.firestore()
        .collection('organizations')
        .doc(organization.id)
        .collection('private')
        .doc(organization.id)
        .collection('analytics')
        .doc(
          calendar.filter === FILTER_BY.all.name
            ? 'ALL'
            : moment(calendar.date, calendar.format).format(calendar.format),
        )
        .collection('byTeam')
        .where('total', '>', 0)
        .orderBy('total', 'desc')
        .limit(5)
        .onSnapshot((docs) => {
          setTop5Teams(docsToArray(docs).map((a) => ({ ...a, completionRate: (a.completed || 0) / (a.total || 0) })));
        });
    }
    return () => subscription && subscription();
  }, [organization?.id, calendar]);

  const outcomesCount = useMemo(() => get(analytics[calendar.date], `total`, 0), [calendar, analytics]);
  const membersCount = useMemo(
    () =>
      Object.keys(get(analytics[calendar.date], `byMember`, {}))
        .map((id) => ({ id, ...get(analytics[calendar.date], `byMember.${id}`, {}) }))
        .filter((u) => u.total > 0).length,
    [calendar, analytics],
  );
  const topContributor = useMemo(() => {
    const result = first(top5Members) || {};

    return result.total > 0 ? result : null;
  }, [top5Members]);
  const topTeam = useMemo(() => {
    const result = first(top5Teams) || {};

    return result.total > 0 ? result : null;
  }, [top5Teams]);
  const topGoal = useMemo(() => first(top5Goals) || null, [top5Goals]);
  const outcomesCompletedCount = useMemo(() => get(analytics[calendar.date], `completed`, 0), [calendar, analytics]);
  const outcomesPendingCount = useMemo(() => get(analytics[calendar.date], `pending`, 0), [calendar, analytics]);
  const goalsByOutcomes = useMemo(
    () => top5Goals.map((t) => [get(goals.map, `${t.id}`, {}).displayName || 'Deleted Goal', t.completed, t.pending]),
    [top5Goals, goals],
  );
  const usersByOutcomes = useMemo(
    () => top5Members.map((u) => [get(usersMap, `${u.id}`, {}).displayName || 'Deleted User', u.completed, u.pending]),
    [top5Members, usersMap],
  );
  const teamsByOutcomes = useMemo(
    () => top5Teams.map((u) => [get(teamsMap, `${u.id}`, {}).displayName || 'Deleted Team', u.completed, u.pending]),
    [top5Teams, teamsMap],
  );
  const outcomesPerDay = useMemo(
    () =>
      [...Array(OTHER_DATES_LENGTH).keys()]
        .map((minus) => moment(calendar.date, calendar.format).subtract(minus, FILTER_BY[calendar.filter].subtract))
        .map((mappedDate) => [
          mappedDate.format(FILTER_BY[calendar.filter].formatOnDisplay),
          get(analytics[mappedDate.format(calendar.format)], `total`, 0),
          get(analytics[mappedDate.format(calendar.format)], `completed`, 0),
          get(analytics[mappedDate.format(calendar.format)], `pending`, 0),
        ])
        .reverse(),
    [calendar, analytics],
  );

  return (
    <Container>
      <Row gutter={16}>
        <Col xs={24} md={24} lg={16}>
          <Title>Dashboard</Title>
        </Col>
        <Col xs={24} md={24} lg={8}>
          <DateSelector
            onChange={(cal) => {
              Mixpanel.track('Filtered Organization Dashboard', { by: cal?.filter });
              setCalendar(cal);
            }}
            value={calendar.date}
            placement='bottomEnd'
            justifyContent={isMobile ? 'center' : 'flex-end'}
            advanced
          />
        </Col>
      </Row>
      <Row gutter={16} style={{ paddingTop: 30 }}>
        <Col xs={24} md={24} lg={24}>
          <Row gutter={16}>
            <Col xs={24} md={24} lg={6}>
              <CounterContainerPanel>
                <span>Created outcomes</span>
                <h1>{outcomesCount}</h1>
              </CounterContainerPanel>
            </Col>
            <Col xs={24} md={8} lg={6}>
              <CounterContainerPanel>
                <span>Top team</span>
                <br />
                {topTeam ? (
                  <TeamDisplay
                    team={teamsMap[topTeam.id]}
                    totalContribution={topTeam.total}
                    completionRate={topTeam.completionRate}
                  />
                ) : (
                  <div style={{ display: 'flex', alignItems: 'center', marginTop: 20, marginBottom: 20 }}>
                    <b>N/A</b>
                  </div>
                )}
              </CounterContainerPanel>
            </Col>
            <Col xs={24} md={8} lg={6}>
              <CounterContainerPanel>
                <span>Top contributor</span>
                <br />
                {topContributor ? (
                  <UserDisplay
                    id={topContributor.id}
                    totalContribution={topContributor.total}
                    completionRate={topContributor.completionRate}
                  />
                ) : (
                  <div style={{ display: 'flex', alignItems: 'center', marginTop: 20, marginBottom: 20 }}>
                    <b>N/A</b>
                  </div>
                )}
              </CounterContainerPanel>
            </Col>
            <Col xs={24} md={8} lg={6}>
              <CounterContainerPanel>
                <span>Top Goal</span>
                <br />
                {topGoal ? (
                  <GoalDisplay
                    goal={{ ...goals.map[topGoal.id], id: topGoal.id }}
                    totalContribution={topGoal.total}
                    completionRate={topGoal.completionRate}
                  />
                ) : (
                  <div style={{ display: 'flex', alignItems: 'center', marginTop: 20, marginBottom: 20 }}>
                    <b>N/A</b>
                  </div>
                )}
              </CounterContainerPanel>
            </Col>
          </Row>
        </Col>
        <Col xs={24} md={24} lg={24}>
          <Row gutter={16}>
            <Col xs={24} md={8}>
              <ContainerPanel>
                <span>Outcomes status</span>
                <PieChart
                  data={
                    outcomesCompletedCount === 0 && outcomesPendingCount === 0
                      ? []
                      : [
                          ['Completed', outcomesCompletedCount],
                          ['Pending', outcomesPendingCount],
                        ]
                  }
                  color={[Colors.COMPLETED, Colors.PENDING]}
                  loading={loading}
                />
              </ContainerPanel>
            </Col>

            <Col xs={24} md={16}>
              <ContainerPanel>
                <span>Goals by outcomes</span>
                <BarChart data={goalsByOutcomes} loading={loading}>
                  <Bars name='Completed' color={Colors.COMPLETED} stack='a' />
                  <Bars name='Pending' color={Colors.PENDING} stack='a' />
                </BarChart>
              </ContainerPanel>
            </Col>

            <Col xs={24} md={12}>
              <ContainerPanel>
                <span>Teams by outcomes</span>
                <BarChart data={teamsByOutcomes} loading={loading}>
                  <Bars name='Completed' color={Colors.COMPLETED} stack='a' />
                  <Bars name='Pending' color={Colors.PENDING} stack='a' />
                </BarChart>
              </ContainerPanel>
            </Col>

            <Col xs={24} md={12}>
              <ContainerPanel>
                <span>Members by outcomes</span>
                <BarChart data={usersByOutcomes} loading={loading}>
                  <Bars name='Completed' color={Colors.COMPLETED} stack='a' />
                  <Bars name='Pending' color={Colors.PENDING} stack='a' />
                </BarChart>
              </ContainerPanel>
            </Col>

            {calendar.filter !== FILTER_BY.all.name && (
              <Col xs={24} md={24} lg={24}>
                <ContainerPanel>
                  <span>Outcomes history</span>
                  <LineChart data={outcomesPerDay} loading={loading}>
                    <Line name='Total' />
                    <Line name='Completed' color={Colors.COMPLETED} />
                    <Line name='Pending' color={Colors.PENDING} />
                  </LineChart>
                </ContainerPanel>
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default OrganizationDashboard;
