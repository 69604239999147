import React, { createContext, useState, useEffect, useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import { Firebase } from '../services';

import { OrganizationContext } from '.';
export const Context = createContext();

const TeamContext = (props) => {
  const organization = useContext(OrganizationContext);
  const { teamId } = useParams();
  const history = useHistory();
  const [team, setTeam] = useState({});

  useEffect(() => {
    let subscription;
    if (organization.id && teamId) {
      subscription = Firebase.firestore()
        .collection('organizations')
        .doc(organization.id)
        .collection('private')
        .doc(organization.id)
        .collection('teams')
        .doc(teamId)
        .onSnapshot(
          (snapshot) =>
            snapshot.exists && !snapshot.data().disabled
              ? setTeam({ ...snapshot.data(), id: snapshot.id })
              : history.push(`/${organization.id}/teams`),
          (ex) => history.push(`/${organization.id}/teams`),
        );
    } else {
      setTeam({});
    }
    return () => subscription && subscription();
  }, [organization.id, teamId]);

  return <Context.Provider value={team}>{props.children}</Context.Provider>;
};

export default TeamContext;
