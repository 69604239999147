import React, { useState } from 'react';

import { BrandLayout } from '../layouts';
import { DemoCard } from '../components';

const Demo = () => {
  const [background, setBackground] = useState(null);
  return (
    <BrandLayout background={background}>
      <DemoCard  setBackground={setBackground}/>
    </BrandLayout>
  );
};

export default Demo;
