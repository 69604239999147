import React, { useContext, useState, useMemo, useCallback, useEffect } from 'react';
import {
  Row,
  Col,
  PanelGroup,
  Panel,
  InputGroup,
  Icon,
  Input,
  Button,
  Modal,
  Loader,
  InputPicker,
  CheckboxGroup,
  Checkbox,
  DatePicker,
  Placeholder,
  Notification,
} from 'rsuite';
import { useLocation } from 'react-router-dom';
import Styled from 'styled-components';
import { find, get, groupBy, includes, orderBy } from 'lodash';
import moment from 'moment-timezone';

import { LoggedUserContext, OrganizationContext } from '../context';
import { Colors, Achievement as AchievementIcon } from '../assets';
import { GoalForm, GoalItem, GoalSelectPicker } from '../components';
import { Bugsnag, Firebase, Mixpanel } from '../services';
import { docsToArray, generateIdBasedOn, groupGoalsWithParents, randomDarkColor, uuidv4 } from '../utils';
import { DEFAULT_VALUE } from '../components/RichText';
import { isMobile } from 'react-device-detect';

const Container = Styled.div({
  padding: 30,
});

const Title = Styled.h2({
  marginBottom: 30,
});

const ContainerPanel = Styled(Panel)({
  backgroundColor: Colors.WHITE,
});

const GoalPanelGroup = Styled(PanelGroup)({
  backgroundColor: Colors.WHITE,
});

const months = moment.months();

const currentYear = Number(moment().format('YYYY'));

const yearOptions = [...[...Array(4).keys()].map((n) => currentYear + n + 1).reverse(), ...[...Array(50).keys()].map((n) => currentYear - n)].map((r) => ({
  label: r,
  value: r,
}));

const OrganizationGoals = ({ setExpand }) => {
  const loggedUser = useContext(LoggedUserContext);
  const organization = useContext(OrganizationContext);
  const query = new URLSearchParams(useLocation().search);
  const [year, setYear] = useState(Number(query.get('year')) || currentYear);
  const [loading, setLoading] = useState(true);
  const [goals, setGoals] = useState([]);
  const [search, setSearch] = useState('');
  const [newGoal, setNewGoal] = useState('');
  const [newGoalDate, setNewGoalDate] = useState(null);
  const [newGoalParent, setNewGoalParent] = useState(null);
  const [filter, setFilter] = useState(['showCompleted']);

  const filteredGoals = useMemo(
    () =>
      goals.filter(
        (t) =>
          (t.displayName || '').toLowerCase().search(search.toLowerCase()) >= 0 &&
          ((!includes(filter, 'showCompleted') && !t.completedAt) || includes(filter, 'showCompleted')) &&
          ((!includes(filter, 'showArchived') && !t.archivedAt) || includes(filter, 'showArchived')) &&
          (!t.dueDate || Number(moment(t.dueDate.toDate()).format('YYYY')) === year),
      ),
    [goals, search, filter, year],
  );

  const orderedGoals = useMemo(
    () =>
      orderBy(
        filteredGoals,
        [
          (t) => (t.archived ? 1 : 0),
          (t) => (get(t, 'dueDate') ? get(t, 'dueDate').toDate() : 0),
        ],
        ['desc', 'desc'],
      ),
    [filteredGoals],
  );

  const groupedGoals = useMemo(
    () =>
      groupBy(groupGoalsWithParents(orderedGoals), (g) =>
        g.dueDate ? moment(g.dueDate.toDate()).format('MMMM, YYYY') : 'Without due date',
      ),
    [orderedGoals],
  );

  useEffect(() => {
    const subscription = Firebase.firestore()
      .collection('organizations')
      .doc(organization.id)
      .collection('private')
      .doc(organization.id)
      .collection('goals')
      .where('deletedAt', '==', null)
      .where('organizational', '==', true)
      .onSnapshot((docs) => {
        setGoals(docsToArray(docs));
        setLoading(false);
      });
    return () => {
      subscription();
    };
  }, [organization.id]);

  const createNewGoal = useCallback(async () => {
    try {
      if (!newGoal) return;
      let id = generateIdBasedOn(newGoal);
      const doc = await Firebase.firestore()
        .collection('organizations')
        .doc(organization.id)
        .collection('private')
        .doc(organization.id)
        .collection('goals')
        .doc(id)
        .get();
      if (doc.exists) {
        id += `-${uuidv4().split('-')[0]}`;
        if (
          (
            await Firebase.firestore()
              .collection('organizations')
              .doc(organization.id)
              .collection('private')
              .doc(organization.id)
              .collection('goals')
              .doc(id)
              .get()
          ).exists
        ) {
          throw new Error('unique id already exists');
        }
      }
      await Firebase.firestore()
        .collection('organizations')
        .doc(organization.id)
        .collection('private')
        .doc(organization.id)
        .collection('goals')
        .doc(id)
        .set({
          displayName: newGoal,
          dueDate: newGoalDate,
          tagColor: randomDarkColor(),
          parent: newGoalParent,
          createdBy: loggedUser.id,
          updatedBy: loggedUser.id,
          createdAt: Firebase.firestore.FieldValue.serverTimestamp(),
          updatedAt: Firebase.firestore.FieldValue.serverTimestamp(),
          deletedAt: null,
          archivedAt: null,
          completedAt: null,
          team: null,
          organizational: true,
          goalSequence: newGoalParent ? [...(find(goals, ['id', newGoalParent]).goalSequence || []), id] : [id],
          status: 'On Track',
          description: DEFAULT_VALUE,
        });
      Mixpanel.track('Created Goal', { organizational: true });
      Mixpanel.people.increment("Goals Created");
      setNewGoal('');
      setNewGoalDate(null);
      Notification.success({
        title: 'Success',
        description: "Goal has been created and it's ready to be used by the entire organization",
      });
    } catch (ex) {
      Notification.error({
        title: 'There has been an error',
        description: get(ex, 'message', 'Please try again...'),
      });
      Bugsnag.notify(ex, (event) => {
        event.severity = 'warning';
      });
    }
  }, [newGoal, newGoalDate, loggedUser.id, organization.id, newGoalParent, goals]);

  return (
    <Container>
      <Row gutter={16}>
        <Col xs={16}>
          <Title>Organization Goals</Title>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col md={24} lg={14} style={{ marginBottom: 20 }}>
          <ContainerPanel>
            <InputGroup inside>
              <Input placeholder='search' style={{ border: 'none' }} value={search} onChange={setSearch} />
              <InputGroup.Button>
                <Icon icon='search' />
              </InputGroup.Button>
            </InputGroup>
          </ContainerPanel>
        </Col>
        <Col md={12} lg={4} style={{ marginBottom: 20 }}>
          <ContainerPanel>
            <InputPicker
              style={{ border: 'none', display: 'block' }}
              value={year}
              onChange={setYear}
              data={yearOptions}
              cleanable={false}
            />
          </ContainerPanel>
        </Col>
        <Col md={12} lg={6} style={{ marginBottom: 20 }}>
          <ContainerPanel>
            <CheckboxGroup inline name='goal-checkbox-filter' value={filter} onChange={setFilter}>
              <Checkbox value='showCompleted'>Show Completed</Checkbox>
              <Checkbox value='showArchived'>Show Archived</Checkbox>
            </CheckboxGroup>
          </ContainerPanel>
        </Col>
      </Row>
      <Row gutter={16} style={{ minHeight: '66vh' }}>
        <Col>
          {loading ? (
            <Row gutter={16}>
              <Col xs={24} style={{ marginBottom: 10 }}>
                <ContainerPanel style={{ minHeight: 80 }}>
                  <Placeholder.Paragraph graph='square' />
                </ContainerPanel>
              </Col>
              <Col xs={24} style={{ marginBottom: 10 }}>
                <ContainerPanel style={{ minHeight: 80 }}>
                  <Placeholder.Paragraph graph='square' />
                </ContainerPanel>
              </Col>
              <Col xs={24} style={{ marginBottom: 10 }}>
                <ContainerPanel style={{ minHeight: 80 }}>
                  <Placeholder.Paragraph graph='square' />
                </ContainerPanel>
              </Col>
              <Col xs={24} style={{ marginBottom: 10 }}>
                <ContainerPanel style={{ minHeight: 80 }}>
                  <Placeholder.Paragraph graph='square' />
                </ContainerPanel>
              </Col>
              <Col xs={24} style={{ marginBottom: 10 }}>
                <ContainerPanel style={{ minHeight: 80 }}>
                  <Placeholder.Paragraph graph='square' />
                </ContainerPanel>
              </Col>
            </Row>
          ) : Object.keys(groupedGoals).length > 0 ? (
            Object.keys(groupedGoals).map((group) => (
              <Row gutter={16}>
                <Col xs={24}>
                  <h6 style={{ marginBottom: 10 }}>{group}</h6>
                </Col>
                {groupedGoals[group].map((goal, index) => (
                  <Col xs={24} style={{ marginBottom: 10 }}>
                    <GoalItem key={`goal-${goal.id}`} data={goal} />
                  </Col>
                ))}
              </Row>
            ))
          ) : (
            <Row gutter={16}>
              <Col xs={24} style={{ marginBottom: 10 }}>
                <ContainerPanel
                  style={{
                    minHeight: 90,
                    textAlign: 'center',
                    paddingTop: 40,
                    paddingBottom: 40,
                  }}
                >
                  <img src={AchievementIcon} style={{ width: '100%', maxWidth: 400 }} />
                  <h5 style={{ marginTop: 20 }}>No goals found</h5>
                  <span>You can start by creating one bellow</span>
                </ContainerPanel>
              </Col>
            </Row>
          )}
        </Col>
      </Row>

      <Row
        gutter={16}
        style={{
          bottom: 0,
          paddingBottom: 20,
          paddingTop: 20,
          backgroundColor: Colors.CLOUD,
          position: 'sticky',
          zIndex: 6,
        }}
      >
        <Col xs={24} md={20} style={isMobile ? { marginBottom: 20 } : {}}>
          <ContainerPanel>
            <Row>
              <Col xs={24} md={16}>
                <Input placeholder='New goal' style={{ border: 'none' }} value={newGoal} onChange={setNewGoal} />
              </Col>
              <Col xs={24} md={8} style={{ textAlign: 'right' }}>
                <DatePicker
                  format='DD MMM, YYYY'
                  placeholder='No due date'
                  appearance='subtle'
                  placement='topStart'
                  value={newGoalDate}
                  onChange={setNewGoalDate}
                  cleanable={false}
                  ranges={[
                    {
                      label: 'yesterday',
                      value: moment().add(-1, 'day').toDate(),
                    },
                    {
                      label: 'today',
                      value: new Date(),
                    },
                    ...(newGoalDate
                      ? [
                          {
                            label: 'Remove',
                            value: null,
                          },
                        ]
                      : []),
                  ]}
                  oneTap
                />
                <GoalSelectPicker
                  preloadedGoals={goals}
                  placeholder='Parent Goal'
                  placement='topStart'
                  onSelect={setNewGoalParent}
                  value={newGoalParent}
                  organizational={true}
                  cleanable={false}
                  appearance='subtle'
                  small
                />
              </Col>
            </Row>
          </ContainerPanel>
        </Col>
        <Col xs={24} md={4}>
          <Button
            block
            appearance='primary'
            style={{ height: 74, fontSize: 18 }}
            onClick={createNewGoal}
            disabled={!newGoal}
          >
            Add Goal
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default OrganizationGoals;
