import React, { useState, useCallback, useRef, useEffect } from 'react';
import { get, debounce } from 'lodash';
import Styled from 'styled-components';
import {
  Panel,
  Button,
  Notification,
  FlexboxGrid,
  InputGroup,
  Icon,
  Form,
  Schema,
  Whisper,
  Tooltip,
  Animation,
  Avatar,
  Row,
  Col,
} from 'rsuite';

import { Bugsnag, Firebase, Mixpanel } from '../services';
import { Logo, FormGroup } from '../components';
import { Colors } from '../assets';
import { useHistory, useLocation, useParams } from 'react-router-dom';

const LoginPanel = Styled(Panel)({
  backgroundColor: '#ffffff',
});
const Content = Styled.div({});
const Section = Styled.div({
  // marginBottom: 20,
});
const ButtonContainer = Styled.div({
  // marginBottom: 10,
});
const SignUpLink = Styled.p({
  textAlign: 'center',
});

const LogoImage = Styled.div(({ url }) => ({
  backgroundImage: `url('${url}')`,
  backgroundPosition: 'center',
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  backgroundColor: Colors.TAGLINE,
  width: '100%',
  height: '100%',
  marginRight: 'auto',
  marginLeft: 'auto',
  borderRadius: 8,
  cursor: 'pointer',
}));

const { StringType } = Schema.Types;
const accountFormModel = Schema.Model({
  email: StringType().isRequired('This field is required').isEmail('Please enter a valid email'),
});

const { Collapse } = Animation;

const DemoCard = ({ linkToSignUp, setBackground }) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const accountFormRef = useRef(null);
  const query = new URLSearchParams(useLocation().search);
  const [accountFormValue, setAccountFormValue] = useState({ email: query.get('email') });
  const [accountFormError, setAccountFormError] = useState({});

  const generateDemo = useCallback(async () => {
    setLoading(true);
    try {
      const {
        data: { tenantId, email, password },
      } = await Firebase.functions().httpsCallable('GenerateDemo')({
        email: accountFormValue.email,
      });
      Firebase.auth().tenantId = tenantId;
      await Firebase.auth().signInWithEmailAndPassword(email, password);
      Mixpanel.track('Requested Demo', { email: accountFormValue.email});
      Notification.success({
        title: 'Success',
        description: 'A demo account has been generated for you.',
      });
    } catch (ex) {
      console.error(ex);
      Notification.error({
        title: 'There has been an error',
        description: 'Please try again later...',
      });
      Bugsnag.notify(ex, (event) => {
        event.severity = 'warning';
      });
    }
    setLoading(false);
  }, [accountFormValue]);

  return (
    <LoginPanel shaded>
      <Content>
        <Logo marginBottom={30} />
        <Section>
          <Form
            fluid
            key='accountForm'
            ref={accountFormRef}
            onChange={setAccountFormValue}
            onCheck={setAccountFormError}
            formValue={accountFormValue}
            model={accountFormModel}
          >
            <FormGroup
              label='Email'
              name='email'
              error={!!accountFormError.email}
              errorMessage={accountFormError.email}
              disabled={loading}
            />
            <ButtonContainer>
              <FlexboxGrid justify='end'>
                <FlexboxGrid.Item colspan={24}>
                  <Button
                    appearance='primary'
                    block
                    disabled={!accountFormValue.email || accountFormError.email || loading}
                    onClick={() => generateDemo()}
                    loading={loading}
                  >
                    Request Demo
                  </Button>
                </FlexboxGrid.Item>
              </FlexboxGrid>
            </ButtonContainer>
          </Form>
        </Section>
      </Content>
    </LoginPanel>
  );
};

export default DemoCard;
