import React from 'react';
import { Avatar } from 'rsuite';

import { Colors } from '../assets';

const TeamDisplay = ({ team, totalContribution, completionRate, style = {}, ...props }) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center', marginTop: 10, marginBottom: 10, ...style }}>
      <Avatar
        square
        size='md'
        style={{
          backgroundColor: Colors.TAGLINE,
          marginRight: 10,
          minWidth: 38,
        }}
        src={team?.photoURL}
      >
        {!team?.photoURL ? (team?.displayName || 'Deleted Team').substring(0, 2) : null}
      </Avatar>
      <div style={{ marginRight: 4, display: 'grid' }}>
        <p
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            fontWeight: 'bold',
            display: 'block',
          }}
        >
          {team?.displayName || 'Deleted Team'}
        </p>
        <span>{`${totalContribution} outcomes - ${(completionRate * 100).toFixed(2)}% completed`}</span>
      </div>
    </div>
  );
};

export default TeamDisplay;
