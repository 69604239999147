import React from 'react';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { AutoFocusPlugin } from '@lexical/react/LexicalAutoFocusPlugin';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { TablePlugin } from '@lexical/react/LexicalTablePlugin';
import { HeadingNode, QuoteNode } from '@lexical/rich-text';
import { TableCellNode, TableNode, TableRowNode } from '@lexical/table';
import { ListItemNode, ListNode } from '@lexical/list';
import { CodeHighlightNode, CodeNode } from '@lexical/code';
import { AutoLinkNode, LinkNode } from '@lexical/link';
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { MarkdownShortcutPlugin } from '@lexical/react/LexicalMarkdownShortcutPlugin';
import { CheckListPlugin } from '@lexical/react/LexicalCheckListPlugin';
import { TRANSFORMERS } from '@lexical/markdown';
import { HorizontalRuleNode } from '@lexical/react/LexicalHorizontalRuleNode';

import {
  TreeViewPlugin,
  ToolbarPlugin,
  ListMaxIndentLevelPlugin,
  CodeHighlightPlugin,
  AutoLinkPlugin,
  UpdatePlugin,
  FocusBlurPlugin,
  TableActionMenuPlugin,
  TextFormatFloatingToolbarPlugin,
  MentionsPlugin,
  ReadOnlyPlugin,
  HorizontalRulePlugin,
  ImagesPlugin,
  OnKeyPressPlugin,
  JiraTicketPlugin,
  OnEditorUpdatePlugin,
  ClickableLinkPlugin,
} from '../lexical-plugins';
import { MentionNode, ImageNode, JiraTicketNode } from '../lexical-nodes';

function Placeholder({ text, className }) {
  return <div className={className || 'editor-placeholder'}>{text || 'Placeholder'}</div>;
}

export const DEFAULT_VALUE = {
  root: {
    children: [{ children: [], direction: null, format: '', indent: 0, type: 'paragraph', version: 1 }],
    direction: null,
    format: '',
    indent: 0,
    type: 'root',
    version: 1,
  },
};

const editorConfig = {
  // The editor theme
  theme: {
    ltr: 'ltr',
    rtl: 'rtl',
    placeholder: 'editor-placeholder',
    paragraph: 'editor-paragraph',
    quote: 'editor-quote',
    heading: {
      h1: 'editor-heading-h1',
      h2: 'editor-heading-h2',
      h3: 'editor-heading-h3',
      h4: 'editor-heading-h4',
      h5: 'editor-heading-h5',
    },
    list: {
      nested: {
        listitem: 'editor-nested-listitem',
      },
      ol: 'editor-list-ol',
      ul: 'editor-list-ul',
      listitem: 'editor-listitem',
      listitemChecked: 'editor-listItemChecked',
      listitemUnchecked: 'editor-listItemUnchecked',
    },
    image: 'editor-image',
    link: 'editor-link',
    text: {
      bold: 'editor-text-bold',
      italic: 'editor-text-italic',
      overflowed: 'editor-text-overflowed',
      hashtag: 'editor-text-hashtag',
      underline: 'editor-text-underline',
      strikethrough: 'editor-text-strikethrough',
      underlineStrikethrough: 'editor-text-underlineStrikethrough',
      code: 'editor-text-code',
    },
    code: 'editor-code',
    codeHighlight: {
      atrule: 'editor-tokenAttr',
      attr: 'editor-tokenAttr',
      boolean: 'editor-tokenProperty',
      builtin: 'editor-tokenSelector',
      cdata: 'editor-tokenComment',
      char: 'editor-tokenSelector',
      class: 'editor-tokenFunction',
      'class-name': 'editor-tokenFunction',
      comment: 'editor-tokenComment',
      constant: 'editor-tokenProperty',
      deleted: 'editor-tokenProperty',
      doctype: 'editor-tokenComment',
      entity: 'editor-tokenOperator',
      function: 'editor-tokenFunction',
      important: 'editor-tokenVariable',
      inserted: 'editor-tokenSelector',
      keyword: 'editor-tokenAttr',
      namespace: 'editor-tokenVariable',
      number: 'editor-tokenProperty',
      operator: 'editor-tokenOperator',
      prolog: 'editor-tokenComment',
      property: 'editor-tokenProperty',
      punctuation: 'editor-tokenPunctuation',
      regex: 'editor-tokenVariable',
      selector: 'editor-tokenSelector',
      string: 'editor-tokenSelector',
      symbol: 'editor-tokenProperty',
      tag: 'editor-tokenProperty',
      url: 'editor-tokenOperator',
      variable: 'editor-tokenVariable',
    },
    table: 'editor-table',
    tableCell: 'editor-tableCell',
    tableCellHeader: 'editor-tableCellHeader',
  },
  // Handling of errors during update
  onError(error) {
    throw error;
  },
  // Any custom nodes go here
  nodes: [
    HeadingNode,
    ListNode,
    ListItemNode,
    QuoteNode,
    CodeNode,
    CodeHighlightNode,
    TableNode,
    TableCellNode,
    TableRowNode,
    AutoLinkNode,
    LinkNode,
    MentionNode,
    HorizontalRuleNode,
    ImageNode,
    JiraTicketNode,
  ],
};

const RichText = ({
  toolbar = true,
  floatingToolbar = true,
  autoFocus = false,
  debug = false,
  readOnly = false,
  clickableLinks = false,
  placeholder,
  editorInputStyle,
  editorInnerStyle,
  value,
  onChange = () => {},
  onFocus = () => {},
  onBlur = () => {},
  onFileUpload = () => {},
  extraUploadData = {},
  onKeyPress = () => {},
  onEditorUpdate = () => {},
  placeholderClassName,
  editorInputClassName,
}) => {
  return (
    <div style={{ marginLeft: -4, marginRight: -4, width: '100%' }}>
      <LexicalComposer
        initialConfig={{
          ...editorConfig,
          editorState: (instance) => {},
        }}
      >
        <div className={`editor-container ${clickableLinks ? 'clickable-links': ''}`}>
          {toolbar && !readOnly && <ToolbarPlugin onFileUpload={onFileUpload} extraUploadData={extraUploadData}/>}
          <div className='editor-inner' style={editorInnerStyle}>
            <RichTextPlugin
              contentEditable={
                <ContentEditable
                  initialEditorState={value}
                  className={editorInputClassName || 'editor-input'}
                  style={editorInputStyle}
                />
              }
              placeholder={<Placeholder text={placeholder} className={placeholderClassName} />}
            />
            <HistoryPlugin />
            <CodeHighlightPlugin />
            <ListPlugin />
            <LinkPlugin />
            <AutoLinkPlugin />
            <CheckListPlugin />
            <TablePlugin />
            <TableActionMenuPlugin />
            {floatingToolbar && <TextFormatFloatingToolbarPlugin />}
            <MentionsPlugin />
            <HorizontalRulePlugin />
            <ImagesPlugin />
            <JiraTicketPlugin />
            <OnKeyPressPlugin onKeyPress={onKeyPress} />
            <ReadOnlyPlugin value={readOnly} />
            <OnChangePlugin
              onChange={(state) => onChange(JSON.parse(JSON.stringify(state)))}
            />
            <UpdatePlugin value={value} />
            <FocusBlurPlugin onFocus={onFocus} onBlur={onBlur} />
            <OnEditorUpdatePlugin onEditorUpdate={onEditorUpdate} />
            <ListMaxIndentLevelPlugin maxDepth={7} />
            <MarkdownShortcutPlugin transformers={TRANSFORMERS} />
            <ClickableLinkPlugin filter={clickableLinks ? undefined : (event) => event.metaKey || event.ctrlKey} />
            {autoFocus && <AutoFocusPlugin />}
            {debug && <TreeViewPlugin />}
          </div>
        </div>
      </LexicalComposer>
    </div>
  );
};

export default RichText;
