import React, { useContext, useState, useMemo, useCallback } from 'react';
import {
  Row,
  Col,
  Panel,
  InputGroup,
  Icon,
  Input,
  Button,
  Modal,
} from 'rsuite';
import Styled from 'styled-components';
import { get, includes } from 'lodash';

import { LoggedUserContext, TeamMembersContext, TeamContext } from '../context';
import { Colors, User as UserIcon } from '../assets';
import { MemberForm, MemberItem } from '../components';
import { Mixpanel } from '../services';

const Container = Styled.div({
  margin: 30,
});

const Title = Styled.h2({
  marginBottom: 30,
});

const ContainerPanel = Styled(Panel)({
  backgroundColor: Colors.WHITE,
});

const TeamMembers = () => {
  const loggedUser = useContext(LoggedUserContext);
  const team = useContext(TeamContext);
  const { list: members, map: membersMap } = useContext(TeamMembersContext);
  const [search, setSearch] = useState('');
  const [showMemberFormModal, setShowMemberFormModal] = useState(false);

  const filteredMembers = useMemo(
    () =>
      members.filter(
        (u) =>
          get(u, 'displayName', '')
            .toLowerCase()
            .search(search.toLowerCase()) >= 0 ||
          get(u, 'email', '').toLowerCase().search(search.toLowerCase()) >= 0,
      ),
    [members, search],
  );

  const doSetShowMemberFormModal = useCallback(
    (value) => () => setShowMemberFormModal(value),
    [],
  );

  return (
    <Container>
      <Row gutter={16}>
        <Col xs={16}>
          <Title>Team Members</Title>
        </Col>
      </Row>
      <Row gutter={16} style={{ marginBottom: 20 }}>
        <Col xs={20}>
          <ContainerPanel>
            <InputGroup inside>
              <Input
                placeholder='search'
                style={{ border: 'none' }}
                value={search}
                onChange={setSearch}
              />
              <InputGroup.Button>
                <Icon icon='search' />
              </InputGroup.Button>
            </InputGroup>
          </ContainerPanel>
        </Col>
        <Col xs={4}>
          <Button
            block
            appearance='primary'
            style={{ height: 74, fontSize: 18 }}
            onClick={doSetShowMemberFormModal(true)}
            disabled={
              loggedUser.role === 'Member' &&
              includes(
                ['', 'Member'],
                get(membersMap, `${loggedUser.id}.teamRole`, ''),
              )
            }
          >
            Add Member
          </Button>
        </Col>
      </Row>
      {filteredMembers.length > 0 ? (
        <Row gutter={16}>
          {filteredMembers.map((member, index) => (
            <Col xs={24} style={{ marginBottom: 10 }}>
              <ContainerPanel
                style={
                  get(member, 'disabled', false)
                    ? {
                        borderColor: Colors.COMPLEMENTARY,
                        borderWidth: 1,
                        borderStyle: 'dashed',
                      }
                    : {}
                }
              >
                <MemberItem key={`member-${get(member, 'id')}`} data={member} />
              </ContainerPanel>
            </Col>
          ))}
        </Row>
      ) : (
        <Row gutter={16}>
          <Col xs={24} style={{ marginBottom: 10 }}>
          <ContainerPanel
                  style={{
                    minHeight: 90,
                    textAlign: 'center',
                    paddingTop: 40,
                    paddingBottom: 40,
                  }}
                >
                  <img src={UserIcon} style={{ width: '100%', maxWidth: 400 }} />
                  <h5 style={{ marginTop: 20 }}>No members found</h5>
                  <span>You can start by adding one</span>
                </ContainerPanel>
          </Col>
        </Row>
      )}
      <Modal
        key='add-new-member'
        show={showMemberFormModal}
        onHide={doSetShowMemberFormModal(false)}
        overflow={false}
      >
        <Modal.Header>
          <Modal.Title>Add Member</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ paddingBottom: 0 }}>
          <MemberForm onSave={() => {
            Mixpanel.track('Added Team Member');
            doSetShowMemberFormModal(false)();
          }} />
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default TeamMembers;
